import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useT } from '../../../lib/i18n'
import { Alert } from '../../ui/Alert'
import { Segment } from '../../ui/Segment'

export const ErrorPageComponent = (props: { title?: string; message?: string; children?: React.ReactNode }) => {
  const { t } = useT('errorPageComponent')
  const title = props.title || t('title')
  const message = props.message || t('message')
  return (
    <Segment title={title}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Alert color="red">{message}</Alert>
      {props.children}
    </Segment>
  )
}
