import type { TrpcRouterOutput } from '@svag/backend/src/router'
import cn from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useMe } from '../../../../lib/ctx'
import { useChangeStudentGuideLessonCompletenes } from '../../../../lib/education/useChangeStudentGuideLessonCompletenes'
import { useT } from '../../../../lib/i18n'
import { getGuideLessonRoute } from '../../../../lib/routes'
import { openSignUpModal } from '../../../auth/Auth'
import { CheckboxUI } from '../../../form/Checkbox'
import { Icon } from '../../../ui/Icon'
import css from './index.module.scss'

const ManageLessonCompletenes = ({
  lesson,
}: {
  lesson: TrpcRouterOutput['getStudentGuideFull']['lessons'][number]
}) => {
  const { t } = useT('lessonsMenu')
  const me = useMe()
  const { changeStudentGuideLessonCompletenes } = useChangeStudentGuideLessonCompletenes({
    lesson,
  })
  return (
    <CheckboxUI
      checked={lesson.isFinished}
      onClick={(e) => {
        e.stopPropagation()
      }}
      disabled={lesson.isLockedBecouseLowPlan}
      onChange={(e) => {
        if (!me) {
          openSignUpModal({
            alertMessage: t('authorizeToCompleteLesson'),
          })
        } else {
          void changeStudentGuideLessonCompletenes({
            isFinished: !lesson.isFinished,
          })
        }
      }}
    />
  )
}

const LessonsMenuItem = ({
  lesson,
  totalCount,
  guideSlug,
  currentLesson,
}: {
  lesson: TrpcRouterOutput['getStudentGuideFull']['lessons'][number]
  guideSlug: string
  totalCount: number
  currentLesson: TrpcRouterOutput['getStudentGuideFull']['lessons'][number] | null
}) => {
  const isCurrent = currentLesson?.id === lesson.id
  return (
    <Link
      className={cn({
        [css.lessonsMenuItem]: true,
        [css.current]: isCurrent,
        [css.locked]: lesson.isLockedBecouseLowPlan,
      })}
      to={getGuideLessonRoute({
        guideSlug,
        lessonSlug: lesson.slug,
      })}
      onClick={() => {}}
      id={`lessons-menu-item-${lesson.id}`}
    >
      <div className={css.topLine}>
        <div className={css.checkboxWithLabel}>
          <div className={css.checkbox}>
            <ManageLessonCompletenes lesson={lesson} />
          </div>
          <div className={css.label}>{`${lesson.index + 1}/${totalCount}`}</div>
          <div className={css.videoDuration}>({lesson.videoDurationString})</div>
        </div>
        <Icon className={css.lock} name="lock" />
      </div>
      <h6 className={css.title}>{lesson.title}</h6>
      {!!lesson.desc && <p className={css.desc}>{lesson.desc}</p>}
    </Link>
  )
}

export const LessonsMenu = ({
  lessons,
  currentLesson,
  guide,
}: {
  lessons: TrpcRouterOutput['getStudentGuideFull']['lessons']
  currentLesson: TrpcRouterOutput['getStudentGuideFull']['lessons'][number] | null
  guide: NonNullable<TrpcRouterOutput['getStudentGuideFull']['guide']>
}) => {
  const lessonsMenuElRef = useRef<HTMLDivElement>(null)
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    if (!lessonsMenuElRef.current) {
      return
    }
    const currentLessonId = currentLesson ? currentLesson.id : guide.currentGuideLesson?.id
    const lessonsMenuEl = lessonsMenuElRef.current
    const lessonsMenuItemEl = document.getElementById(`lessons-menu-item-${currentLessonId}`)
    const getScrollableParent = (el: HTMLElement | null): HTMLElement | null =>
      el == null ? null : el.scrollHeight > el.clientHeight ? el : getScrollableParent(el.parentNode as HTMLElement)
    const scrollableEl = getScrollableParent(lessonsMenuEl)
    if (!scrollableEl || !lessonsMenuItemEl) {
      setVisible(true)
      return
    }
    const scrollableElRect = scrollableEl.getBoundingClientRect()
    const lessonsMenuItemElRect = lessonsMenuItemEl.getBoundingClientRect()
    const scrollableElScrollTop = scrollableEl.scrollTop
    const lessonsMenuItemElScrollTop = lessonsMenuItemElRect.top - scrollableElRect.top + scrollableElScrollTop
    scrollableEl.scrollTo({ top: lessonsMenuItemElScrollTop })
    setVisible(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonsMenuElRef.current])
  return (
    <div ref={lessonsMenuElRef} className={cn({ [css.lessonsMenu]: true, [css.visible]: visible })}>
      {lessons.map((lesson, i) => (
        <LessonsMenuItem
          key={lesson.id}
          lesson={lesson}
          guideSlug={guide?.slug}
          currentLesson={currentLesson}
          totalCount={lessons.length}
        />
      ))}
    </div>
  )
}
