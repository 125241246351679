import type { TrpcRouterOutput } from '@svag/backend/src/router'
import { zOrderGithubInvitationInput } from '@svag/backend/src/router/other/orderGithubInvitation/input'
import { getContactLink } from '@svag/shared/src/getContactLink'
import { getGithubRepoUrl } from '@svag/shared/src/getGithubRepoUrl'
import { useForm } from '../../../lib/form'
import { useT } from '../../../lib/i18n'
import { trpc } from '../../../lib/trpc'
import { FormItems } from '../../form/FormItems'
import { Input } from '../../form/Input'
import { Alert } from '../../ui/Alert'
import { Button, Buttons } from '../../ui/Button'

export const GithubInvitation = ({
  githubRepo,
  onSuccess,
}: {
  githubRepo: TrpcRouterOutput['getStudentGuideFull']['guide']['githubRepos'][0]
  onSuccess?: (updatedGithubRepo: TrpcRouterOutput['orderGithubInvitation']) => void
}) => {
  const { t } = useT('githubInvitation')
  const orderGithubInvitation = trpc.orderGithubInvitation.useMutation()
  const form = useForm({
    onSubmit: async (values) => {
      const result = await orderGithubInvitation.mutateAsync(values)
      if (onSuccess) {
        onSuccess(result)
      }
    },
    validationSchema: zOrderGithubInvitationInput,
    resetOnSuccess: false,
    enableReinitialize: true,
    initialValues: {
      githubRepoId: githubRepo.id,
      githubUsername: '',
    },
  })
  if (!githubRepo.invitedGithubUsername) {
    return (
      <form onSubmit={form.formik.handleSubmit}>
        <FormItems>
          <Alert color="brown">
            {t('inviteMessage')}
            <a href="https://github.com/signup" target="_blank" rel="noreferrer">
              {t('createAccount')}
            </a>
          </Alert>
          <Input name="githubUsername" floatingPlaceholder={t('githubUsernamePlaceholder')} formik={form.formik} />
          <Alert {...form.alertProps} />
          <Button type="submit" {...form.buttonProps}>
            {t('getInvitation')}
          </Button>
        </FormItems>
      </form>
    )
  } else {
    return (
      <FormItems>
        <Alert
          color="green"
          html={t('invitationSentMessage', {
            repoName: githubRepo.name,
            repoUrl: getGithubRepoUrl(githubRepo.name),
            username: githubRepo.invitedGithubUsername,
            invitationsUrl: `${getGithubRepoUrl(githubRepo.name)}/invitations`,
            authorContactLink: getContactLink(githubRepo.author),
          })}
        />
        <Buttons>
          <Button href={getGithubRepoUrl(githubRepo.name)} target="_blank" rel="noreferrer">
            {t('goToRepository')}
          </Button>
        </Buttons>
      </FormItems>
    )
  }
}
