import { VideoKinescope } from '../../../../../../components/other/VideoKinescope'
import { useT } from '../../../../../../lib/i18n'
import { EdDevPromoPageStack } from '../EdDevPromoPageStack'
import css from './index.module.scss'

export const EdDevPromoPageArchitecture = () => {
  const { t } = useT('edDevPromoPageArchitecture')

  return (
    <div className={css.architecture} id="architecture">
      <div className={css.wrapper}>
        <h1 className={css.sectionTitle}>
          <span className={css.sectionTitleText}>{t('sectionTitle')}</span>
        </h1>

        <div className={css.intro}>
          <EdDevPromoPageStack className={css.stack} size="m" />
          <div className={css.text}>
            <p>{t('intro.paragraph1')}</p>
            <p>{t('intro.paragraph2')}</p>
            <p>{t('intro.paragraph3')}</p>
          </div>
        </div>

        <VideoKinescope
          className={css.videoPlace}
          videoId="9f7ec531-f45d-450c-ab82-ea1643784e57"
          width={560}
          height={364}
          eventElementGroup="edPromoVideo"
          eventElementId="edPromoArchitectureVideo"
        />
      </div>
    </div>
  )
}
