import { useT } from '../../../../lib/i18n'
import { getGuideHomeRoute } from '../../../../lib/routes'
import { Button, Buttons } from '../../../ui/Button'
import { Card, type CardProps } from '../../../ui/Card'
import { type EdItem } from '../EdCard'

export const GuideCard = ({
  direction,
  type,
  title,
  desc,
  slug,
  promoUrlPath,
  isReady,
  currentGuideLesson,
  startedByMeAt,
  isUnfinishedLessonsExists,
  setRef,
}: Extract<EdItem, { type: 'guide' }> & Pick<CardProps, 'setRef'>) => {
  const { t } = useT('guideCard')
  const { t: tg } = useT('general')
  return (
    <Card
      setRef={setRef}
      hintColor={direction}
      hintIconName="guide"
      hintText={t('hintPrefix') + ' / ' + tg(`directions.${direction}`)}
      title={title}
      content={desc}
      buttons={
        isReady || !!promoUrlPath ? (
          <Buttons compressed stretch>
            {isReady &&
              (isUnfinishedLessonsExists && startedByMeAt ? (
                <Button
                  to={getGuideHomeRoute({ guideSlug: slug })}
                  eventElementGroup="edButton"
                  eventElementId="continueLearningButton"
                >
                  {t('buttons.continueLearning')}
                </Button>
              ) : (
                <Button
                  to={getGuideHomeRoute({ guideSlug: slug })}
                  eventElementGroup="edButton"
                  eventElementId="openGuideButton"
                >
                  {t('buttons.openGuide')}
                </Button>
              ))}
            {!!promoUrlPath && (
              <Button
                color="brandAccentLight"
                to={promoUrlPath}
                eventElementGroup="edButton"
                eventElementId="openGuidePromoButton"
              >
                {t('buttons.moreDetails')}
              </Button>
            )}
          </Buttons>
        ) : null
      }
    />
  )
}
