import type { TrpcRouterOutput } from '@svag/backend/src/router'
import { openPurchaseProductModal } from '../../../../components/billing/PurchaseProduct/store'
import { PublicUserPreview } from '../../../../components/other/PublicUserPreview'
import { Button, Buttons } from '../../../../components/ui/Button'
import { Segment, Segments } from '../../../../components/ui/Segment'
import { useT } from '../../../../lib/i18n'
import {
  ConsultationPageInstanceInfo,
  useGetConsultationInstanceInfoWithCurrency,
} from '../../ConsultationPageInstanceInfo'
import { ConsultationPageVideo } from '../ConsultationPageVideo'
import css from './index.module.scss'

export const ConsultationPageAttendee = ({
  consultationInstance,
}: {
  consultationInstance: TrpcRouterOutput['getConsultationInstance']['consultationInstance']
}) => {
  const { t } = useT('consultationPageAttendee')
  const { getConsultationInstanceInfoWithCurrency } = useGetConsultationInstanceInfoWithCurrency()
  return (
    <Segment title={`${consultationInstance.title} #${consultationInstance.serialNumber}`}>
      <Segments>
        <Segment size="M" title={t('info')}>
          <div className={css.infoAndButtons}>
            <div className={css.info}>
              <ConsultationPageInstanceInfo
                size="m"
                info={getConsultationInstanceInfoWithCurrency({
                  consultationInstance,
                  pickKeys: ['status', 'happendAt', 'scheduledAt', 'prepay', 'afterpay', 'price', 'notes'],
                })}
              />
            </div>
            {consultationInstance.status === 'waitingForPrepay' && (
              <div className={css.buttons}>
                <Buttons>
                  <Button
                    onClick={() => {
                      openPurchaseProductModal({
                        type: 'consultationPrepay',
                        options: {
                          consultationInstanceId: consultationInstance.id,
                        },
                      })
                    }}
                  >
                    {t('makePrepayment')}
                  </Button>
                </Buttons>
              </div>
            )}
            {consultationInstance.status === 'waitingForAfterpay' && (
              <div className={css.buttons}>
                <Buttons>
                  <Button
                    onClick={() => {
                      openPurchaseProductModal({
                        type: 'consultationAfterpay',
                        options: {
                          consultationInstanceId: consultationInstance.id,
                        },
                      })
                    }}
                  >
                    {t('makePayment')}
                  </Button>
                </Buttons>
              </div>
            )}
          </div>
        </Segment>
        <Segment size="M" title={t('consultant')}>
          <PublicUserPreview size="m" publicUser={consultationInstance.consultant} />
        </Segment>
        <ConsultationPageVideo consultationInstance={consultationInstance} />
      </Segments>
    </Segment>
  )
}
