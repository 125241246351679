import { AnimatedStars, IconStar } from '../../../../../components/ui/AnimatedStars'
import { Button } from '../../../../../components/ui/Button'
import { Icon } from '../../../../../components/ui/Icon'
import { useCurrency } from '../../../../../lib/currency'
import { useT } from '../../../../../lib/i18n'
import { trackClick } from '../../../../../lib/mixpanel'
import css from './index.module.scss'

const StepButtonDesign = ({ buttonText }: { buttonText: string }) => {
  return null
  // return (
  //   <button
  //     onClick={() => {
  //       openDesignsGallery()
  //     }}
  //     className={css.stepButton}
  //   >
  //     {buttonText}
  //   </button>
  // )
}

const StepButtonMeeting = ({ buttonText }: { buttonText: string }) => {
  return (
    <a
      href="https://cal.com/svag.group/30min"
      rel="noreferrer"
      target="_blank"
      className={css.stepButton}
      onClick={() => {
        trackClick({
          elementGroup: 'homeContactButton',
          elementId: 'homePlanMeetingButton',
          to: 'https://cal.com/svag.group/30min',
        })
      }}
    >
      {buttonText}
    </a>
  )
}

const StepButtonDevelopment = ({ buttonText }: { buttonText: string }) => {
  return null
  // return (
  //   <a
  //     href="https://www.linkedin.com/pulse/how-order-development-startup-doesnt-disappoint-sergei-dmitriev/"
  //     rel="noreferrer"
  //     target="_blank"
  //     className={css.stepButton}
  //   >
  //     {buttonText}
  //   </a>
  // )
}

const StepButton = ({
  buttonText,
  kkey,
}: {
  buttonText: string
  kkey: 'meeting' | 'prototype' | 'design' | 'development'
}) => {
  if (kkey === 'meeting') {
    return <StepButtonMeeting buttonText={buttonText} />
  }
  if (kkey === 'development') {
    return <StepButtonDevelopment buttonText={buttonText} />
  }
  if (kkey === 'design') {
    return <StepButtonDesign buttonText={buttonText} />
  }
  return null
}

export const ClientsPagePlan = () => {
  const { t } = useT('clientsPagePlan')
  const { normalizePricesWithCurrency } = useCurrency()
  return (
    <div className={css.plan} id="plan">
      <div className={css.wrapper}>
        <h2 className={css.sectionTitle}>
          <span className={css.sectionTitleText}>{t('title')}</span>
          <AnimatedStars className={css.animatedStars} visibleClassName={css.visible}>
            <IconStar />
            <IconStar />
            <IconStar />
          </AnimatedStars>
        </h2>
        <div className={css.steps}>
          {t('steps', { returnObjects: true }).map((step, i) => (
            <div key={i} className={css.step}>
              <div className={css.stepHeader}>
                <div className={css.stepNumber}>{i}</div>
                <div className={css.stepTitle}>{step.title}</div>
              </div>
              <div
                className={css.stepText}
                dangerouslySetInnerHTML={{ __html: normalizePricesWithCurrency(step.text) }}
              />
              {!!(step.price || step.duration || step.buttonText) && (
                <div className={css.stepFooter}>
                  {step.price && (
                    <div className={css.stepPrice}>
                      <Icon name="wallet" className={css.icon} />
                      {normalizePricesWithCurrency(step.price)}
                    </div>
                  )}
                  {step.duration && (
                    <div className={css.stepTime}>
                      <Icon name="calendar" className={css.icon} />
                      {step.duration}
                    </div>
                  )}
                  {step.buttonText && <StepButton buttonText={step.buttonText} kkey={step.key as any} />}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className={css.footer}>
          <div className={css.buttonPlace}>
            <Button
              size="l"
              href="https://cal.com/svag.group/30min"
              rel="noreferrer"
              target="_blank"
              className={css.button}
              eventElementGroup="homeContactButton"
              eventElementId="homePlanContactButton"
            >
              {t('buttonText')}
            </Button>
          </div>
          <div className={css.buttonDesc}>{t('buttonDesc')}</div>
        </div>
      </div>
    </div>
  )
}
