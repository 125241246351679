import autosizeEl from 'autosize'
import cn from 'classnames'
import { type FormikProps } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import css from './index.module.scss'

export const Textarea = ({
  label,
  autosize = true,
  name,
  formik,
  maxWidth,
  minHeight,
  floatingPlaceholder,
  placeholder,
  hint,
  disabled,
}: {
  label?: string
  placeholder?: string
  floatingPlaceholder?: string
  name: string
  formik: FormikProps<any>
  maxWidth?: number | string
  minHeight?: number
  type?: 'text' | 'password'
  hint?: string
  disabled?: boolean
  autosize?: boolean
}) => {
  const value = formik.values[name]
  const error = formik.errors[name] as string | undefined
  const touched = formik.touched[name] as boolean
  const invalid = touched && !!error
  const disabledHere = disabled || formik.isSubmitting
  const [focused, setFocused] = useState(false)

  const elRef = useRef<HTMLTextAreaElement>(null)
  useEffect(() => {
    if (elRef.current != null) {
      autosizeEl(elRef.current)
    }
  }, [elRef])

  return (
    <div
      className={cn({
        [css.field]: true,
        [css.disabled]: disabledHere,
        [css.filled]: !!value?.toString().length,
        [css.invalid]: invalid,
        [css.focused]: focused,
      })}
      style={{ maxWidth }}
    >
      {label && (
        <label className={css.label} htmlFor={name}>
          {label}
        </label>
      )}
      {floatingPlaceholder && (
        <div className={css.floatingPlaceholder}>
          <div className={css.floatingPlaceholderText}>{floatingPlaceholder}</div>
        </div>
      )}
      <div className={cn({ [css.textareaPlace]: true, [css.invalid]: invalid })}>
        <textarea
          ref={elRef}
          className={cn({ [css.textarea]: true })}
          disabled={disabledHere}
          onChange={(e) => {
            void formik.setFieldValue(name, e.target.value)
          }}
          onFocus={() => {
            setFocused(true)
          }}
          onBlur={() => {
            formik.setFieldTouched(name)
            setFocused(false)
          }}
          value={value}
          name={name}
          id={name}
          placeholder={placeholder}
          style={{ ...(maxWidth ? { maxWidth } : {}), ...(minHeight ? { minHeight } : {}) }}
        />
      </div>
      {invalid && <div className={css.error}>{error}</div>}
      {hint && <div className={css.hint}>{hint}</div>}
    </div>
  )
}
