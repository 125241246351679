import { type TRPCError } from '@trpc/server'
import { getHTTPStatusCodeFromError } from '@trpc/server/http'
import { TRPC_ERROR_CODES_BY_KEY, type TRPC_ERROR_CODE_KEY } from '@trpc/server/rpc'

export class AppError extends Error {
  errorType = 'UnexpectedError' as
    | 'UnexpectedError'
    | 'ExpectedError'
    | 'NotFoundError'
    | 'AccessDeniedError'
    | 'UnauthorizedError'
    | 'UnprocessableError'

  defaultMessage = 'Unexpected error'
  codeText = 'INTERNAL_SERVER_ERROR' as TRPC_ERROR_CODE_KEY
  isExpectedError = false
  errorTitle?: string
}

export class ExpectedError extends AppError {
  isExpectedError = true
}

export class NotFoundError extends ExpectedError {
  constructor(props?: string | { message: string; title: string }) {
    const message = typeof props === 'string' ? props : props?.message
    const title = typeof props === 'string' ? undefined : props?.title
    super(message || 'Not found')
    this.errorTitle = title
  }

  errorType = 'NotFoundError' as const
  defaultMessage = 'Not found'
  codeText = 'NOT_FOUND' as const
}

export class AccessDeniedError extends ExpectedError {
  errorType = 'AccessDeniedError' as const
  defaultMessage = 'Access denied'
  codeText = 'FORBIDDEN' as const
}

export class UnauthorizedError extends ExpectedError {
  errorType = 'UnauthorizedError' as const
  defaultMessage = 'Unauthorized'
  codeText = 'UNAUTHORIZED' as const
}

export class UnprocessableError extends ExpectedError {
  errorType = 'UnprocessableError' as const
  defaultMessage = 'Unprocessable'
  codeText = 'UNPROCESSABLE_CONTENT' as const
}

export const getErrorData = (
  error: any
): {
  errorType: string
  isExpectedError: boolean
  errorTitle: string | undefined
  errorMessage: string
  codeNumber: number
  codeText: TRPC_ERROR_CODE_KEY
  httpStatus: number
} => {
  const errorMessage =
    error?.message || error?.data?.message || error?.defaultMessage || error?.data?.defaultMessage || 'Unknown error'
  const codeText = error?.codeText || error?.data?.codeText || 'INTERNAL_SERVER_ERROR'
  const codeNumber = (TRPC_ERROR_CODES_BY_KEY as any)[codeText] || TRPC_ERROR_CODES_BY_KEY.INTERNAL_SERVER_ERROR
  const httpStatus = getHTTPStatusCodeFromError({ code: codeText } as TRPCError) || 500
  return {
    errorMessage,
    errorType: error?.errorType || error?.data?.errorType || 'UnexpectedError',
    isExpectedError: error?.isExpectedError || error?.data?.isExpectedError || false,
    errorTitle: error?.errorTitle || error?.data?.errorTitle || undefined,
    codeNumber,
    codeText,
    httpStatus,
  }
}
