import { useParams } from 'react-router-dom'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getEducationRoute, getGroupsRoute, type getGroupRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import { type RouteParams } from '../../../utils/pumpGetRoute'
import { GroupPageStudent } from './GroupPageStudent'
import { GroupPageTeacher } from './GroupPageTeacher'

export const GroupPage = withPageWrapper({
  title: ({ t }) => t('groupPage.title'),
  showLoaderOnFetching: false,
  getBreadCrumbs: ({ queryResult, t }) => {
    return [
      { label: t('groupPage.breadcrumbs.education'), to: getEducationRoute() },
      { label: t('groupPage.breadcrumbs.groups'), to: getGroupsRoute() },
      { label: `${queryResult.data.groupInstance.title}` },
    ]
  },
  useQuery: () => {
    const { groupInstanceSlug } = useParams() as RouteParams<typeof getGroupRoute>
    return trpc.getGroupInstance.useQuery({
      groupInstanceSlug,
    })
  },
  setProps: ({ queryResult, getAuthorizedMe }) => {
    return {
      groupInstance: queryResult.data.groupInstance,
    }
  },
  langs: ['ru', 'en'],
})(({ groupInstance }) => {
  if (groupInstance.isMeTeacher) {
    return <GroupPageTeacher groupInstance={groupInstance} />
  }
  return <GroupPageStudent groupInstance={groupInstance} />
})
