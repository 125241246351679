import { useT } from '../../../../../../lib/i18n'
import css from './index.module.scss'

export const EdDevPromoPageTarget = () => {
  const { t } = useT('edDevPromoPageTarget')

  return (
    <div className={css.target} id="target">
      <div className={css.wrapper}>
        <h1 className={css.sectionTitle}>
          <span className={css.sectionTitleText}>{t('sectionTitle')}</span>
        </h1>
        <div className={css.items}>
          {(
            [
              { titleKey: 'item1.title', textKey: 'item1.text' },
              { titleKey: 'item2.title', textKey: 'item2.text' },
              { titleKey: 'item3.title', textKey: 'item3.text' },
              // { titleKey: 'item4.title', textKey: 'item4.text' },
              // { titleKey: 'item5.title', textKey: 'item5.text' },
              // { titleKey: 'item6.title', textKey: 'item6.text' },
            ] as const
          ).map((item, index) => (
            <div className={css.itemOuter} key={index}>
              <div className={css.item}>
                <div className={css.title}>{t(item.titleKey)}</div>
                <div className={css.text}>{t(item.textKey)}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
