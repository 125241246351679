import { TRPCClientError } from '@trpc/client'
import { useFormik, type FormikHelpers } from 'formik'
import { withZodSchema } from 'formik-validator-zod'
import { useEffect, useMemo, useRef, useState } from 'react'
import { type z } from 'zod'
import { type AlertProps } from '../components/ui/Alert'
import { type ButtonProps } from '../components/ui/Button'
import { notify, type NotifyProps } from './notify'
import { sentryCaptureException } from './sentry'

type ValuesType<TMaybeZodSchema> = TMaybeZodSchema extends z.ZodTypeAny ? z.infer<TMaybeZodSchema> : {}

export const useForm = <TMaybeZodSchema extends z.ZodTypeAny | undefined = undefined>({
  successMessage = false,
  successMessageDuration = 3000,
  successMessagePolicy = 'alert',
  submitErrorMessagePolicy = 'alert',
  validationErrorMessagePolicy = 'alert',
  resetOnSuccess = true,
  showValidationAlert = false,
  initialValues,
  validationSchema,
  onSubmit,
  enableReinitialize,
}: {
  successMessage?: string | false
  successMessageDuration?: number | false
  successMessagePolicy?: 'alert' | 'toast'
  submitErrorMessagePolicy?: 'alert' | 'toast'
  validationErrorMessagePolicy?: 'alert' | 'toast'
  resetOnSuccess?: boolean
  showValidationAlert?: boolean
  initialValues?: ValuesType<TMaybeZodSchema>
  validationSchema?: TMaybeZodSchema
  onSubmit?: (
    values: ValuesType<TMaybeZodSchema>,
    actions: FormikHelpers<ValuesType<TMaybeZodSchema>>
  ) => Promise<any> | any
  enableReinitialize?: boolean
}) => {
  const [successMessageVisible, setSuccessMessageVisible] = useState(false)
  const [submittingError, setSubmittingError] = useState<Error | null>(null)
  const hideSuccessMessageTimeoutRef = useRef<null | ReturnType<typeof setTimeout>>(null)

  const formik = useFormik<ValuesType<TMaybeZodSchema>>({
    enableReinitialize,
    initialValues: initialValues || ({} as any),
    // ...(validationSchema && { validationSchema: toFormikValidationSchema(validationSchema) }),
    ...(validationSchema && { validate: withZodSchema(validationSchema) }),
    onSubmit: async (values, formikHelpers) => {
      if (!onSubmit) {
        return
      }
      try {
        if (hideSuccessMessageTimeoutRef.current) {
          clearTimeout(hideSuccessMessageTimeoutRef.current)
        }
        setSubmittingError(null)
        await onSubmit(values, formikHelpers)
        if (resetOnSuccess) {
          formik.resetForm()
        }
        setSuccessMessageVisible(true)
        if (successMessageDuration) {
          hideSuccessMessageTimeoutRef.current = setTimeout(() => {
            setSuccessMessageVisible(false)
          }, successMessageDuration)
        }
      } catch (error: any) {
        if (!(error instanceof TRPCClientError)) {
          sentryCaptureException(error)
        }
        setSubmittingError(error)
      }
    },
  })

  const alertProps = useMemo<AlertProps>(() => {
    if (submittingError && submitErrorMessagePolicy === 'alert') {
      return {
        hidden: false,
        children: submittingError.message,
        color: 'red',
      }
    }
    if (showValidationAlert && !formik.isValid && !!formik.submitCount && validationErrorMessagePolicy === 'alert') {
      return {
        hidden: false,
        children: 'Some fields are invalid',
        color: 'red',
      }
    }
    if (successMessageVisible && successMessage && successMessagePolicy === 'alert') {
      return {
        hidden: false,
        children: successMessage,
        color: 'green',
      }
    }
    return {
      color: 'red',
      hidden: true,
      children: null,
    }
  }, [
    submittingError,
    formik.isValid,
    formik.submitCount,
    successMessageVisible,
    successMessage,
    showValidationAlert,
    successMessagePolicy,
    submitErrorMessagePolicy,
    validationErrorMessagePolicy,
  ])

  const notifyProps = useMemo<NotifyProps | null>(() => {
    if (submittingError && submitErrorMessagePolicy === 'toast') {
      return {
        message: submittingError.message,
        type: 'error',
      }
    }
    if (showValidationAlert && !formik.isValid && !!formik.submitCount && validationErrorMessagePolicy === 'toast') {
      return {
        message: 'Some fields are invalid',
        type: 'error',
      }
    }
    if (successMessageVisible && successMessage && successMessagePolicy === 'toast') {
      return {
        message: successMessage,
        type: 'success',
      }
    }
    return null
  }, [
    submittingError,
    formik.isValid,
    formik.submitCount,
    successMessageVisible,
    successMessage,
    showValidationAlert,
    successMessagePolicy,
    submitErrorMessagePolicy,
    validationErrorMessagePolicy,
  ])

  useEffect(() => {
    if (notifyProps != null) {
      notify(notifyProps)
    }
  }, [notifyProps])

  const buttonProps = useMemo<Omit<ButtonProps, 'children'>>(() => {
    return {
      loading: formik.isSubmitting,
    }
  }, [formik.isSubmitting])

  return {
    formik,
    alertProps,
    buttonProps,
  }
}
