import type { TrpcRouterOutput } from '@svag/backend/src/router'
import type { ProductIdentifier } from '@svag/backend/src/services/product'
import { toMoney } from '@svag/shared/src/money'
import { zStringRequired } from '@svag/shared/src/zod'
import { z } from 'zod'
import { useForm } from '../../../../lib/form'
import { useT } from '../../../../lib/i18n'
import { trackPressPurchaseButton } from '../../../../lib/mixpanel'
import { notify } from '../../../../lib/notify'
import { trpc } from '../../../../lib/trpc'
import { FormItems } from '../../../form/FormItems'
import { Input } from '../../../form/Input'
import { Alert } from '../../../ui/Alert'
import { Button } from '../../../ui/Button'
import { closePurchaseProductModal } from '../store'

export const PaymentButtonVerifone = ({
  product,
  productIdentifier,
  className,
}: {
  product: TrpcRouterOutput['getProduct']['product']
  productIdentifier: ProductIdentifier
  className?: string
}) => {
  const { t } = useT('paymentButtonVerifone')
  const purchaseProduct = trpc.purchaseProduct.useMutation()
  const trpcUtils = trpc.useContext()
  const { buttonProps, alertProps, formik } = useForm({
    initialValues: {
      password: '',
    },
    validationSchema: z.object({
      password: zStringRequired,
    }),
    onSubmit: async ({ password }) => {
      await purchaseProduct.mutateAsync({ productIdentifier, currency: 'usd', password })
      notify({
        type: 'success',
        message: t('successMessage'),
      })
      closePurchaseProductModal()
      setTimeout(() => {
        void trpcUtils.invalidate()
      }, 1)
    },
    successMessage: false,
  })

  return (
    <div className={className}>
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Input formik={formik} name="password" label={t('passwordLabel')} hint={t('passwordHint')} />
          <Alert {...alertProps} />
          <Button
            {...buttonProps}
            onClick={() => {
              trackPressPurchaseButton({
                product,
                productIdentifier,
                amount: product.amountUsd,
                currency: 'usd',
              })
              formik.handleSubmit()
            }}
            fullWidth
          >
            {t('payButtonText', { amount: toMoney({ amount: product.amountUsd, currency: 'usd' }) })}
          </Button>
        </FormItems>
      </form>
    </div>
  )
}
