import { Members } from '../../../../../components/general/Members'
import { AnimatedStars, IconStar } from '../../../../../components/ui/AnimatedStars'
import { useT } from '../../../../../lib/i18n'
import { trpc } from '../../../../../lib/trpc'
import css from './index.module.scss'

export const ClientsPageMembers = () => {
  const { t } = useT('clientsPageMembers')
  const qr = trpc.getTeamsAndSpecialists.useQuery({})
  if (!qr.data) {
    return null
  }
  return (
    <div className={css.projects} id="projects">
      <div className={css.wrapper}>
        <h2 className={css.sectionTitle}>
          <span className={css.sectionTitleText}>{t('title')}</span>
          <AnimatedStars className={css.animatedStars} visibleClassName={css.visible}>
            <IconStar />
            <IconStar />
            <IconStar />
          </AnimatedStars>
        </h2>
        <div className={css.intro}>{t('desc')}</div>
        <Members members={qr.data.teamsAndSpecialists} className={css.items} />
      </div>
    </div>
  )
}
