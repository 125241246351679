import { zResetPasswordByPhoneInput } from '@svag/backend/src/router/auth/resetPasswordByPhone/input'
import { zRestorePasswordByEmailInput } from '@svag/backend/src/router/auth/restorePasswordByEmail/input'
import { zPasswordsMustBeTheSame, zStringRequired } from '@svag/shared/src/zod'
import { type FormikProps } from 'formik'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { z } from 'zod'
import { switchAuthComponentChoice } from '../../../components/auth/Auth'
import { Button, type ButtonProps, Buttons } from '../../../components/ui/Button'
import { Segment, type SegmentProps } from '../../../components/ui/Segment'
import { useCacheRecord, useSyncCacheRecord } from '../../../lib/cache'
import { useForm } from '../../../lib/form'
import { useT } from '../../../lib/i18n'
import { getRestorePasswordRoute, getSignInRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import { FormItems } from '../../form/FormItems'
import { Input } from '../../form/Input'
import { RadioButton, RadioButtons } from '../../form/RadioButton'
import { Alert } from '../../ui/Alert'

const zMethod = z.enum(['email', 'phone'])
type Method = z.infer<typeof zMethod>

const SingInButton = ({
  isItRestorePasswordPage,
  color = 'brandAccentLight',
}: {
  isItRestorePasswordPage: boolean
  color?: ButtonProps['color']
}) => {
  const { t } = useT('restorePassword')
  return (
    <Button
      color={color}
      {...(isItRestorePasswordPage
        ? {
            to: getSignInRoute(),
          }
        : {
            onClick: () => {
              switchAuthComponentChoice('signIn')
            },
          })}
    >
      {t('signIn')}
    </Button>
  )
}

const ChangeMathod = ({ formik, setMethod }: { formik: FormikProps<any>; setMethod: (method: Method) => void }) => {
  const { t } = useT('restorePassword')
  useEffect(() => {
    setMethod(formik.values.method)
  }, [formik.values.method, setMethod])
  return (
    <RadioButtons direction="row" formik={formik} name="method">
      <RadioButton formik={formik} label={t('byEmail')} name="method" value="email" />
      <RadioButton formik={formik} label={t('byPhone')} name="method" value="phone" />
    </RadioButtons>
  )
}

export const RestorePasswordByPhone = ({
  segmentSize,
  isItRestorePasswordPage,
  setMethod,
}: {
  segmentSize?: SegmentProps['size']
  isItRestorePasswordPage: boolean
  setMethod: (method: Method) => void
}) => {
  const { t } = useT('restorePassword')
  const resetPasswordByPhone = trpc.resetPasswordByPhone.useMutation()
  const requestVerificationCode = trpc.requestVerificationCode.useMutation()
  const [finished, setFinished] = useState(false)
  const { cacheRecord } = useCacheRecord({
    key: 'authForm',
    defaultValue: { phone: '' },
  })
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      method: 'phone',
      ...cacheRecord,
      newPassword: '',
      newPasswordAgain: '',
    },
    validationSchema: zResetPasswordByPhoneInput
      .omit({ verificationCode: true })
      .extend({
        method: zMethod,
        newPasswordAgain: zStringRequired,
      })
      .superRefine(zPasswordsMustBeTheSame('newPassword', 'newPasswordAgain')),
    onSubmit: async ({ phone, newPassword }) => {
      const input = { phone, newPassword }
      const { promptMessage } = await requestVerificationCode.mutateAsync({
        phone,
        procedureName: 'resetPasswordByPhone',
        procedureInput: input,
      })
      const verificationCode = prompt(promptMessage)
      await resetPasswordByPhone.mutateAsync({ ...input, verificationCode })
      setFinished(true)
    },
    successMessage: false,
  })
  useSyncCacheRecord({
    key: 'authForm',
    source: formik.values,
    toPickFromSource: ['phone'],
  })

  if (!finished) {
    return (
      <Segment title={t('restorePassword')} size={segmentSize}>
        <form onSubmit={formik.handleSubmit}>
          <FormItems>
            <ChangeMathod formik={formik} setMethod={setMethod} />
            <Input floatingPlaceholder={t('phoneNumber')} hint={t('phoneHint')} name="phone" formik={formik} />
            <Input floatingPlaceholder={t('newPassword')} name="newPassword" type="password" formik={formik} />
            <Input
              floatingPlaceholder={t('repeatNewPassword')}
              name="newPasswordAgain"
              type="password"
              formik={formik}
            />
            <Alert {...alertProps} />
            <Buttons>
              <Button type="submit" {...buttonProps}>
                {t('setNewPassword')}
              </Button>
              <SingInButton isItRestorePasswordPage={isItRestorePasswordPage} />
            </Buttons>
          </FormItems>
        </form>
      </Segment>
    )
  }

  return (
    <Segment title={t('restorePassword')}>
      <Alert
        color="green"
        message={t('newPasswordSetSuccess')}
        buttons={
          <Buttons>
            <SingInButton color="brandAccent" isItRestorePasswordPage={isItRestorePasswordPage} />
          </Buttons>
        }
      />
    </Segment>
  )
}

export const RestorePasswordByEmail = ({
  segmentSize,
  isItRestorePasswordPage,
  setMethod,
}: {
  segmentSize?: SegmentProps['size']
  isItRestorePasswordPage: boolean
  setMethod: (method: Method) => void
}) => {
  const { t } = useT('restorePassword')
  const restorePassword = trpc.restorePasswordByEmail.useMutation()
  const [finished, setFinished] = useState(false)
  const { cacheRecord } = useCacheRecord({
    key: 'authForm',
    defaultValue: { email: '' },
  })
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      method: 'email',
      ...cacheRecord,
    },
    validationSchema: zRestorePasswordByEmailInput.extend({
      method: zMethod,
    }),
    onSubmit: async (values) => {
      await restorePassword.mutateAsync(values)
      setFinished(true)
    },
    successMessage: false,
  })
  useSyncCacheRecord({
    key: 'authForm',
    source: formik.values,
    toPickFromSource: ['email'],
  })

  if (!finished) {
    return (
      <Segment title={t('restorePassword')} size={segmentSize}>
        <form onSubmit={formik.handleSubmit}>
          <FormItems>
            <ChangeMathod formik={formik} setMethod={setMethod} />
            <Input floatingPlaceholder={t('emailAddress')} hint={t('emailHint')} name="email" formik={formik} />
            <Alert {...alertProps} />
            <Buttons>
              <Button type="submit" {...buttonProps}>
                {t('restorePassword')}
              </Button>
              <SingInButton isItRestorePasswordPage={isItRestorePasswordPage} />
            </Buttons>
          </FormItems>
        </form>
      </Segment>
    )
  }

  return (
    <Segment title={t('restorePassword')}>
      <Alert color="green" message={t('emailSentSuccess')} />
    </Segment>
  )
}

export const RestorePassword = ({ segmentSize }: { segmentSize?: SegmentProps['size'] }) => {
  const { pathname } = useLocation()
  const [method, setMethod] = useState<Method>('email')
  const isItRestorePasswordPage = pathname === getRestorePasswordRoute()

  if (method === 'email') {
    return (
      <RestorePasswordByEmail
        setMethod={setMethod}
        segmentSize={segmentSize}
        isItRestorePasswordPage={isItRestorePasswordPage}
      />
    )
  } else {
    return (
      <RestorePasswordByPhone
        setMethod={setMethod}
        segmentSize={segmentSize}
        isItRestorePasswordPage={isItRestorePasswordPage}
      />
    )
  }
}
