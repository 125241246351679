import type { TrpcRouterOutput } from '@svag/backend/src/router'
import { zChangePhoneInput } from '@svag/backend/src/router/auth/changePhone/input'
import { zRequestEmailChangingInput } from '@svag/backend/src/router/auth/requestEmailChanging/input'
import { zUpdatePasswordInput } from '@svag/backend/src/router/auth/updatePassword/input'
import { zUpdateProfileInput } from '@svag/backend/src/router/auth/updateProfile/input'
import { getCountriesSelectOptions } from '@svag/shared/src/countries'
import { zPasswordsMustBeTheSame, zStringRequired } from '@svag/shared/src/zod'
import { FormItems } from '../../../components/form/FormItems'
import { Input } from '../../../components/form/Input'
import { Select } from '../../../components/form/Select'
import { Alert } from '../../../components/ui/Alert'
import { Button } from '../../../components/ui/Button'
import { Segment, Segments } from '../../../components/ui/Segment'
import { useForm } from '../../../lib/form'
import { useT } from '../../../lib/i18n'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { trpc } from '../../../lib/trpc'

const General = ({ me }: { me: NonNullable<TrpcRouterOutput['getMe']['me']> }) => {
  const { t } = useT('editProfilePage')
  const trpcUtils = trpc.useContext()
  const updateProfile = trpc.updateProfile.useMutation()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      name: me.name,
      country: me.country,
    },
    validationSchema: zUpdateProfileInput,
    onSubmit: async (values) => {
      const result = await updateProfile.mutateAsync(values)
      trpcUtils.getMe.setData(undefined, result)
    },
    successMessage: t('general.successMessage'),
    resetOnSuccess: false,
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormItems>
        <Input label={t('general.nameLabel')} name="name" formik={formik} />
        <Select
          label={t('general.countryLabel')}
          options={getCountriesSelectOptions()}
          name="country"
          formik={formik}
        />
        <Alert {...alertProps} />
        <Button type="submit" {...buttonProps}>
          {t('general.updateProfileButton')}
        </Button>
      </FormItems>
    </form>
  )
}

const Email = ({ me }: { me: NonNullable<TrpcRouterOutput['getMe']['me']> }) => {
  const { t } = useT('editProfilePage')
  const requestEmailChanging = trpc.requestEmailChanging.useMutation()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      newEmail: '',
    },
    validationSchema: zRequestEmailChangingInput,
    onSubmit: async (values) => {
      await requestEmailChanging.mutateAsync(values)
    },
    successMessage: t('email.successMessage'),
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormItems>
        <Input
          label={t('email.currentEmailLabel', { email: me.email })}
          placeholder={t('email.newEmailPlaceholder')}
          name="newEmail"
          formik={formik}
        />
        <Alert {...alertProps} />
        <Button type="submit" {...buttonProps}>
          {t('email.updateEmailButton')}
        </Button>
      </FormItems>
    </form>
  )
}

const Phone = ({ me }: { me: NonNullable<TrpcRouterOutput['getMe']['me']> }) => {
  const { t } = useT('editProfilePage')
  const changePhone = trpc.changePhone.useMutation()
  const requestVerificationCode = trpc.requestVerificationCode.useMutation()
  const trpcUtils = trpc.useContext()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      newPhone: '',
    },
    validationSchema: zChangePhoneInput.omit({ verificationCode: true }),
    onSubmit: async ({ newPhone }) => {
      const input = { newPhone }
      const { promptMessage } = await requestVerificationCode.mutateAsync({
        phone: newPhone,
        procedureName: 'changePhone',
        procedureInput: input,
      })
      const verificationCode = prompt(promptMessage)
      const { me } = await changePhone.mutateAsync({ ...input, verificationCode })
      trpcUtils.getMe.setData(undefined, { me })
    },
    successMessage: t('phone.successMessage'),
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormItems>
        <Input
          label={t('phone.currentPhoneLabel', { phone: me.phone })}
          placeholder={t('phone.newPhonePlaceholder')}
          name="newPhone"
          formik={formik}
        />
        <Alert {...alertProps} />
        <Button type="submit" {...buttonProps}>
          {t('phone.updatePhoneButton')}
        </Button>
      </FormItems>
    </form>
  )
}

const Password = () => {
  const { t } = useT('editProfilePage')
  const updatePassword = trpc.updatePassword.useMutation()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordAgain: '',
    },
    validationSchema: zUpdatePasswordInput
      .extend({
        newPasswordAgain: zStringRequired,
      })
      .superRefine(zPasswordsMustBeTheSame('newPassword', 'newPasswordAgain')),
    onSubmit: async ({ newPassword, oldPassword }) => {
      await updatePassword.mutateAsync({ newPassword, oldPassword })
    },
    successMessage: t('password.successMessage'),
    resetOnSuccess: true,
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormItems>
        <Input
          floatingPlaceholder={t('password.oldPasswordPlaceholder')}
          name="oldPassword"
          type="password"
          formik={formik}
        />
        <Input
          floatingPlaceholder={t('password.newPasswordPlaceholder')}
          name="newPassword"
          type="password"
          formik={formik}
        />
        <Input
          floatingPlaceholder={t('password.newPasswordAgainPlaceholder')}
          name="newPasswordAgain"
          type="password"
          formik={formik}
        />
        <Alert {...alertProps} />
        <Button type="submit" {...buttonProps}>
          {t('password.updatePasswordButton')}
        </Button>
      </FormItems>
    </form>
  )
}

export const EditProfilePage = withPageWrapper({
  title: ({ t }) => t('editProfilePage.pageTitle'),
  authorizedOnly: true,
  setProps: ({ getAuthorizedMe }) => ({
    me: getAuthorizedMe(),
  }),
  langs: ['ru', 'en'],
})(({ me }) => {
  const { t } = useT('editProfilePage')
  return (
    <Segment title={t('pageTitle')}>
      <Segments>
        <Segment title={t('general.title')} size="S">
          <General me={me} />
        </Segment>
        <Segment title={t('email.title')} size="S" id="email">
          <Email me={me} />
        </Segment>
        <Segment title={t('phone.title')} size="S">
          <Phone me={me} />
        </Segment>
        <Segment title={t('password.title')} size="S">
          <Password />
        </Segment>
      </Segments>
    </Segment>
  )
})
