import { Outlet } from 'react-router-dom'
import { useT } from '../../../lib/i18n'
import {
  getConsultationsRoute,
  getDashboardRoute,
  getEditProfileRoute,
  getEducationRoute,
  getGroupsRoute,
  getGuidesRoute,
  getHomeRoute,
} from '../../../lib/routes'
import { useDesktopOrMobile } from '../../../lib/useWindowSize'
import { GeneralLayout, GeneralLayoutNavLink } from '../GeneralLayout'

export const DashboardLayout = ({ children, showSidebar }: { children?: React.ReactNode; showSidebar?: boolean }) => {
  const { t } = useT('dashboardLayout')
  const { isDesktop } = useDesktopOrMobile()
  return (
    <GeneralLayout
      logoLinkPolicy="navigateToHome"
      showSidebar={showSidebar}
      generalNavigationChildren={
        <>
          {/* <GeneralLayoutNavLink to={getClientsPromoRoute()}>{t('generalMenu.clients')}</GeneralLayoutNavLink> */}
          {/* <GeneralLayoutNavLink to={getSpecialistsPromoRoute()}>{t('generalMenu.specialists')}</GeneralLayoutNavLink> */}
          <GeneralLayoutNavLink to={getHomeRoute()}>{t('generalMenu.main')}</GeneralLayoutNavLink>
          <GeneralLayoutNavLink to={getDashboardRoute()}>{t('accountMenu.dashboard')}</GeneralLayoutNavLink>
        </>
      }
      sidebarNavigationChildren={
        <>
          {isDesktop && (
            <GeneralLayoutNavLink to={getDashboardRoute()}>{t('accountMenu.dashboard')}</GeneralLayoutNavLink>
          )}
          <GeneralLayoutNavLink
            subLinks={
              <>
                <GeneralLayoutNavLink to={getGuidesRoute()}>{t('accountMenu.guides')}</GeneralLayoutNavLink>
                <GeneralLayoutNavLink to={getConsultationsRoute()}>
                  {t('accountMenu.consultations')}
                </GeneralLayoutNavLink>
                <GeneralLayoutNavLink to={getGroupsRoute()}>{t('accountMenu.groups')}</GeneralLayoutNavLink>
              </>
            }
            to={getEducationRoute()}
          >
            {t('accountMenu.education')}
          </GeneralLayoutNavLink>
          {/* <GeneralLayoutNavLink to={getCommuinityRoute()}>{t('accountMenu.community')}</GeneralLayoutNavLink> */}
          <GeneralLayoutNavLink to={getEditProfileRoute()}>{t('accountMenu.profile')}</GeneralLayoutNavLink>
        </>
      }
    >
      {children || <Outlet />}
    </GeneralLayout>
  )
}
