import { toMoney } from '@svag/shared/src/money'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { openSignUpModal } from '../../../components/auth/Auth'
import { openPurchaseProductModal } from '../../../components/billing/PurchaseProduct/store'
import { Alert } from '../../../components/ui/Alert'
import { Button } from '../../../components/ui/Button'
import { Loader } from '../../../components/ui/Loader'
import { useMe } from '../../../lib/ctx'
import { useT } from '../../../lib/i18n'
import { usePromoCodeValue } from '../../../lib/promoCode'
import { getGuideHomeRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

const PromoCodeInformer = ({
  className,
  forcePromoCodeValue,
  guideId,
  hideBorder = false,
}: {
  className?: string
  guideId: string
  forcePromoCodeValue?: string
  hideBorder?: boolean
}) => {
  const { t } = useT('guidePlans')
  const { promoCodeValue } = usePromoCodeValue(forcePromoCodeValue)
  const queryResult = trpc.getPromoCode.useQuery({
    guideId,
    promoCodeValue,
  })
  const promoCode = queryResult.data?.promoCode
  if (!promoCode) {
    return null
  }
  return (
    <div className={cn(css.promoCodeInformer, className)}>
      <Alert
        fullWidth={true}
        hideBorder={hideBorder}
        title={`${t('promoCodeActive')} ${promoCode.value}! ${t('discount')} ${promoCode.salePercent}%`}
        message={t('promoCodeMessage')}
        color="green"
      />
    </div>
  )
}

export const GuidePlans = ({
  className,
  guideSlug,
  hideBorder = false,
  withStartButton = false,
  forcePromoCodeValue,
}: {
  className?: string
  guideSlug: string
  hideBorder?: boolean
  withStartButton?: boolean
  forcePromoCodeValue?: string
}) => {
  const { t } = useT('guidePlans')
  const navigate = useNavigate()
  const { promoCodeValue } = usePromoCodeValue(forcePromoCodeValue)
  const me = useMe()
  const queryResult = trpc.getStudentGuideFull.useQuery(
    {
      guideSlug,
      promoCodeValue,
    },
    {
      staleTime: 3000,
    }
  )
  if (queryResult.isLoading) {
    return (
      <div className={cn(css.plans, className)}>
        <Loader type="section" />
      </div>
    )
  }
  if (queryResult.isError) {
    return (
      <div className={cn(css.plans, className)}>
        <Alert message={queryResult.error.message} color="red" />
      </div>
    )
  }
  const guide = queryResult.data.guide
  return (
    <>
      <PromoCodeInformer hideBorder={hideBorder} forcePromoCodeValue={promoCodeValue} guideId={guide.id} />
      <div className={cn({ [css.plans]: true, [className || '']: !!className, [css.hideBorder]: hideBorder })}>
        {guide.plans.map((plan) => {
          const oldPriceExists = plan.priceUsd !== plan.defaultPriceUsd
          const priceExists = plan.priceUsd !== 0 || !oldPriceExists
          const pricesExists = oldPriceExists || priceExists
          if (!plan.isCurrent && plan.deprecated) {
            return null
          }
          return (
            <div
              className={cn({ [css.plan]: true, [css.current]: plan.isCurrent, [css.unavailable]: !plan.isAvailable })}
              key={plan.name}
            >
              {plan.isCurrent && <div className={css.currentPlanLabel}>{t('currentTariff')}</div>}
              <h2 className={css.title}>{plan.title}</h2>
              <div className={css.desc}>{plan.description}</div>
              {pricesExists && plan.isAvailable && (
                <div className={css.prices}>
                  {oldPriceExists && (
                    <div className={css.oldPrice}>
                      <span className={css.label}>{t('oldPrice')}:</span>
                      <span className={css.value}>
                        {toMoney({
                          amount: plan.defaultPriceUsd,
                          currency: 'usd',
                        })}{' '}
                        {t('or')}{' '}
                        {toMoney({
                          amount: plan.defaultPriceRub,
                          currency: 'rub',
                        })}
                      </span>
                    </div>
                  )}
                  {priceExists && (
                    <div className={css.price}>
                      <span className={css.label}>{oldPriceExists ? t('discountPrice') : t('cost')}:</span>
                      <span className={css.value}>
                        {toMoney({
                          amount: plan.priceUsd,
                          currency: 'usd',
                        })}{' '}
                        {t('or')}{' '}
                        {toMoney({
                          amount: plan.priceRub,
                          currency: 'rub',
                        })}
                      </span>
                    </div>
                  )}
                </div>
              )}
              {plan.isAvailable && (
                <Button
                  size="l"
                  className={css.button}
                  onClick={() => {
                    openPurchaseProductModal({
                      type: 'guide',
                      options: {
                        guideId: guide.id,
                        guidePlan: plan.name,
                        promoCodeValue,
                      },
                    })
                  }}
                >
                  {t('upgradeButton', { planTitle: plan.title })}
                </Button>
              )}
              {plan.name === 'free' && plan.isCurrent && withStartButton && (
                <>
                  {!me ? (
                    <Button
                      size="l"
                      className={css.button}
                      color="brandAccentLight"
                      onClick={() => {
                        openSignUpModal({
                          alertMessage: t('startLearningFreeAlert'),
                          onSuccess: () => {
                            navigate(getGuideHomeRoute({ guideSlug: guide.slug }))
                          },
                        })
                      }}
                    >
                      {t('startLearningFree')}
                    </Button>
                  ) : (
                    <Button
                      size="l"
                      className={css.button}
                      color="brandAccentLight"
                      to={getGuideHomeRoute({ guideSlug: guide.slug })}
                    >
                      {t('startLearningFree')}
                    </Button>
                  )}
                </>
              )}
              {plan.name !== 'free' && plan.isCurrent && withStartButton && (
                <Button
                  size="l"
                  className={css.button}
                  color="brandAccentLight"
                  to={getGuideHomeRoute({ guideSlug: guide.slug })}
                >
                  {t('continueLearning')}
                </Button>
              )}
            </div>
          )
        })}
      </div>
    </>
  )
}
