import { Button } from '../../../../../components/ui/Button'
import { T, useT } from '../../../../../lib/i18n'
import css from './index.module.scss'

export const SpecialistsPageIntro = () => {
  const { t } = useT('specialistsPageIntro')
  return (
    <div className={css.intro}>
      <div className={css.wrapper}>
        <h1 className={css.title}>
          <span className={css.text}>
            <T
              t={t}
              k="header"
              components={{
                nowrap: <span className={css.nowrap} />,
                'ru-grdnt-1': <span className={css.ruGrdnt1} />,
                'ru-grdnt-2': <span className={css.ruGrdnt2} />,
                'ru-grdnt-3': <span className={css.ruGrdnt3} />,
                'en-grdnt-1': <span className={css.enGrdnt1} />,
                'en-grdnt-2': <span className={css.enGrdnt2} />,
              }}
            />
          </span>
        </h1>
        <p className={css.desc}>
          <T
            t={t}
            k="desc"
            components={{
              productology: <span className={css.productology} />,
              development: <span className={css.development} />,
              design: <span className={css.design} />,
            }}
          />
        </p>
        <Button
          size="l"
          href="https://cal.com/svag.group/30min"
          rel="noreferrer"
          target="_blank"
          className={css.button}
          eventElementGroup="homeContactButton"
          eventElementId="homeIntroContactButton"
        >
          {t('button')}
        </Button>
      </div>
    </div>
  )
}
