import { getErrorData } from '@svag/shared/src/error'
import { AccessDeniedPageComponent } from '../AccessDeniedPageComponent'
import { AuthorizedOnlyPageComponent } from '../AuthorizedOnlyPageComponent'
import { ErrorPageComponent } from '../ErrorPageComponent'
import { NotFoundPageComponent } from '../NotFoundPageComponent'

export const ErrorBasedErrorPageComponent = ({ error }: { error: any }) => {
  const { errorType, errorTitle } = getErrorData(error)
  switch (errorType) {
    case 'UnauthorizedError':
      return <AuthorizedOnlyPageComponent title={errorTitle} message={error.message} />
    case 'AccessDeniedError':
      return <AccessDeniedPageComponent title={errorTitle} message={error.message} />
    case 'NotFoundError':
      return <NotFoundPageComponent title={errorTitle} message={error.message} />
    default:
      return <ErrorPageComponent title={errorTitle} message={error.message} />
  }
}
