/* eslint-disable @typescript-eslint/naming-convention */
import { useStore } from '@nanostores/react'
import {
  appendUtmsHistory,
  getEventDataByUtmsHistory,
  isUtmsHistoriesEqual,
  parseJsonUtmsHistory,
  type UTMSDated,
  type UTMS,
  type UTMSHistory,
} from '@svag/shared/src/utm'
import Cookies from 'js-cookie'
import { atom } from 'nanostores'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { appContextStore, useConfig, useMe } from './ctx'
import { trackUserData } from './mixpanel'
import { sentryCaptureException } from './sentry'
import { trpc } from './trpc'

const getUtmsHistoryFromCookies = () => {
  const utmsHistory = parseJsonUtmsHistory(Cookies.get('utmsHistory'))
  return utmsHistory
}

export const utmsHistoryStore = atom<{ meUtmsHistory: UTMSHistory | null; utmsHistory: UTMSHistory }>({
  utmsHistory: getUtmsHistoryFromCookies(),
  meUtmsHistory: appContextStore.get().me?.utmsHistory || null,
})

export const useCurrentUtms = () => {
  const utmsHistory = useStore(utmsHistoryStore).utmsHistory
  const currentUtms: UTMSDated | undefined = utmsHistory[0]
  return { currentUtms }
}

export const UtmsWatcher = () => {
  const [searchParams] = useSearchParams()
  const searchParamsObject = Object.fromEntries(searchParams.entries())
  const searchParamsObjectStringifyed = JSON.stringify(searchParamsObject)
  const [searchParamsUtms, setSearchParamsUtms] = useState<UTMS>({})
  const searchParamsUtmsStringifyed = JSON.stringify(searchParamsUtms)
  const me = useMe()
  const config = useConfig()
  // const [utmsHistory, setUtmsHistory] = useState<UTMSHistory>(getUtmsHistoryFromCookies())
  const utmsHistoryState = useStore(utmsHistoryStore)
  const utmsHistory = utmsHistoryState.utmsHistory
  const utmsHistoryStringifyed = JSON.stringify(utmsHistory)
  const meUtmsHistory = utmsHistoryState.meUtmsHistory
  const meUtmsHistoryStringifyed = JSON.stringify(meUtmsHistory)
  const updateUtmsHistory = trpc.updateUtmsHistory.useMutation()
  const trpcUtils = trpc.useContext()

  useEffect(() => {
    if (me) {
      utmsHistoryStore.set({
        utmsHistory: utmsHistoryStore.get().utmsHistory,
        meUtmsHistory: me.utmsHistory,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me])

  useEffect(() => {
    const utm_source = searchParamsObject.utm_source
    const utm_medium = searchParamsObject.utm_medium
    const utm_campaign = searchParamsObject.utm_campaign
    const utm_term = searchParamsObject.utm_term || searchParamsObject.utm_keyword || searchParamsObject.keyword
    const utm_content = searchParamsObject.utm_content
    setSearchParamsUtms({ utm_source, utm_medium, utm_campaign, utm_term, utm_content })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParamsObjectStringifyed])

  const isSearchParamsUtmsExists =
    searchParamsUtms.utm_source ||
    searchParamsUtms.utm_medium ||
    searchParamsUtms.utm_campaign ||
    searchParamsUtms.utm_term ||
    searchParamsUtms.utm_content
  useEffect(() => {
    if (isSearchParamsUtmsExists) {
      const currentUtmsHistory = getUtmsHistoryFromCookies()
      const newUtmsHistory = appendUtmsHistory(currentUtmsHistory, { ...searchParamsUtms, dateISO: config.nowISO })
      utmsHistoryStore.set({
        utmsHistory: newUtmsHistory,
        meUtmsHistory: utmsHistoryStore.get().meUtmsHistory,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParamsUtmsStringifyed, isSearchParamsUtmsExists])

  useEffect(() => {
    if (meUtmsHistory) {
      if (isUtmsHistoriesEqual(meUtmsHistory, utmsHistory)) {
        return
      }
      void (async () => {
        try {
          const result = await updateUtmsHistory.mutateAsync({
            utmsHistory,
          })
          utmsHistoryStore.set({
            utmsHistory: result.me.utmsHistory,
            meUtmsHistory: result.me.utmsHistory,
          })
          trackUserData({ utmsHistory: result.me.utmsHistory, utms: result.me.utms })
          Cookies.set('utmsHistory', JSON.stringify(result.me.utmsHistory), { expires: 99999 })
          trpcUtils.getMe.setData(undefined, result)
        } catch (error: any) {
          sentryCaptureException(error)
        }
      })()
    } else {
      trackUserData({ utmsHistory, ...getEventDataByUtmsHistory(utmsHistory) })
      Cookies.set('utmsHistory', JSON.stringify(utmsHistory), { expires: 99999 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meUtmsHistoryStringifyed, utmsHistoryStringifyed])

  return null
}
