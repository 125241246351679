import { useEffect } from 'react'
import './styles.scss'
import { copyToClipboard } from '../../../lib/copyToClipboard'

export const CopyCode = () => {
  useEffect(() => {
    const handler = (e: MouseEvent) => {
      const target = e.target as HTMLElement
      if (target.tagName === 'CODE' || target.tagName === 'PRE') {
        const text = target.innerText
        copyToClipboard(text)
      }
    }
    document.body.addEventListener('click', handler)

    return () => {
      document.body.removeEventListener('click', handler)
    }
  })

  return null
}
