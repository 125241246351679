import { useStore } from '@nanostores/react'
import { action } from 'nanostores'
import { cookiesAtom } from '../utils/cookiesNanostores'

type GuideSettings = {
  commitsFormat: 'side' | 'line' | 'hidden'
  desktopMenuHidden: boolean
}

const defaultGuideSettings: GuideSettings = {
  commitsFormat: 'side',
  desktopMenuHidden: false,
}

export const guidesSettingsStore = cookiesAtom<Record<string, GuideSettings>>(
  'guidesSettings',
  {},
  {
    encode(value) {
      return JSON.stringify(value)
    },
    decode(value) {
      try {
        return JSON.parse(value)
      } catch (err) {
        return {}
      }
    },
  }
)

export const setGuideSettings = action(
  guidesSettingsStore,
  'openSignInModal',
  (store, guideSlug: string, settingsUpdate: Partial<GuideSettings>) => {
    const currentGuideSettings = store.get()[guideSlug] || {}
    store.set({
      ...store.get(),
      [guideSlug]: {
        ...defaultGuideSettings,
        ...currentGuideSettings,
        ...settingsUpdate,
      },
    })
  }
)

export const useGuideSettings = (guideSlug: string) => {
  const guidesSettings = useStore(guidesSettingsStore)
  return {
    ...defaultGuideSettings,
    ...(guidesSettings[guideSlug] || {}),
  }
}
