import type { TrpcRouterOutput } from '@svag/backend/src/router'
import { VideoKinescope } from '../../../../components/other/VideoKinescope'
import css from './index.module.scss'

export const GuideLessonVideoKinescope = ({
  lesson,
}: {
  lesson: TrpcRouterOutput['getStudentGuideLesson']['lesson']
}) => {
  if (!lesson.kinescopeVideoId) {
    return null
  }
  const width = lesson.videoWidth || 560
  const height = lesson.videoHeight || 315
  return (
    <div className={css.videoOuterOuter}>
      <VideoKinescope videoId={lesson.kinescopeVideoId} width={width} height={height} />
    </div>
  )
}
