import format from 'date-fns/format'
import React from 'react'
import { useT } from '../../../../../lib/i18n'
import { Button, Buttons } from '../../../../ui/Button'
import { Card } from '../../../../ui/Card'
import { LabelValueInfo } from '../../../../ui/LabelValueInfo'
import { type EdItem } from '../../EdCard'

export const GroupCardActiveStudent = ({
  setRef,
  groupInstance,
}: {
  setRef: React.RefCallback<any>
  groupInstance: Extract<EdItem, { type: 'group' }>
}) => {
  const { t } = useT('groupCardActiveStudent')
  const { t: tg } = useT('general')
  return (
    <Card
      setRef={setRef}
      title={`${groupInstance.title}`}
      hintColor={groupInstance.direction}
      hintIconName="group"
      hintText={t('hintTextPrefix') + ' / ' + tg(`directions.${groupInstance.direction}`)}
      content={
        <LabelValueInfo
          size="s"
          items={[
            ...(groupInstance.myMembershipValidUntil
              ? [
                  {
                    label: t('membershipValidUntilLabel'),
                    value: format(new Date(groupInstance.myMembershipValidUntil), 'dd.MM.yyyy'),
                  },
                ]
              : []),
            {
              label: t('descriptionLabel'),
              value: groupInstance.desc,
            },
          ]}
        />
      }
      buttons={
        <Buttons stretch>
          <Button to={groupInstance.urlPath} color="brandAccentLight">
            {t('detailsButton')}
          </Button>
        </Buttons>
      }
    />
  )
}
