import cn from 'classnames'
import { type FormikProps } from 'formik'
import { useState } from 'react'
import css from './index.module.scss'

export const Input: React.FC<{
  label?: string
  placeholder?: string
  floatingPlaceholder?: string
  name: string
  formik: FormikProps<any>
  maxWidth?: number | string
  type?: 'text' | 'password'
  hint?: string
  disabled?: boolean
}> = ({ label, name, formik, maxWidth, type = 'text', floatingPlaceholder, placeholder, hint, disabled }) => {
  const value = formik.values[name]
  const error = formik.errors[name] as string | undefined
  const touched = formik.touched[name] as boolean
  const invalid = touched && !!error
  const disabledHere = disabled || formik.isSubmitting
  const [focused, setFocused] = useState(false)

  return (
    <div
      className={cn({
        [css.field]: true,
        [css.disabled]: disabledHere,
        [css.filled]: !!value?.toString().length,
        [css.invalid]: invalid,
        [css.focused]: focused,
      })}
      style={{ maxWidth }}
    >
      {label && (
        <label className={css.label} htmlFor={name}>
          {label}
        </label>
      )}
      {floatingPlaceholder && (
        <div className={css.floatingPlaceholder}>
          <div className={css.floatingPlaceholderText}>{floatingPlaceholder}</div>
        </div>
      )}
      <div className={cn({ [css.inputPlace]: true, [css.invalid]: invalid })}>
        <input
          className={cn({ [css.input]: true })}
          disabled={disabledHere}
          type={type}
          onChange={(e) => {
            void formik.setFieldValue(name, e.target.value)
          }}
          onFocus={() => {
            setFocused(true)
          }}
          onBlur={() => {
            formik.setFieldTouched(name)
            setFocused(false)
          }}
          value={value || ''}
          name={name}
          id={name}
          placeholder={placeholder}
        />
      </div>
      {invalid && <div className={css.error}>{error}</div>}
      {hint && <div className={css.hint}>{hint}</div>}
    </div>
  )
}
