import { RichText } from '../../components/ui/RichText'
import { useLang } from '../../lib/i18n'
import { withPageWrapper } from '../../lib/pageWrapper'
import { DocsFooterEn, DocsFooterRu } from './DocsFooter'

const Ru = () => {
  return (
    <>
      <h1>Пользовательское соглашение</h1>
      <p>Последнее обновление: 17.10.2023</p>
      <p>
        Эти Условия предоставления услуг ("Условия") регулируют ваше использование нашего веб-сайта, расположенного по
        адресу <a href="https://svag.group">https://svag.group</a> ("Веб-сайт"), и являются юридически обязывающим
        договором между вами, пользователем Веб-сайта, и ИП "Сергей Дмитриев Игоревич", индивидуальным предпринимателем
        Армении, именуемым в дальнейшем "SVAG", "мы", "нас" или "наш".
      </p>
      <h3>Принятие условий</h3>
      <p>Используя наш Веб-сайт, вы соглашаетесь соблюдать и быть связанным этими Условиями.</p>
      <h3>Предлагаемые услуги</h3>
      <ul>
        <li>
          Доступ к онлайн-учебнику: После оплаты пользователи получат доступ к онлайн-учебнику согласно условию
          тарифного плана описанному странице каждого отдельного учебника.
        </li>
        <li>
          Онлайн-консультации: Предоплата требуется для консультаций. Дополнительная оплата может потребоваться для
          продленных консультаций. В консультации может быть отказано без оъяснения причин, в таком случае денги за
          консультацию будут возвращены.
        </li>
        <li>
          Доступ к групповому обучению: Требуется оплата за один месяц групповых занятий. В участии в группе может быть
          отказано без оъяснения причин, в таком случае денги оплаченные за участие в группе в этом месяце будут
          возвращены.
        </li>
        <li>
          Дополнительные услуги: Оплата за разработку, дизайн и другие услуги также доступна. Для оплаты услуг
          необходимо связаться с нами по почте <a href="mailto:manager@svag.group">manager@svag.group</a>
        </li>
      </ul>
      <p>Для покупки любой услуги вы должны зарегистрироваться на Веб-сайте, указав свой адрес электронной почты.</p>
      <h3>Оплата</h3>
      <p>
        Вы можете выбрать предпочитаемую валюту в момент оплаты того или иного продукта или услуги. Цены указаны в
        долларах США и российских рублях. Цены конвертируются в разные валюты не по курсу той или иной валюты, а
        устанавливаются нами отдельно для каждой валюты.
      </p>
      <h3>Политика возврата</h3>
      <p>
        Если вы хотите запросить возврат средств, пожалуйста, отправьте электронное письмо на{' '}
        <a href="mailto:manager@svag.group">manager@svag.group</a>. Письмо должно быть отправлено вами с той же почты,
        которая сейчас установлена для аккаунта, скоторого была произведена оплата на этом сайте.
      </p>
      <h3>Применимое право</h3>
      <p>Этот договор регулируется законами Республики Армения.</p>
      <h3>Изменения в условиях</h3>
      <p>SVAG оставляет за собой право изменять эти Условия в любое время.</p>
      <h3>Связаться с нами</h3>
      <p>
        Если у вас есть вопросы по поводу этих Условий, пожалуйста, свяжитесь с нами по адресу{' '}
        <a href="mailto:manager@svag.group">manager@svag.group</a>
      </p>
      <DocsFooterRu />
    </>
  )
}

const En = () => {
  return (
    <>
      <h1>Terms of Service</h1>
      <p>Last updated: 17.10.2023</p>
      <p>
        These Terms of Service ("Terms") govern your use of our website located at{' '}
        <a href="https://svag.group">https://svag.group</a> ("Website") and form a legally binding contract between you,
        the user of the Website, and PE "Sergey Dmitriev Igorevich", the individual entrepreneur of Armenia, hereinafter
        referred to as "SVAG", "us", "we" or "our".
      </p>
      <h3>Acceptance of Terms</h3>
      <p>By using our Website, you agree to comply with and be bound by these Terms.</p>
      <h3>Services Offered</h3>
      <ul>
        <li>
          Access to online textbook: After payment, users will gain access to the online textbook according to the terms
          of the pricing plan described on the page of each individual textbook.
        </li>
        <li>
          Online consultations: Prepayment is required for consultations. Additional payment may be needed for extended
          consultations. Consultations can be denied without explanation, in which case the money for the consultation
          will be refunded.
        </li>
        <li>
          Access to group training: Payment is required for one month of group sessions. Participation in the group can
          be denied without explanation, in which case the money paid for participation in the group for that month will
          be refunded.
        </li>
        <li>
          Additional services: Payment for development, design, and other services is also available. To make a payment
          for services, please contact us via email at <a href="mailto:manager@svag.group">manager@svag.group</a>
        </li>
      </ul>
      <p>To purchase any service, you must register on the Website using your email address.</p>
      <h3>Payment</h3>
      <p>
        You can choose your preferred currency at the time of payment for a particular product or service. Prices are
        listed in US dollars and Russian rubles. The prices are not converted based on the exchange rate of any
        particular currency but are set by us separately for each currency.
      </p>
      <h3>Refund Policy</h3>
      <p>
        If you would like to request a refund, please send an email to{' '}
        <a href="mailto:manager@svag.group">manager@svag.group</a>. The email should be sent from the same address that
        is currently associated with the account from which payment was made on this website.
      </p>
      <h3>Governing Law</h3>
      <p>This agreement is governed by the laws of the Republic of Armenia.</p>
      <h3>Changes to Terms</h3>
      <p>SVAG reserves the right to change these Terms at any time.</p>
      <h3>Contact Us</h3>
      <p>
        If you have any questions about these Terms, please contact us at{' '}
        <a href="mailto:manager@svag.group">manager@svag.group</a>
      </p>

      <DocsFooterEn />
    </>
  )
}

export const TermsPage = withPageWrapper({
  title: ({ lang }) => (lang === 'ru' ? 'Пользовательское соглашение' : 'Terms of Service'),
  langs: ['ru', 'en'],
})(() => {
  const { lang } = useLang()
  return <RichText>{lang === 'ru' ? <Ru /> : <En />}</RichText>
})
