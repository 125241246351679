import { useStore } from '@nanostores/react'
import type { ProductIdentifier } from '@svag/backend/src/services/product'
import { toMoney } from '@svag/shared/src/money'
import cn from 'classnames'
import { useMe } from '../../../lib/ctx'
import { useCurrency } from '../../../lib/currency'
import { useT } from '../../../lib/i18n'
import {
  trackBlockedPurchaseBecouseEmailNotConfirmed,
  trackBlockedPurchaseBecouseNotAuthorized,
  trackStartPurchase,
} from '../../../lib/mixpanel'
import { trpc } from '../../../lib/trpc'
import { Auth } from '../../auth/Auth'
import { ConfirmEmailBlocker } from '../../auth/ConfirmEmailBlocker'
import { FormItems } from '../../form/FormItems'
import { OnRender } from '../../other/OnRender'
import { Alert } from '../../ui/Alert'
import { Loader } from '../../ui/Loader'
import { Modal } from '../../ui/Modal'
import { Segment } from '../../ui/Segment'
import { PaymentButtonPaw } from './PaymentButtonPaw'
import { PaymentButtonVerifone } from './PaymentButtonVerifone'
import css from './index.module.scss'
import { closePurchaseProductModal, purchaseProductStore } from './store'

export const PurchaseProduct = ({ productIdentifier }: { productIdentifier: ProductIdentifier }) => {
  const { t } = useT('purchaseProduct')
  const { currency, setCurrency } = useCurrency()
  const purchaseProductState = useStore(purchaseProductStore)
  const queryResult = trpc.getProduct.useQuery(productIdentifier)
  if (queryResult.isLoading) {
    return <Loader type="page" />
  }
  if (queryResult.error) {
    return <Alert color="red" message={queryResult.error.message} />
  }

  const { product } = queryResult.data
  return (
    <Segment size="S" title={`${t('title')}: ${product.title}`}>
      <OnRender
        handler={() => {
          trackStartPurchase({
            product,
            productIdentifier,
          })
        }}
        deps={[JSON.stringify(product)]}
      />
      <FormItems>
        {purchaseProductState.isCurrenciesVisible && (
          <div className={css.currencies}>
            <div className={css.label}>{t('currencySelection')}</div>
            <div className={css.items}>
              <div
                onClick={() => {
                  setCurrency('usd')
                }}
                className={cn({ [css.currency]: true, [css.active]: currency === 'usd' })}
              >
                <p className={css.price}>{toMoney({ amount: product.amountUsd, currency: 'usd' })}</p>
                <p className={css.currencyDesc}>{t('currencyDescUSD')}</p>
              </div>
              <div
                onClick={() => {
                  setCurrency('rub')
                }}
                className={cn({ [css.currency]: true, [css.active]: currency === 'rub' })}
              >
                <p className={css.price}>{toMoney({ amount: product.amountRub, currency: 'rub' })}</p>
                <p className={css.currencyDesc}>{t('currencyDescRUB')}</p>
              </div>
            </div>
          </div>
        )}
        {currency === 'usd' ? (
          <PaymentButtonVerifone product={product} productIdentifier={productIdentifier} />
        ) : (
          <PaymentButtonPaw product={product} productIdentifier={productIdentifier} />
        )}
      </FormItems>
    </Segment>
  )
}

export const PurchaseProductModal = () => {
  const purchaseProductState = useStore(purchaseProductStore)
  const me = useMe()
  const { t } = useT('purchaseProduct')

  return (
    <Modal
      maxWidth={480}
      isOpen={purchaseProductState.isModalOpen}
      onRequestClose={() => {
        closePurchaseProductModal()
      }}
    >
      {!me ? (
        <>
          <OnRender
            handler={() => {
              trackBlockedPurchaseBecouseNotAuthorized({
                productIdentifier: purchaseProductState.productIdentifier,
              })
            }}
            deps={[JSON.stringify(purchaseProductState.productIdentifier)]}
          />
          <Auth policy="component" initialChoice="signUp" alertMessage={t('authAlert')} />
        </>
      ) : !me.emailConfirmed ? (
        <>
          <OnRender
            handler={() => {
              trackBlockedPurchaseBecouseEmailNotConfirmed({
                productIdentifier: purchaseProductState.productIdentifier,
              })
            }}
            deps={[JSON.stringify(purchaseProductState.productIdentifier)]}
          />
          <ConfirmEmailBlocker segmentSize="M" alertMessage={t('confirmEmailAlert')} />
        </>
      ) : (
        <PurchaseProduct productIdentifier={purchaseProductState.productIdentifier} />
      )}
    </Modal>
  )
}
