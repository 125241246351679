import minBy from 'lodash/minBy'
import range from 'lodash/range'
import times from 'lodash/times'
import React, { useEffect, useRef, useState } from 'react'
import { useWindowSize } from '../../../lib/useWindowSize'

// export type ColumnFillerItemWithIndividualRenderType<
//   TItem extends { render: ColumnFillerItemRenderType<TItem> } = { render: ColumnFillerItemRenderType }
// > = TItem
// export type ColumnFillerItemWithoutIndividualRenderType<TItem extends {} = {}> = TItem
// export type ColumnFillerItemType<
//   TItem extends
//     | ColumnFillerItemWithIndividualRenderType
//     | ColumnFillerItemWithoutIndividualRenderType = ColumnFillerItemWithoutIndividualRenderType
// > = TItem extends ColumnFillerItemWithIndividualRenderType
//   ? ColumnFillerItemWithIndividualRenderType<TItem>
//   : ColumnFillerItemWithoutIndividualRenderType<TItem>
export type ColumnFillerItemType<
  TItem extends Record<string, any> & { render?: ColumnFillerItemRenderType } = Record<string, any> & {
    render?: ColumnFillerItemRenderType
  }
> = TItem
export type ColumnFillerItemsType<TItem extends ColumnFillerItemType> = Array<ColumnFillerItemType<TItem>>
export type ColumnFillerItemKeyKeyType<TItem extends ColumnFillerItemType = ColumnFillerItemType> =
  keyof ColumnFillerItemType<TItem>
export type ColumnFillerItemRenderType<TItem extends {} = {}> = (props: {
  item: TItem
  index: number
  setItemRef: React.RefCallback<any>
}) => JSX.Element

const Item = ({
  index,
  itemRender,
  item,
  itemKeyKey,
  setItemsHeights,
  itemsHeights,
}: {
  index: number
  item: ColumnFillerItemType<any>
  itemKeyKey: ColumnFillerItemKeyKeyType<any>
  itemRender: ColumnFillerItemRenderType<any>
  itemsHeights: Record<string, number>
  setItemsHeights: React.Dispatch<React.SetStateAction<Record<string, number>>>
}) => {
  const { width: windowWidth } = useWindowSize()
  const itemRef = useRef<HTMLElement | null>()
  useEffect(() => {
    const height = itemRef.current?.offsetHeight || 0
    if (itemsHeights[item[itemKeyKey] as string] !== height) {
      setItemsHeights({
        ...itemsHeights,
        [item[itemKeyKey]]: height,
      })
    }
  }, [itemRef, setItemsHeights, itemsHeights, item, itemKeyKey, windowWidth])
  return itemRender({
    item,
    index,
    setItemRef: (ref) => (itemRef.current = ref),
  })
}

export const ColumnFiller = <TItem extends ColumnFillerItemType = ColumnFillerItemType>({
  items,
  itemKeyKey = 'key' as keyof TItem,
  columnRender,
  columnsCount,
  itemRender,
}: {
  items: ColumnFillerItemsType<TItem>
  itemKeyKey?: ColumnFillerItemKeyKeyType<TItem>
  columnRender: (props: { index: number; children: React.ReactNode; width: string }) => JSX.Element
  columnsCount: number
  itemRender?: ColumnFillerItemRenderType<TItem>
}) => {
  const [itemsWithColumnIndex, setItemsWithColumnIndex] = useState<Array<{ item: TItem; columnIndex: number }>>(
    items.map((item) => ({ item, columnIndex: 0 }))
  )
  const columnWidth = `${(100 / columnsCount).toFixed(3)}%`
  const [itemsHeights, setItemsHeights] = useState<Record<string, number>>({})

  useEffect(() => {
    const normalizeItemsWithColumnIndex = () => {
      const newItemsWithColumnIndex: Array<{ item: TItem; columnIndex: number }> = []
      const columnsHeights = times(columnsCount, () => 0)
      for (const item of items) {
        const itemHeight = itemsHeights[item[itemKeyKey] as string] || 0
        const lowestColumnIndex = minBy(range(columnsCount), (columnIndex) => columnsHeights[columnIndex]) || 0
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        columnsHeights[lowestColumnIndex] += itemHeight
        newItemsWithColumnIndex.push({ item, columnIndex: lowestColumnIndex })
      }
      setItemsWithColumnIndex(newItemsWithColumnIndex)
    }
    normalizeItemsWithColumnIndex()
  }, [items, itemsHeights, columnsCount, itemKeyKey])

  return (
    <>
      {times(columnsCount, (columnIndex) => {
        return columnRender({
          index: columnIndex,
          width: columnWidth,
          children: (
            <>
              {itemsWithColumnIndex.map(
                (itemWithColumnIndex, itemIndex) =>
                  columnIndex === itemWithColumnIndex.columnIndex && (
                    <Item
                      key={itemWithColumnIndex.item[itemKeyKey] as string}
                      item={itemWithColumnIndex.item}
                      itemKeyKey={itemKeyKey}
                      index={itemIndex}
                      itemRender={itemWithColumnIndex.item.render || itemRender || (() => <></>)}
                      itemsHeights={itemsHeights}
                      setItemsHeights={setItemsHeights}
                    />
                  )
              )}
            </>
          ),
        })
      })}
    </>
  )
}
