import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { removeLangFromRoutePathname } from '../../../lib/i18n'

export const ScrollTopRouter = () => {
  const { pathname } = useLocation()
  const prevPathnameRef = useRef<string | null>(null)
  const isSimilarPathnames = (pn1: string | null, pn2: string | null) => {
    if (!pn1 || !pn2) {
      return false
    }
    const p1Langless = removeLangFromRoutePathname(pn1)
    const p2Langless = removeLangFromRoutePathname(pn2)
    return p1Langless === p2Langless
  }

  useEffect(() => {
    const prevPathname = prevPathnameRef.current
    if (!isSimilarPathnames(pathname, prevPathname)) {
      window.scrollTo(0, 0)
    }
    if (pathname !== prevPathname) {
      prevPathnameRef.current = pathname
    }
  }, [pathname])

  return null
}
