import type { TrpcRouterOutput } from '@svag/backend/src/router'
import type { ProductIdentifier } from '@svag/backend/src/services/product'
import { toMoney } from '@svag/shared/src/money'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useForm } from '../../../../lib/form'
import { useT } from '../../../../lib/i18n'
import { trackPressPurchaseButton } from '../../../../lib/mixpanel'
import { trpc } from '../../../../lib/trpc'
import { FormItems } from '../../../form/FormItems'
import { Alert } from '../../../ui/Alert'
import { Button } from '../../../ui/Button'
import { closePurchaseProductModal, hideCurrenciesInPurchaseProductModal } from '../store'

export const PaymentButtonPaw = ({
  product,
  productIdentifier,
  className,
}: {
  product: TrpcRouterOutput['getProduct']['product']
  productIdentifier: ProductIdentifier
  className?: string
}) => {
  const { pathname } = useLocation()
  const { t } = useT('paymentButtonPaw')
  const createOrderPaw = trpc.createOrderPaw.useMutation()
  const trpcUtils = trpc.useContext()
  const [link, setLink] = useState<string | null>(null)
  const { buttonProps, alertProps, formik } = useForm({
    onSubmit: async () => {
      const { pawPaymentLink } = await createOrderPaw.mutateAsync({ productIdentifier, redirectRoute: pathname })
      window.open(pawPaymentLink, '_blank')
      hideCurrenciesInPurchaseProductModal()
      setLink(pawPaymentLink)
    },
    successMessage: false,
  })

  return (
    <div className={className}>
      {!link ? (
        <form onSubmit={formik.handleSubmit}>
          <FormItems>
            <Alert {...alertProps} />
            <Button
              {...buttonProps}
              onClick={() => {
                trackPressPurchaseButton({
                  product,
                  productIdentifier,
                  amount: product.amountRub,
                  currency: 'rub',
                })
                formik.handleSubmit()
              }}
              fullWidth
            >
              {t('payButtonLabel', { amount: toMoney({ amount: product.amountRub, currency: 'rub' }) })}
            </Button>
          </FormItems>
        </form>
      ) : (
        <FormItems>
          <Alert
            color="brown"
            buttons={
              <Button fullWidth color="brandAccent" href={link} target="_blank">
                {t('goToPaymentPageButtonLabel')}
              </Button>
            }
            message={t('redirectMessage')}
          />
          <Alert
            color="brown"
            buttons={
              <Button
                fullWidth
                color="brandAccent"
                onClick={() => {
                  closePurchaseProductModal()
                  setTimeout(() => {
                    void trpcUtils.invalidate()
                  }, 1)
                }}
              >
                {t('confirmationButtonLabel')}
              </Button>
            }
            message={t('confirmationMessage')}
          />
        </FormItems>
      )}
    </div>
  )
}
