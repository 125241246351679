import type { TrpcRouterOutput } from '@svag/backend/src/router'
import i18next from 'i18next'
import React from 'react'
import { useT } from '../../../lib/i18n'
import { Button, Buttons } from '../../ui/Button'
import { LabelValueInfo } from '../../ui/LabelValueInfo'
import css from './index.module.scss'

type PublicUserType =
  | TrpcRouterOutput['getConsultationInstancesForAttendee']['consultationInstances'][number]['attendee']
  | TrpcRouterOutput['getConsultationInstancesForConsultant']['consultationInstances'][number]['attendee']
  | TrpcRouterOutput['getConsultationInstance']['consultationInstance']['attendee']
type PublicUserInfoItemType = {
  key: string
  label: React.ReactNode
  value: React.ReactNode
}
type PublicUserInfoType = PublicUserInfoItemType[]

const getPublicUserInfo = ({ publicUser }: { publicUser: PublicUserType }) => {
  const info: PublicUserInfoType = []
  const nameLabel = i18next.t('publicUserPreview:name')
  info.push({
    key: 'name',
    label: nameLabel,
    value: publicUser.name,
  })
  const contactKeys = ['telegram', 'instagram', 'facebook', 'linkedin', 'vkontakte'] as const
  for (const key of contactKeys) {
    const rawValue = publicUser[key]
    if (rawValue) {
      info.push({
        key,
        label: {
          telegram: 'Telegram',
          instagram: 'Instagram',
          facebook: 'Facebook',
          linkedin: 'LinkedIn',
          vkontakte: 'VKontakte',
        }[key],
        value: (
          <a href={rawValue} target="_blank" rel="noreferrer">
            {rawValue}
          </a>
        ),
      })
    }
  }
  return info
}

export const PublicUserPreview = ({ publicUser, size }: { publicUser: PublicUserType; size: 's' | 'm' }) => {
  const { t } = useT('publicUserPreview')
  const info = getPublicUserInfo({ publicUser })
  return (
    <div className={css.preview}>
      <LabelValueInfo size={size} items={info} />
      {publicUser.contactLink && (
        <div className={css.contactButton}>
          <Buttons>
            <Button href={publicUser.contactLink} target="_blank" rel="noreferrer">
              {t('contactButton')}
            </Button>
          </Buttons>
        </div>
      )}
    </div>
  )
}
