import { ColumnFiller } from '../../../../../components/ui/ColumnFiller'
import { Icon } from '../../../../../components/ui/Icon'
import { useLang, useT } from '../../../../../lib/i18n'
import { trpc } from '../../../../../lib/trpc'
import { useWindowSize } from '../../../../../lib/useWindowSize'
import css from './index.module.scss'

type ItemType = {
  alias: string
  langs?: string[]
  subItems: Array<{
    iconName: any
    url: string
    titleKey: any
    langs?: string[]
  }>
  imageUrl?: string
  imageWidth?: number
  imageHeight?: number
  descKey?: any
}

const items: ItemType[] = [
  {
    alias: 'telegramChannel',
    langs: ['ru'],
    subItems: [
      {
        iconName: 'telegramRound',
        url: 'https://t.me/devPont',
        titleKey: 'devpontTelegram',
      },
    ],
    imageUrl: '/images/home/devpont-avatar.png',
    imageWidth: 975,
    imageHeight: 806,
    descKey: 'devpontTelegramDesc',
  },
  {
    alias: 'youtube',
    subItems: [
      {
        iconName: 'youtubeRound',
        url: 'https://www.youtube.com/channel/UCK0INO4V_rMwklc60hkwBxQ',
        titleKey: 'youtube',
      },
    ],
    imageUrl: '/images/home/devpont-banner.png',
    imageWidth: 1168,
    imageHeight: 600,
    descKey: 'youtubeDesc',
  },
  {
    alias: 'vkvideo',
    langs: ['ru'],
    subItems: [
      {
        iconName: 'vkontakteRound',
        url: 'https://vk.com/video/@devPont',
        titleKey: 'vkvideo',
      },
    ],
    descKey: 'vkvideoDesc',
  },
  {
    alias: 'rutube',
    langs: ['ru'],
    subItems: [
      {
        iconName: 'rutubeRound',
        url: 'https://rutube.ru/channel/39002929/',
        titleKey: 'rutube',
      },
    ],
    descKey: 'rutubeDesc',
  },
  {
    alias: 'habr',
    subItems: [
      {
        iconName: 'habrRound',
        url: 'https://habr.com/ru/users/iserdmi/publications/articles/',
        titleKey: 'habr',
      },
    ],
    descKey: 'habrDesc',
  },
  {
    alias: 'devto',
    subItems: [
      {
        iconName: 'devtoRound',
        url: 'https://dev.to/iserdmi',
        titleKey: 'devto',
      },
    ],
    descKey: 'devtoDesc',
  },
  {
    alias: 'telegram',
    subItems: [
      {
        iconName: 'telegramRound',
        url: 'https://t.me/iserdmi',
        titleKey: 'telegram',
      },
    ],
  },
  {
    alias: 'facebook',
    subItems: [
      {
        iconName: 'facebookRound',
        url: 'https://www.facebook.com/iserdmi',
        titleKey: 'facebook',
      },
    ],
  },
  {
    alias: 'instagram',
    subItems: [
      {
        iconName: 'instagramRound',
        url: 'https://www.instagram.com/iserdmi',
        titleKey: 'instagram',
      },
    ],
  },
  {
    alias: 'twitter',
    subItems: [
      {
        iconName: 'xRound',
        url: 'https://x.com/iserdmi',
        titleKey: 'twitter',
      },
    ],
  },
  {
    alias: 'linkedin',
    subItems: [
      {
        iconName: 'xRound',
        url: 'https://www.linkedin.com/in/iserdmi',
        titleKey: 'linkedin',
      },
    ],
  },
  {
    alias: 'vkontakte',
    langs: ['ru'],
    subItems: [
      {
        iconName: 'vkontakteRound',
        url: 'https://vk.com/iserdmi',
        titleKey: 'vkontakte',
      },
    ],
  },
]

const Item = ({ item, setItemRef }: { item: ItemType; setItemRef: (ref: any) => void }) => {
  const { t } = useT('homePageSocial')
  const { lang } = useLang()
  const si = item.subItems[0]
  if (item.langs && !item.langs.includes(lang)) {
    return null
  }
  return (
    <a ref={setItemRef} className={css.item} href={si.url} target="_blank" rel="noopener noreferrer">
      <div className={css.iconAndName}>
        <Icon className={css.icon} name={si.iconName} />
        <div className={css.name}>{t(si.titleKey)}</div>
      </div>
      {!!item.imageUrl && (
        <img src={item.imageUrl} width={item.imageWidth} height={item.imageHeight} alt="" className={css.preview} />
      )}
      {item.descKey && <div className={css.desc}>{t(item.descKey)}</div>}
    </a>
  )
}

export const HomePageSocial = () => {
  const { t } = useT('homePageSocial')
  const qr = trpc.getPublications.useQuery({})
  const { width: windowWidth } = useWindowSize()
  const columnsCount = windowWidth > 1100 ? 3 : windowWidth > 800 ? 2 : 1
  if (!qr.data) {
    return null
  }
  return (
    <div className={css.social} id="social">
      <div className={css.wrapper}>
        <h2 className={css.sectionTitle}>
          <span className={css.sectionTitleText}>{t('title')}</span>
        </h2>
        <div className={css.intro}>{t('desc')}</div>
        <div className={css.items}>
          <ColumnFiller
            items={items.map((p, i) => ({ ...p, key: i }))}
            columnsCount={columnsCount}
            columnRender={({ children, index, width }) => (
              <div key={index} style={{ width }} className={css.column}>
                {children}
              </div>
            )}
            itemRender={({ item, index, setItemRef }) => <Item key={item.alias} item={item} setItemRef={setItemRef} />}
          />
        </div>
      </div>
    </div>
  )
}
