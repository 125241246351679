import { z } from 'zod'

export const zEnvNonemptyTrimmed = z.string().trim().min(1)
export const zEnvNonemptyTrimmedRequiredOnNotLocal = zEnvNonemptyTrimmed.optional().refine(
  // eslint-disable-next-line node/no-process-env
  (val) => `${process.env.HOST_ENV}` === 'local' || !!val,
  'Required on not local host'
)
export const zEnvHost = z.enum(['local', 'production'])

export const zDirection = z.enum(['development', 'design', 'productology', 'other'])
export const zStringRequired = z.string({ required_error: 'Обязательное поле' }).min(1, 'Обязательное поле')
export const zStringOptional = z.string().optional()
// <div style="position: relative; padding-bottom: 64.92335437330928%; height: 0;"><iframe src="https://www.loom.com/embed/780881ac2cab45daafa797bd51e0a74a?sid=9261a8a4-5620-4291-9478-5d7346e31a5a" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>
export const zVideoLoomEmbedCode = z
  .string()
  .regex(
    /<div style="[^"]+"><iframe src="https:\/\/www\.loom\.com\/embed\/.*?".*?><\/iframe><\/div>/,
    'Неверный формат видео'
  )
export const zVideoYoutubeEmbedCode = z
  .string()
  .regex(/<iframe.*?src="https:\/\/www\.youtube\.com\/embed\/.*?".*?><\/iframe>/, 'Неверный формат видео')
export const zVideoEmbedCode = z.union([zVideoLoomEmbedCode, zVideoYoutubeEmbedCode])
export const zVideoEmbedCodeOptional = zVideoEmbedCode.optional()
export const zBooleanRequired = z.boolean()
export const zEmailRequired = zStringRequired.email('Неверный формат почты')
export const zEmailOptional = zStringRequired.optional()
export const zNickRequired = zStringRequired.regex(
  /^[a-z0-9-]+$/,
  'Nick may contain only lowercase letters, numbers and dashes'
)
export const zStringMin = (min: number) => zStringRequired.min(min, `Должно быть минимум ${min} символов'`)
export const zStringMaxRequired = (max: number) => zStringRequired.max(max, `Должно быть максимум ${max} символов'`)
export const zPasswordsMustBeTheSame =
  (passwordFieldName: string, passwordAgainFieldName: string) => (val: any, ctx: z.RefinementCtx) => {
    if (val[passwordFieldName] !== val[passwordAgainFieldName]) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Пароли должны совпадать',
        path: [passwordAgainFieldName],
      })
    }
  }
