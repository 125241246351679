import { EdDevPromoPageStack } from '../../EdDevPromoPage/EdDevPromoPageStack'
import css from './index.module.scss'

export const EdDevPromoJTMPageStack = () => {
  return (
    <div className={css.stackSection}>
      <div className={css.wrapper}>
        <div className={css.sectionHeader}>
          <h1 className={css.sectionTitle}>
            <span className={css.sectionTitleText}>Определитесь со стеком технологий</span>
          </h1>
        </div>
        <div className={css.mainText}>
          <p>
            Самый лучший стек технологий — это тот стек, который позволяет писать код быстрее, качественнее, более
            поддерживаемым, более масштабируемым, является полноценным, то есть закрывает все задачи фулстек-разработки,
            и приносит максимум радости и удовольствия от самого процесса разработки. Я пробовал много разных
            комбинаций, и вот тот стек, который по всем критериям подошёл мне больше всего.
          </p>
        </div>
        <EdDevPromoPageStack className={css.stack} size="m" />
        <div className={css.mainText}>
          <p>
            Если вы не знаете всех этих технологий, но хотите их изучить, пожалуйста, не изучайте их по одной и по
            очереди — так это не работает. Просто берите и используйте сразу все, создавая полноценный продукт. В
            фуллстек-разработке самое главное — это умение применять всё вместе, и вот когда вы уже будете уметь всё
            применить на практике и связать между собой, тогда можете погрузиться в глубину каждой отдельной технологии.
          </p>
          <p>
            При разработке первого проекта с нуля я настоятельно рекомендую использовать именно этот стек. Создавая
            последующие проекты, можете изменить в этом стеке технологий что угодно, но только тогда, когда сможете это
            сделать с пониманием дела.
          </p>
        </div>
      </div>
    </div>
  )
}
