import type { TrpcRouterOutput } from '@svag/backend/src/router'
import { getContactLink } from '@svag/shared/src/getContactLink'
import cn from 'classnames'
import { Button, Buttons } from '../../../../../../components/ui/Button'
import css from './index.module.scss'

export const EdDevPromoJTMPageHelp = ({
  backgroundColor = 'brandAccentLight',
  hideBottomPadding,
  title,
  desc,
  guide,
}: {
  hideBottomPadding?: boolean
  backgroundColor?: 'brandAccentLight' | 'systemPureWhite'
  title: string
  desc: string
  guide: TrpcRouterOutput['getStudentGuideFull']['guide']
}) => {
  return (
    <div
      className={cn({
        [css.helpSection]: true,
        [css[`backgroundColor-${backgroundColor}`]]: true,
        [css.hideBottomPadding]: hideBottomPadding,
      })}
    >
      <div className={css.wrapper}>
        <h2 className={css.title}>{title}</h2>
        <div className={css.mainText}>
          <p>{desc}</p>
        </div>
        <Buttons className={css.buttons}>
          <Button color="dark" target="_blank" href={getContactLink(guide.author)}>
            Написать в телеграм
          </Button>
        </Buttons>
      </div>
    </div>
  )
}
