import React from 'react'
import { openSignUpModal } from '../../../../../../components/auth/Auth'
import { Button } from '../../../../../../components/ui/Button'
import { RichText } from '../../../../../../components/ui/RichText'
import { useMe } from '../../../../../../lib/ctx'
import css from './index.module.scss'

const freeCourses = [
  {
    title: 'JavaScript',
    items: [
      {
        title: 'Современный учебник JavaScript',
        url: 'https://learn.javascript.ru/',
        desc: '',
      },
      {
        title: 'The Rolling Scopes School',
        url: 'https://docs.rs.school/#/js-fe-course',
        desc: '',
      },
      {
        title: 'Погружение в JavaScript: для начинающих',
        url: 'https://stepik.org/course/180784/promo',
        desc: '',
      },
      {
        title: 'JavaScript для начинающих',
        url: 'https://stepik.org/course/2223/promo',
        desc: '',
      },
      {
        title: 'Руководство по JavaScript',
        url: 'https://metanit.com/web/javascript/',
        desc: '',
      },
      {
        title: 'Учебник JavaScript для новичков',
        url: 'https://code.mu/ru/javascript/book/prime/',
        desc: '',
      },
      {
        title: 'Выразительный JavaScript',
        url: 'https://karmazzin.gitbook.io/eloquentjavascript_ru',
        desc: '',
      },
      {
        title: '30 дней яваскрипта',
        url: 'https://github.com/Asabeneh/30-Days-Of-JavaScript/blob/master/RU/README.md',
        desc: '',
      },
      {
        title: 'Видеообучение по яваскрипт',
        url: 'https://www.youtube.com/@AsForJS',
        desc: '',
      },
      {
        title: 'Видео JavaScript c Нуля',
        url: 'https://www.youtube.com/watch?v=fcMcf_4PjfI&t=1s',
        desc: '',
      },
      {
        title: 'Ещё видео по яваскрипт',
        url: 'https://www.youtube.com/watch?v=maPRR_jjyOE',
        desc: '',
      },
      {
        title: 'Учебник по JavaScript',
        url: 'https://coderlessons.com/tutorials/veb-razrabotka/uchit-javascript/uchebnik-po-javascript',
        desc: '',
      },
      {
        title: 'Основы JS от Хекслет',
        url: 'https://ru.hexlet.io/courses/js-basics',
        desc: '',
      },
      {
        title: 'Уроки JavaScript',
        url: 'https://itproger.com/course/javascript',
        desc: '',
      },
    ],
  },
  {
    title: 'TypeScript',
    items: [
      {
        title: 'Курс TypeScript: бесплатное обучение для разработчиков',
        url: 'https://code-basics.com/ru/languages/typescript',
        desc: '',
      },
      {
        title: 'Введение в TypeScript',
        url: 'https://stepik.org/course/118563/promo',
        desc: '',
      },
      {
        title: 'Начало работы с TypeScript',
        url: 'https://learn.microsoft.com/ru-ru/training/modules/typescript-get-started/',
        desc: '',
      },
      {
        title: 'Создание приложений JavaScript с помощью TypeScript',
        url: 'https://learn.microsoft.com/ru-ru/training/paths/build-javascript-applications-typescript/',
        desc: '',
      },
      {
        title: 'Руководство по TypeScript',
        url: 'https://metanit.com/web/typescript/',
        desc: '',
      },
      {
        title: 'Онлайн курс TypeScript',
        url: 'https://academiait.ru/course/typescript/',
        desc: '',
      },
      {
        title: 'Видео учебник TypeScript',
        url: 'https://www.youtube.com/playlist?list=PLNkWIWHIRwMEm1FgiLjHqSky27x5rXvQa',
        desc: '',
      },
    ],
  },
  {
    title: 'React.js',
    items: [
      {
        title: 'Курс Reactjs: Начало',
        url: 'https://ru.hexlet.io/courses/reactjs',
        desc: '',
      },
      {
        title: 'React I',
        url: 'https://loftschool.com/modules/15-react-i/',
        desc: '',
      },
      {
        title: 'Онлайн курс: React',
        url: 'https://academiait.ru/course/react/',
        desc: '',
      },
      {
        title: 'Видео курс по реакт',
        url: 'https://www.youtube.com/watch?v=YBwUfpDIMgw&list=PLNkWIWHIRwMHC8qUfTzfxPuGrz4o9tmeE',
        desc: '',
      },
      {
        title: 'Ещё видео курс по реакт',
        url: 'https://www.youtube.com/watch?v=mMk_lOzo8c0&list=PLfnFOImnyWRWhDwoXXqkn2iIRHdA2iJjX',
        desc: '',
      },
      {
        title: 'И ещё видео курс по реакт',
        url: 'https://www.youtube.com/playlist?list=PLcvhF2Wqh7DNVy1OCUpG3i5lyxyBWhGZ8',
        desc: '',
      },
      {
        title: 'Учебник React для новичков',
        url: 'https://code.mu/ru/javascript/framework/react/book/prime/',
        desc: '',
      },
    ],
  },
  {
    title: 'Вёрстка',
    items: [
      {
        title: 'Учебник с заданиями по вёрстке',
        url: 'https://katalog-kursov.ru/lessons/html-css/',
        desc: '',
      },
      {
        title: 'Основы HTML и CSS',
        url: 'https://stepik.org/course/52164/promo#toc',
        desc: '',
      },
      {
        title: 'Уроки HTML5 для начинающих',
        url: 'https://itproger.com/course/html',
        desc: '',
      },
      {
        title: 'Вёрстка сайта',
        url: 'https://itproger.com/course/website',
        desc: '',
      },
      {
        title: 'Веб-разработка для начинающих (HTML и CSS)',
        url: 'https://stepik.org/course/38218/promo',
        desc: '',
      },
      {
        title: 'CSS уроки',
        url: 'https://itproger.com/course/css',
        desc: '',
      },
      {
        title: 'Курс Основы HTML, CSS и веб-дизайна на Хекслете',
        url: 'https://ru.hexlet.io/courses/html',
        desc: '',
      },
    ],
  },
  {
    title: 'Node.js',
    items: [
      {
        title: 'Уроки Node JS для начинающих',
        url: 'https://www.youtube.com/playlist?list=PLDyJYA6aTY1loAdIkiCun_Cz5nnWEF1WW',
        desc: '',
      },
      {
        title: 'Курс NodeJS',
        url: 'https://www.youtube.com/playlist?list=PLM7wFzahDYnHYn81-oqavYIp6vaEd5gdH',
        desc: '',
      },
      {
        title: 'Уроки по Node.js',
        url: 'https://www.youtube.com/playlist?list=PLuY6eeDuleIOmCbpBUPDIdkNqn9XSnhsG',
        desc: '',
      },
    ],
  },
  {
    title: 'Базы данных',
    items: [
      {
        title: 'Руководство по PostgreSQL',
        url: 'https://metanit.com/sql/postgresql/',
        desc: '',
      },
      {
        title: 'Уроки PostgreSQL для начинающих',
        url: 'https://www.youtube.com/playlist?list=PLPPIc-4tm3YQsdhSV1qzAgDKTuMUNnPmp',
        desc: '',
      },
      {
        title: 'Базы данных в целом',
        url: 'https://stepik.org/course/2614/promo',
        desc: '',
      },
      {
        title: 'Основы работы с базой данных PostgreSQL',
        url: 'https://www.youtube.com/playlist?list=PL8X2nqRlWfaZRM65hqNbm-HYUAK9J7uOi',
        desc: '',
      },
    ],
  },
]

export const EdDevPromoNewbiePageFree = () => {
  const me = useMe()
  const openSignUpModalHere = () => {
    openSignUpModal({
      alertMessage:
        'Чтобы получить доступ к ссылкам на бесплатные учебники, пожалуйста, зарегистрируйтесь. Подборка также будет отправлена на вашу почту.',
      interests: ['iserdmiDevWeb', 'iserdmiDevWebNewbie'],
      onSuccess: () => {},
    })
  }
  return (
    <div className={css.freeSection}>
      <div className={css.wrapper}>
        <div className={css.sectionHeader}>
          <h1 className={css.sectionTitle}>
            <span className={css.sectionTitleText}>
              Бесплатные учебники по JavaScript, TypeScript, React.js, вёрстке, Node.js, базам данных
            </span>
          </h1>
        </div>
        <RichText className={css.mainText}>
          <p>
            Крайне не рекомендую платить за учебники по основам программирования, покрайней мере, пока не попробуете
            бесплатные. Есть огромное количество очень хороших бесплатных курсов и учебников. Вот вам подборка.
          </p>
          {!me && (
            <p className="fullWidth">
              <Button
                size="l"
                // fullWidth
                className={css.button}
                color="brandAccent"
                onClick={() => {
                  openSignUpModalHere()
                }}
              >
                Получить подборку на почту бесплатно
              </Button>
            </p>
          )}
          {freeCourses.map((section, i) => (
            <React.Fragment key={i}>
              <h2>{section.title}</h2>
              <ul>
                {section.items.map((item, j) => (
                  <li key={j}>
                    <a
                      href={me ? item.url : '#'}
                      onClick={
                        me
                          ? undefined
                          : (e) => {
                              e.preventDefault()
                              openSignUpModalHere()
                            }
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.title}
                    </a>
                    {item.desc ? ` — ${item.desc}` : ''}
                  </li>
                ))}
              </ul>
            </React.Fragment>
          ))}
          <p>
            Сохраните себе подборку этих учебников и начнайте учиться, создавая свой первый реальный проект с нуля,
            обращаясь к этим учебникам за всеми необходимыми знаниями.
          </p>
          {!me && (
            <p className="fullWidth">
              <Button
                size="l"
                // fullWidth
                className={css.button}
                color="brandAccent"
                onClick={() => {
                  openSignUpModalHere()
                }}
              >
                Получить подборку на почту бесплатно
              </Button>
            </p>
          )}
          <p>
            <b>
              Помните, что эти учебники — всего лишь теория, знание которой не определяет вас, как хорошего
              программиста. Хороший программист умеет создавать и создаёт полноценные продукты. Учитесь сразу
              на хорошего программиста. Учитесь через создание реальных проектов.
            </b>
          </p>
        </RichText>
      </div>
    </div>
  )
}
