import cn from 'classnames'
import { Link } from 'react-router-dom'
import { useAllT } from '../../../lib/i18n'
import { getHomeRoute, getPolicyRoute, getTermsRoute } from '../../../lib/routes'
import css from './index.module.scss'

export const Footer = ({
  className,
  disableFooterTopPadding,
  footerBackgroundColor = 'systemPureWhite',
  disableFooterPositioning,
}: {
  className?: string
  disableFooterTopPadding?: boolean
  footerBackgroundColor?: 'systemPureWhite' | 'brandAccent'
  disableFooterPositioning?: boolean
}) => {
  const { t } = useAllT()
  return (
    <div
      className={cn({
        [css.footer]: true,
        [css.disableFooterTopPadding]: disableFooterTopPadding,
        [css[`color-${footerBackgroundColor}`]]: true,
        [className || '']: !!className,
      })}
    >
      <div
        className={cn({
          [css.wrapper]: true,
          [css.disableFooterPositioning]: disableFooterPositioning,
        })}
      >
        <div className={css.copyright}>
          © {new Date().getFullYear()}{' '}
          <Link className={css.link} to={getHomeRoute()}>
            {t('general.svag')}
          </Link>
        </div>
        <ul className={css.links}>
          <li className={css.linkOuter}>
            <Link className={css.link} to={getTermsRoute()}>
              {t('generalLayout.terms')}
            </Link>
          </li>
          <li className={css.linkOuter}>
            <Link className={css.link} to={getPolicyRoute()}>
              {t('generalLayout.policy')}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}
