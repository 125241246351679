import cn from 'classnames'
import { useCurrency } from '../../../lib/currency'
import css from './index.module.scss'

export const CurrencySwitchPrice = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  const { setCurrency, currency } = useCurrency()
  return (
    <span
      className={cn(css.currencySwitchPrice, className)}
      onClick={() => {
        setCurrency(currency === 'rub' ? 'usd' : 'rub')
      }}
    >
      {children}
    </span>
  )
}
