import type { TrpcRouterOutput } from '@svag/backend/src/router'
import { z } from 'zod'
import { useForm } from '../form'
import { trackMarkGuideLessonAsCompleted } from '../mixpanel'
import { trpc } from '../trpc'

export const useChangeStudentGuideLessonCompletenes = ({
  lesson,
}: {
  lesson:
    | TrpcRouterOutput['getStudentGuideLesson']['lesson']
    | TrpcRouterOutput['getStudentGuideFull']['lessons'][number]
}) => {
  const changeStudentGuideLessonCompletenes = trpc.changeStudentGuideLessonCompletenes.useMutation()
  const trpcUtils = trpc.useContext()
  const form = useForm({
    successMessagePolicy: 'toast',
    submitErrorMessagePolicy: 'toast',
    validationErrorMessagePolicy: 'toast',
    validationSchema: z.object({
      isFinished: z.boolean(),
    }),
    initialValues: {
      isFinished: true,
    },
    onSubmit: async ({ isFinished }) => {
      void trpcUtils.getStudentGuideFull.cancel({ guideSlug: lesson.guideSlug })
      void trpcUtils.getStudentGuideLesson.cancel({
        guideSlug: lesson.guideSlug,
        lessonSlug: lesson.slug,
      })
      trpcUtils.getStudentGuideFull.setData(
        {
          guideSlug: lesson.guideSlug,
        },
        (oldData) => {
          if (!oldData) {
            return oldData
          }
          return {
            ...oldData,
            lessons: oldData.lessons.map((l) => {
              if (l.id === lesson.id) {
                return { ...l, isFinished }
              }
              return l
            }),
          }
        }
      )
      trpcUtils.getStudentGuideLesson.setData(
        {
          guideSlug: lesson.guideSlug,
          lessonSlug: lesson.slug,
        },
        (oldData) => {
          if (!oldData) {
            return oldData
          }
          return { ...oldData, isFinished }
        }
      )

      await changeStudentGuideLessonCompletenes.mutateAsync({
        lessonId: lesson.id,
        isFinished,
      })
      if (isFinished) {
        trackMarkGuideLessonAsCompleted({
          guideLesson: lesson,
        })
      }

      void trpcUtils.getStudentGuideFull.cancel({ guideSlug: lesson.guideSlug })
      void trpcUtils.getStudentGuideLesson.cancel({
        guideSlug: lesson.guideSlug,
        lessonSlug: lesson.slug,
      })
      void trpcUtils.getStudentGuideFull.refetch({
        guideSlug: lesson.guideSlug,
      })
      void trpcUtils.getStudentGuideLesson.refetch({
        guideSlug: lesson.guideSlug,
        lessonSlug: lesson.slug,
      })
    },
  })

  return {
    changeStudentGuideLessonCompletenes: async ({ isFinished }: { isFinished: boolean }) => {
      await form.formik.setValues({ isFinished })
      await form.formik.submitForm()
    },
  }
}
