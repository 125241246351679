import type { TrpcRouterOutput } from '@svag/backend/src/router'
import { useNavigate } from 'react-router-dom'
import { openSignUpModal } from '../../../../components/auth/Auth'
import { Button, Buttons } from '../../../../components/ui/Button'
import { Card, type CardProps } from '../../../../components/ui/Card'
import { useMe } from '../../../../lib/ctx'
import { useChangeStudentGuideLessonCompletenes } from '../../../../lib/education/useChangeStudentGuideLessonCompletenes'
import { useT } from '../../../../lib/i18n'
import { getGuideLessonRoute } from '../../../../lib/routes'

export const NextGuideLessonCard = ({
  activeLesson,
  setRef,
}: {
  activeLesson: TrpcRouterOutput['getStudentGuideLesson']['lesson']
} & Pick<CardProps, 'setRef'>) => {
  const { t } = useT('nextGuideLessonCard')
  const me = useMe()
  const navigate = useNavigate()
  const { changeStudentGuideLessonCompletenes } = useChangeStudentGuideLessonCompletenes({
    lesson: activeLesson,
  })
  if (!activeLesson.nextLesson) {
    return null
  }
  const nextLesson = activeLesson.nextLesson
  return (
    <Card
      hintText={t('hintText')}
      hintIconName="guide"
      title={nextLesson.title}
      content={nextLesson.desc}
      setRef={setRef}
      buttons={
        activeLesson.isFinished ? (
          <Buttons>
            <Button
              color="brandAccentLight"
              to={getGuideLessonRoute({
                guideSlug: nextLesson.guideSlug,
                lessonSlug: nextLesson.slug,
              })}
            >
              {t('goToButton')}
            </Button>
          </Buttons>
        ) : (
          <Buttons compressed stretch>
            <Button
              color="brandAccentLight"
              onClick={() => {
                if (!me) {
                  openSignUpModal({
                    alertMessage: t('signUpAlert'),
                  })
                } else {
                  void changeStudentGuideLessonCompletenes({
                    isFinished: true,
                  })
                  navigate(getGuideLessonRoute({ guideSlug: nextLesson.guideSlug, lessonSlug: nextLesson.slug }))
                }
              }}
            >
              {t('markAndGoToButton')}
            </Button>
            <Button
              color="brandAccentLight"
              to={getGuideLessonRoute({
                guideSlug: nextLesson.guideSlug,
                lessonSlug: nextLesson.slug,
              })}
            >
              {t('justGoToButton')}
            </Button>
          </Buttons>
        )
      }
    />
  )
}
