import { atom } from 'nanostores'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
  getDashboardRoute,
  getResetPasswordRoute,
  getRestorePasswordRoute,
  getSignInRoute,
  getSignOutRoute,
  getSignUpRoute,
} from '../../../lib/routes'

export const lastVisistedNotAuthRouteStore = atom<string>(getDashboardRoute())

export const NotAuthRouteTracker = () => {
  const { pathname } = useLocation()
  useEffect(() => {
    const authRoutesRegexes = [
      getSignUpRoute(),
      getSignInRoute(),
      getSignOutRoute(),
      getResetPasswordRoute({ resetPasswordToken: '[^/]+' }),
      getRestorePasswordRoute(),
      getSignOutRoute(),
    ]
    // Check if pathname not equals to regex in authRoutesRegexes
    const isAuthRoute = authRoutesRegexes.some((regex) => new RegExp('^' + regex + '$').test(pathname))

    // const isAuthRoute = authRoutes.includes(pathname)
    if (!isAuthRoute) {
      lastVisistedNotAuthRouteStore.set(pathname)
    }
  }, [pathname])

  return null
}
