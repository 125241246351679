import toast, { type ToastOptions } from 'react-hot-toast'

export type NotifyProps = { message: string; type: 'success' | 'error' | 'neutral' } & ToastOptions
export const notify = ({ message, type, ...restProps }: NotifyProps) => {
  const props = {
    position: 'top-center' as const,
    ...restProps,
  }
  if (type === 'neutral') {
    return toast(message, props)
  } else {
    return toast[type](message, props)
  }
}
