import cn from 'classnames'
import { type FormikProps } from 'formik'
import _ from 'lodash'
import React from 'react'
import { Icon } from '../../ui/Icon'
import css from './index.module.scss'

export const CheckboxUI: React.FC<{
  label?: string
  name?: string
  checked: boolean
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => void
  onChange?: (val: any) => void
  onBlur?: () => void
  bold?: boolean
}> = ({ label, name, disabled, onClick, onChange, checked, onBlur, bold, ...restProps }) => {
  const checkedProps = onChange ? { checked } : { checked }
  return (
    <label
      className={cn({
        [css.checkbox]: true,
        [css.disabled]: disabled,
        [css.checked]: checked,
        [css.bold]: !!bold,
      })}
      onClick={onClick}
    >
      <input
        className={css.input}
        type="checkbox"
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        {...checkedProps}
        {...restProps}
      />
      <span className={css.box}>
        <Icon name="checkboxTick" className={css.tick} />
      </span>
      {!!label && <span className={css.label}>{label}</span>}
    </label>
  )
}

export const Checkbox: React.FC<
  {
    label: string
    formik: FormikProps<any>
    name: string
    onChange?: (val: any) => void
    bold?: boolean
  } & ({ type: 'boolean'; value?: undefined } | { type: 'array'; value: string })
> = ({ label, formik, name, onChange, value, bold, type = 'boolean', ...restProps }) => {
  const formikValue = _.get(formik.values, name)
  return (
    <label
      className={cn({
        [css.checkbox]: true,
        [css.bold]: !!bold,
      })}
    >
      <input
        type="checkbox"
        name={name}
        value={type === 'boolean' ? undefined : value}
        checked={type === 'boolean' ? formikValue : formikValue.includes(value)}
        onChange={() => {
          if (type === 'boolean') {
            onChange?.(!formikValue)
            void formik.setFieldValue(name, !formikValue)
          } else {
            const newValue = formikValue.includes(value)
              ? formikValue.filter((v: any) => v !== value)
              : _.uniq([...formikValue, value])
            onChange?.(newValue)
            void formik.setFieldValue(name, newValue)
          }
        }}
        onBlur={formik.handleBlur}
        {...restProps}
      />{' '}
      {label}
    </label>
  )
}

export const Checkboxes: React.FC<{
  label: string
  formik: FormikProps<any>
  name: string
  hint?: string
  children: React.ReactNode
  direction?: 'column' | 'row'
}> = ({ label, formik, name, hint, children, direction = 'column', ...restProps }) => {
  const error = _.get(formik.errors, name)
  const touched = _.get(formik.touched, name)
  return (
    <div
      className={cn({
        [css.checkboxes]: true,
        [css[`direction-${direction}`]]: !!direction,
      })}
      {...restProps}
    >
      {!!label && <label className={css.label}>{label}</label>}
      <div className={css.items}>{children}</div>
      {!!error && touched && <p className={css.error}>{error as string}</p>}
      {!!hint && <p className={css.hint}>{hint}</p>}
    </div>
  )
}
