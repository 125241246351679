import { useLocation } from 'react-router-dom'
import { isPathnamesEqualExceptLang, useT } from '../../../lib/i18n'
import { getDashboardRoute, getHomeRoute } from '../../../lib/routes'
import { GeneralLayout, GeneralLayoutNavLink } from '../GeneralLayout'

export const IntroLayout = ({ children }: { children?: React.ReactNode }) => {
  const { pathname } = useLocation()
  const { t } = useT('introLayout') // Added useT hook with 'introLayout' namespace

  return (
    <GeneralLayout
      fullWidthContent
      logoLinkPolicy={isPathnamesEqualExceptLang(pathname, getHomeRoute()) ? 'scrollToTop' : 'navigateToHome'}
      showSidebar={false}
      generalNavigationChildren={
        <>
          {/* <GeneralLayoutNavLink to={getClientsPromoRoute()}>{t('nav.clients')}</GeneralLayoutNavLink> */}
          {/* <GeneralLayoutNavLink to={getSpecialistsPromoRoute()}>{t('nav.specialists')}</GeneralLayoutNavLink> */}
          <GeneralLayoutNavLink to={getDashboardRoute()}>{t('nav.main')}</GeneralLayoutNavLink>
          <GeneralLayoutNavLink to={getDashboardRoute()}>{t('nav.dashboard')}</GeneralLayoutNavLink>
        </>
      }
    >
      {children}
    </GeneralLayout>
  )
}
