import { EdCardsWithFilter } from '../../../../../components/education/cards/EdCard'
import { AnimatedStars, IconStar } from '../../../../../components/ui/AnimatedStars'
import { useT } from '../../../../../lib/i18n'
import { trpc } from '../../../../../lib/trpc'
import css from './index.module.scss'

export const SpecialistsPageEd = () => {
  const { t } = useT('specialistsPageEd')
  const qr = trpc.getEdItems.useQuery()
  if (!qr.data) {
    return null
  }
  return (
    <div className={css.ed} id="ed">
      <div className={css.wrapper}>
        <h2 className={css.sectionTitle}>
          <span className={css.sectionTitleText}>{t('title')}</span>
          <AnimatedStars className={css.animatedStars} visibleClassName={css.visible}>
            <IconStar />
            <IconStar />
            <IconStar />
          </AnimatedStars>
        </h2>
        <div className={css.items}>
          <EdCardsWithFilter
            edItems={qr.data.edItems}
            getColumnsCount={({ items, windowWidth }) =>
              items.length < 2 ? 1 : items.length < 3 ? 2 : windowWidth > 1100 ? 3 : windowWidth > 900 ? 2 : 1
            }
          />
        </div>
      </div>
    </div>
  )
}
