import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { ReactComponent as CrossModal } from '../../../assets/icons/crossModal.svg'
import { useWindowSize } from '../../../lib/useWindowSize'
import css from './index.module.scss'

ReactModal.defaultStyles = {}
ReactModal.setAppElement('#root')

export const Modal: React.FC<{
  onRequestClose: Function
  children: React.ReactNode
  maxWidth?: number
  isOpen: boolean
  title?: string
  desc?: string
}> = ({ onRequestClose, children, maxWidth = 480, isOpen, title, desc, ...restProps }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  const { width: windowWidth } = useWindowSize()
  const [marginBottom, setMarginBottom] = useState(windowWidth > 600 ? '0' : '-100px')
  const [marginRight, setMarginRight] = useState(windowWidth > 600 ? '-100px' : '0')
  const [opacity, setOpacity] = useState('0')
  const [backgroundColor, setBackgroundColor] = useState('rgba(0, 0, 0, 0)')

  useEffect(() => {
    if (!isOpen) {
      setMarginBottom(windowWidth > 600 ? '0' : '-100px')
      setMarginRight(windowWidth > 600 ? '-100px' : '0')
    }
  }, [windowWidth, isOpen])

  const niceOnRequestClose = () => {
    setOpacity('0')
    setMarginBottom(windowWidth > 600 ? '0' : '-100px')
    setMarginRight(windowWidth > 600 ? '-100px' : '0')
    setBackgroundColor('rgba(0, 0, 0, 0)')
    setTimeout(() => {
      onRequestClose()
    }, 210)
  }
  return (
    <ReactModal
      {...restProps}
      isOpen={isOpen}
      style={{ content: { marginBottom, marginRight, opacity, maxWidth }, overlay: { backgroundColor } }}
      onRequestClose={niceOnRequestClose}
      shouldFocusAfterRender={false}
      onAfterOpen={() => {
        setOpacity('1')
        setMarginBottom('0')
        setMarginRight('0')
        setBackgroundColor('rgba(0, 0, 0, 0.6)')
      }}
    >
      <button className={css.cross} onClick={niceOnRequestClose}>
        <CrossModal className={css.icon} />
      </button>
      <div className={css.content}>
        {title && <h1 className={css.title}>{title}</h1>}
        {desc && <p className={css.desc}>{desc}</p>}
        <div className={css.inner}>{children}</div>
      </div>
    </ReactModal>
  )
}
