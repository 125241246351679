import css from './index.module.scss'

export const EdDevPromoJTMPageResume = () => {
  return (
    <div className={css.resumeSection}>
      <div className={css.wrapper}>
        <div className={css.sectionHeader}>
          <h1 className={css.sectionTitle}>
            <span className={css.sectionTitleText}>Ваше резюме по окончании обучения</span>
          </h1>
        </div>
        <div className={css.mainText}>
          Это уникальное резюме, которое однозначно определяет, что вы не джун, а минимум мидл. У других такого резюме
          нет. Я с подобным резюме устраивался на работу дважды с конверсией 100%.
        </div>
        <div className={css.resume}>
          <h3>Обо мне</h3>
          <p>
            Я фулстек тайпскрипт разработчик, мне {'{{'}Х{'}}'} лет, живу в {'{{'}Y{'}}'}, работаю удалённо. Умею
            создавать и создавал продукты с нуля. Хочу стать частью сильной команды, укрепить и развить навыки
            построения сильной архитектуры и написания качественного кода.
          </p>
          <p>
            На данный момент мой избранный стек технологий: React, TypeScript, Vite, Node.js, pnpm, Express, tRPC,
            PostgreSQL, Prisma, Formik, Zod, Jest, SCSS, Passport, React Router, Cloudinary, AWS S3, MJML, Winston,
            Prettier, ESLint, Stylelint, Husky.
          </p>
          <p>
            На заре своей карьеры также имел опыт с: {'{{'}технологии с других крусов и прежних мест работы{'}}'}
          </p>
          <p>
            Не силён в девопсе, но базовый проект с помощью этих технологий задеполить и поддерживать смогу: Cloudflare,
            Heroku, DataDog, Sentry, Docker. Буду рад развить навыки девопс на новом месте работы.
          </p>
          <h3>История становления, опыт, проекты</h3>
          <p>
            {'{{'}рассказываете как начинали вообще{'}}'}
          </p>
          <p>
            {'{{'}рассказываете что делали не предыдущий местах работы{'}}'}
          </p>
          <p>
            {'{{'}рассказываете в каждом новом абзаце про проекты которые делали с нуля и что там было интересного{'}}'}
          </p>
          <h3>Ожидания от работы</h3>
          <p>
            {'{{'}загадайте работу мечты и опишите её здесь{'}}'}
          </p>
        </div>
      </div>
    </div>
  )
}
