import { Helmet } from 'react-helmet-async'
import { useT } from '../../../lib/i18n'
import { Auth } from '../../auth/Auth'

export const AuthorizedOnlyPageComponent = (props: { title?: string; message?: string }) => {
  const { t } = useT('authorizedOnlyPageComponent')
  const title = props.title || t('title')
  const message = props.message || t('message')
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Auth alertMessage={message} policy="component" initialChoice="signUp" />
    </>
  )
}
