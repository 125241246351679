import { AnimatedStars, IconStar } from '../../../../../components/ui/AnimatedStars'
import { Button } from '../../../../../components/ui/Button'
import { useT } from '../../../../../lib/i18n'
import css from './index.module.scss'

export const ClientsPageContacts = () => {
  const { t } = useT('clientsPageContacts')
  return (
    <div className={css.contacts} id="contacts">
      <div className={css.wrapper}>
        <h2 className={css.sectionTitle}>
          <span className={css.sectionTitleText}>{t('title')}</span>
          <AnimatedStars className={css.animatedStars} visibleClassName={css.visible}>
            <IconStar />
            <IconStar />
            <IconStar />
          </AnimatedStars>
        </h2>
        <div className={css.content}>
          <div className={css.photoPlace}>
            <img src="/images/team/s4.png" alt="" className={css.photo} />
          </div>
          <div className={css.info}>
            <p className={css.paragraph} dangerouslySetInnerHTML={{ __html: t('text') }} />
            <Button
              href="https://cal.com/svag.group/30min"
              target="_blank"
              rel="noreferrer"
              className={css.button}
              color="brandAccentLight"
              icon="hey"
              eventElementGroup="homeContactButton"
              eventElementId="homeContactsContactButton"
            >
              {t('buttonText')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
