import { GeneralLayout, GeneralLayoutNavLink } from '../../../../components/layouts/GeneralLayout'
import { useT } from '../../../../lib/i18n'
import { withPageWrapper } from '../../../../lib/pageWrapper'
import { getDashboardRoute } from '../../../../lib/routes'
import { ClientsPageContacts } from '../ClientsPromoPage/ClientsPageContacts'
import { ClientsPagePlan } from '../ClientsPromoPage/ClientsPagePlan'
import { ClientsPageProjects } from '../ClientsPromoPage/ClientsPageProjects'
import { ClientsPageTriggers } from '../ClientsPromoPage/ClientsPageTriggers'
import { SpecialistsPageEd } from '../SpecialistsPromoPage/SpecialistsPageEd'
import { HomePageIntro } from './HomePageIntro'
import { HomePagePublications } from './HomePagePublications'
import { HomePageSocial } from './HomePageSocial'
import { HomePageTeam } from './HomePageTeam'

export const HomePromoPage = withPageWrapper({
  langs: ['ru', 'en'],
  showLoaderOnFetching: false,
})(() => {
  const { t } = useT('homePageLayout')
  return (
    <GeneralLayout
      fullWidthContent
      logoLinkPolicy={'scrollToTop'}
      showSidebar={false}
      generalNavigationChildren={
        <>
          {/* <GeneralLayoutNavLink to={getClientsPromoRoute()}>{t('nav.clients')}</GeneralLayoutNavLink> */}
          {/* <GeneralLayoutNavLink to={getSpecialistsPromoRoute()}>{t('nav.specialists')}</GeneralLayoutNavLink> */}
          <GeneralLayoutNavLink to={'#plan'}>{t('nav.plan')}</GeneralLayoutNavLink>
          <GeneralLayoutNavLink to={'#team'}>{t('nav.team')}</GeneralLayoutNavLink>
          <GeneralLayoutNavLink to={'#projects'}>{t('nav.projects')}</GeneralLayoutNavLink>
          <GeneralLayoutNavLink to={'#ed'}>{t('nav.ed')}</GeneralLayoutNavLink>
          <GeneralLayoutNavLink to={'#publications'}>{t('nav.publications')}</GeneralLayoutNavLink>
          <GeneralLayoutNavLink to={'#social'}>{t('nav.contacts')}</GeneralLayoutNavLink>
          <GeneralLayoutNavLink to={getDashboardRoute()}>{t('nav.dashboard')}</GeneralLayoutNavLink>
        </>
      }
    >
      <HomePageIntro />
      <ClientsPageTriggers />
      <ClientsPagePlan />
      <HomePageTeam />
      <ClientsPageProjects />
      <SpecialistsPageEd />
      <HomePagePublications />
      <HomePageSocial />
      <ClientsPageContacts />
    </GeneralLayout>
  )
})
