import cn from 'classnames'
import { type FormikProps } from 'formik'
import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import { useState } from 'react'
import ReactSelect from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { useT } from '../../../lib/i18n'
import css from './index.module.scss'

export const Select = ({
  label,
  floatingPlaceholder,
  formik,
  name,
  hint,
  onChange,
  fullWidth,
  maxWidth = 400,
  disabled,
  options,
  isMulti = false,
  placeholder = '',
  creatable = false,
  clearable = true,
  ...restProps
}: {
  label?: string
  floatingPlaceholder?: string
  formik: FormikProps<any>
  name: string
  hint?: string
  onChange?: (value: any) => void
  fullWidth?: boolean
  maxWidth?: number
  disabled?: boolean
  options: Array<{ value: string; label: string }>
  isMulti?: boolean
  placeholder?: string
  creatable?: boolean
  clearable?: boolean
}) => {
  const { t } = useT('select')
  const error = get(formik.errors, name) as string | undefined
  const value = get(formik.values, name) as string | string[] | undefined
  const touched = get(formik.touched, name) as boolean
  const invalid = touched && !!error
  const disabledHere = disabled || formik.isSubmitting

  const selectedOption = isMulti
    ? filter(options, (option) => value?.includes?.(option.value))
    : find(options, (option) => option.value === value)
  const [focused, setFocused] = useState(false)
  const selectProps = {
    id: name,
    value: selectedOption,
    onChange: (selectedOption: any) => {
      if (isArray(selectedOption)) {
        void formik.setFieldValue(
          name,
          selectedOption.map((so) => so.value),
          true
        )
      } else {
        void formik.setFieldValue(name, selectedOption?.value || '', true)
      }
      setTimeout(() => {
        formik.setFieldTouched(name)
      }, 1)
      onChange?.(selectedOption)
    },
    onBlur: () => {
      formik.setFieldTouched(name)
      setFocused(false)
    },
    onFocus: () => {
      setFocused(true)
    },
    isMulti,
    isClearable: clearable,
    name,
    className: css.input,
    isDisabled: disabledHere,
    styles: {
      option: (provided: any, state: any) => ({
        ...provided,
      }),
      control: (provided: any, state: any) => {
        return {
          ...provided,
          maxWidth: fullWidth ? 'none' : maxWidth,
          boxSizing: 'border-box',
          borderRadius: state.menuIsOpen ? '8px 8px 0 0' : 8,
          transition: 'none',
          borderColor: !!error && touched ? '#e91044' : state.isFocused ? '#111bff' : '#f3f5fa',
          borderWidth: 1,
          lineHeight: '150%',
          pointerEvents: disabled ? 'none' : 'auto',
          opacity: disabled ? 0.5 : 1,
          boxShadow: 'none',
          padding: '8px 26px 8px 11px',
          minHeight: '45px',
          backgroundColor: '#f3f5fa',
          '&:hover': {
            // borderColor: state.isFocused ? '#2684ff' : !!error && touched ? '#c00' : '#666',
            boxShadow: 'none',
          },
        }
      },
      indicatorSeparator: (provided: any) => ({
        ...provided,
        display: 'none',
      }),
      clearIndicator: (provided: any) => ({
        ...provided,
        padding: 3,
      }),
      dropdownIndicator: (provided: any) => ({
        color: '#666',
        padding: 0,
        position: 'absolute' as const,
        marginTop: -10,
        right: 7,
        top: '50%',
        '&:hover': {
          color: '#666',
        },
      }),
      valueContainer: (provided: any) => ({
        ...provided,
        padding: 0,
      }),
      input: (provided: any) => ({
        ...provided,
        padding: 0,
        margin: 0,
        lineHeight: 1,
        fontFamily: "'Inter', sans-serif",
      }),
      singleValue: (provided: any) => ({
        ...provided,
        maxWidth: '100%',
        margin: 0,
        // transform: 'translateY(-50%)',
        // top: '52%',
      }),
      placeholder: (provided: any) => ({
        ...provided,
        maxWidth: '100%',
        margin: 0,
        transform: 'translateY(-50%)',
        top: '52%',
      }),
      menu: (provided: any) => ({
        ...provided,
        marginBottom: 0,
        marginTop: 0,
        maxWidth: fullWidth ? 'none' : maxWidth,
        boxShadow: 'none',
        backgroundColor: '#f3f5fa',
        border: focused ? '1px solid #111bff' : '1px solid #f3f5fa',
        borderTop: 'none',
        borderRadius: '0 0 8px 8px',
        padding: 0,
        zIndex: 300,
      }),
    },
    options,
    placeholder,
  }
  return (
    <div
      className={cn({
        [css.field]: true,
        [css.disabled]: disabledHere,
        [css.filled]: !!value?.length,
        [css.invalid]: invalid,
        [css.focused]: focused,
      })}
      style={{ maxWidth }}
    >
      {label && (
        <label className={css.label} htmlFor={name}>
          {label}
        </label>
      )}
      {floatingPlaceholder && (
        <div className={css.floatingPlaceholder}>
          <div className={css.floatingPlaceholderText}>{floatingPlaceholder}</div>
        </div>
      )}
      <div className={cn({ [css.inputPlace]: true, [css.invalid]: invalid })}>
        {creatable ? (
          <CreatableSelect formatCreateLabel={(label) => t('create', { label })} {...selectProps} {...restProps} />
        ) : (
          <ReactSelect {...selectProps} {...restProps} />
        )}
      </div>
      {invalid && <div className={css.error}>{error}</div>}
      {hint && <div className={css.hint}>{hint}</div>}
    </div>
  )
}
