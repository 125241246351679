export const plural = (
  number: number,
  titles:
    | [string, string, string]
    | {
        one: string
        few: string
        many: string
      },
  returnCountValue: boolean = true
) => {
  const titlesArray = Array.isArray(titles) ? titles : [titles.one, titles.few, titles.many]
  const cases = [2, 0, 1, 1, 1, 2]
  const formula = titlesArray[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
  return returnCountValue ? `${number} ${formula}` : formula
}
