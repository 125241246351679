import type { TrpcRouterOutput } from '@svag/backend/src/router'
import {
  getDisabledPropsOfUpdateConsultationInstanceInput,
  zUpdateConsultationInstanceInput,
} from '@svag/backend/src/router/consultation/updateConsultationInstance/input'
import { pick } from '@svag/shared/src/pick'
import { DatePicker } from '../../../../components/form/DatePicker'
import { FormItems } from '../../../../components/form/FormItems'
import { Input } from '../../../../components/form/Input'
import { Textarea } from '../../../../components/form/Textarea'
import { PublicUserPreview } from '../../../../components/other/PublicUserPreview'
import { Alert } from '../../../../components/ui/Alert'
import { Button } from '../../../../components/ui/Button'
import { Segment, Segments } from '../../../../components/ui/Segment'
import { useForm } from '../../../../lib/form'
import { useT } from '../../../../lib/i18n'
import { trpc } from '../../../../lib/trpc'
import {
  ConsultationPageInstanceInfo,
  useGetConsultationInstanceInfoWithCurrency,
} from '../../ConsultationPageInstanceInfo'
import { ConsultationPageVideo } from '../ConsultationPageVideo'

const Editor = ({
  consultationInstance,
}: {
  consultationInstance: TrpcRouterOutput['getConsultationInstance']['consultationInstance']
}) => {
  const { t } = useT('consultationPageConsultant')
  const updateConsultationInstance = trpc.updateConsultationInstance.useMutation()
  const trpcUtils = trpc.useContext()
  const form = useForm({
    validationSchema: zUpdateConsultationInstanceInput,
    initialValues: {
      consultationInstanceId: consultationInstance.id,
      ...pick(consultationInstance, [
        'title',
        'desc',
        'scheduledAt',
        'happendAt',
        'videoEmbedCode',
        'notes',
        'durationMinutes',
        'priceUsd',
        'prepayRequiredAmountUsd',
        'prepayDeadlineAt',
        'afterpayRequiredAmountUsd',
      ]),
    },
    onSubmit: async (values) => {
      const data = await updateConsultationInstance.mutateAsync(values)
      void trpcUtils.getConsultationInstancesForConsultant.invalidate()
      trpcUtils.getConsultationInstance.setData(
        { consultationInstanceSerialNumber: consultationInstance.serialNumber },
        (oldData) => {
          if (!oldData) {
            return oldData
          }
          return {
            ...oldData,
            consultationInstance: {
              ...oldData.consultationInstance,
              ...data.updatedConsultationInstance,
            },
          }
        }
      )
    },
    showValidationAlert: true,
    resetOnSuccess: false,
    successMessage: t('edit.successMessage'),
  })

  const disabledProps = getDisabledPropsOfUpdateConsultationInstanceInput({
    consultationInstance,
  })

  return (
    <Segment size="M" title={t('edit.segmentTitle')}>
      <form onSubmit={form.formik.handleSubmit}>
        <FormItems>
          <Input formik={form.formik} name="priceUsd" label={t('edit.fields.priceUsd')} />
          <Input formik={form.formik} name="title" label={t('edit.fields.title')} />
          <Input formik={form.formik} name="desc" label={t('edit.fields.desc')} />
          <DatePicker formik={form.formik} name="scheduledAt" label={t('edit.fields.scheduledAt')} />
          <Input
            disabled={disabledProps.prepayRequiredAmountUsd}
            formik={form.formik}
            name="prepayRequiredAmount"
            label={t('edit.fields.prepayRequiredAmount')}
          />
          <DatePicker
            disabled={disabledProps.prepayDeadlineAt}
            formik={form.formik}
            name="prepayDeadlineAt"
            label={t('edit.fields.prepayDeadlineAt')}
          />
          <Input
            disabled={disabledProps.afterpayRequiredAmountUsd}
            formik={form.formik}
            name="afterpayRequiredAmount"
            label={t('edit.fields.afterpayRequiredAmount')}
          />

          <DatePicker formik={form.formik} name="happendAt" label={t('edit.fields.happendAt')} />
          <Input formik={form.formik} name="durationMinutes" label={t('edit.fields.durationMinutes')} />
          <Textarea formik={form.formik} name="videoEmbedCode" label={t('edit.fields.videoEmbedCode')} maxWidth={800} />
          <Textarea formik={form.formik} name="notes" label={t('edit.fields.notes')} maxWidth={800} />
          <Alert {...form.alertProps} />
          <Button type="submit" {...form.buttonProps}>
            {t('edit.saveButton')}
          </Button>
        </FormItems>
      </form>
    </Segment>
  )
}

export const ConsultationPageConsultant = ({
  consultationInstance,
}: {
  consultationInstance: TrpcRouterOutput['getConsultationInstance']['consultationInstance']
}) => {
  const { t } = useT('consultationPageConsultant')
  const { getConsultationInstanceInfoWithCurrency } = useGetConsultationInstanceInfoWithCurrency()
  return (
    <Segment title={`${consultationInstance.title} #${consultationInstance.serialNumber}`}>
      <Segments>
        <Segment size="M" title={t('info.segmentTitle')}>
          <ConsultationPageInstanceInfo
            size="m"
            info={getConsultationInstanceInfoWithCurrency({
              consultationInstance,
              pickKeys: ['status', 'happendAt', 'scheduledAt', 'prepay', 'afterpay', 'price'],
            })}
          />
        </Segment>
        <Segment size="M" title={t('attendee.segmentTitle')}>
          <PublicUserPreview size="m" publicUser={consultationInstance.attendee} />
        </Segment>
        <ConsultationPageVideo consultationInstance={consultationInstance} />
        <Editor consultationInstance={consultationInstance} />
      </Segments>
    </Segment>
  )
}
