import { canConsulting } from '@svag/backend/src/services/user/can'
import { Button } from '../../../components/ui/Button'
import { Segment, Segments } from '../../../components/ui/Segment'
import { useMe } from '../../../lib/ctx'
import { useT } from '../../../lib/i18n'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getNewConsultationRoute } from '../../../lib/routes'
import { ConsultationsPageAttendeeActive } from './ConsultationsPageAttendeeActive'
import { ConsultationsPageAttendeeInactive } from './ConsultationsPageAttendeeInactive'
import { ConsultationsPageConsultantActive } from './ConsultationsPageConsultantActive'
import { ConsultationsPageConsultantInactive } from './ConsultationsPageConsultantInactive'
import { ConsultationsPageOffers } from './ConsultationsPageOffers'

export const ConsultationsPage = withPageWrapper({
  title: ({ t }) => t('consultationsPage.title'),
  authorizedOnly: true,
  langs: ['ru', 'en'],
})(() => {
  const { t } = useT('consultationsPage')
  const me = useMe()
  return (
    <Segment
      title={t('title')}
      rightRender={
        canConsulting(me) && (
          <>
            <Button nowrap to={getNewConsultationRoute()}>
              {t('newConsultation')}
            </Button>
          </>
        )
      }
    >
      <Segments>
        <ConsultationsPageConsultantActive />
        <ConsultationsPageConsultantInactive />
        <ConsultationsPageAttendeeActive />
        <ConsultationsPageOffers />
        <ConsultationsPageAttendeeInactive />
      </Segments>
    </Segment>
  )
})
