import type { ProductIdentifier } from '@svag/backend/src/services/product'
import { action, atom } from 'nanostores'

export const purchaseProductStore = atom({
  isModalOpen: false,
  isCurrenciesVisible: true,
  productIdentifier: null as never as ProductIdentifier,
  step: 'start' as 'start' | 'payment' | 'success' | 'fail',
})

export const openPurchaseProductModal = action(
  purchaseProductStore,
  'openPurchaseProductModal',
  (store, productIdentifier: ProductIdentifier) => {
    store.set({ ...store.get(), isModalOpen: true, isCurrenciesVisible: true, productIdentifier, step: 'start' })
  }
)

export const closePurchaseProductModal = action(purchaseProductStore, 'openPurchaseProductModal', (store) => {
  store.set({ ...store.get(), isModalOpen: false })
})

export const hideCurrenciesInPurchaseProductModal = action(
  purchaseProductStore,
  'hideCurrenciesInPurchaseProductModal',
  (store) => {
    store.set({ ...store.get(), isCurrenciesVisible: false })
  }
)
