import type { TrpcRouterOutput } from '@svag/backend/src/router'
import React, { useEffect } from 'react'
import { DashboardLayout } from '../../../../../components/layouts/DashboardLayout'
import { GeneralLayout } from '../../../../../components/layouts/GeneralLayout'
import { Button, Buttons } from '../../../../../components/ui/Button'
import { trackSuccessEdPromo, trackVisitEdPromo } from '../../../../../lib/mixpanel'
import { withPageWrapper } from '../../../../../lib/pageWrapper'
import { trpc } from '../../../../../lib/trpc'
import { getHoursFromDuration } from '../../../../../utils/getHoursFromDuration'
import css from './index.module.scss'

const Layout = ({
  children,
  guide,
}: {
  children: React.ReactNode
  guide: TrpcRouterOutput['getStudentGuideFull']['guide']
}) => {
  return (
    <GeneralLayout
      fullWidthContent
      // logoLinkPolicy="scrollToTop"
      logoLinkPolicy="navigateToHome"
      logoImagePolicy="education"
      showSidebar={false}
      parseWindowSize={(size) => ({
        isMobile: size.width <= 600,
        isDesktop: size.width > 600,
      })}
    >
      {children}
    </GeneralLayout>
  )
}

export const EdDevPromoJTMShortPage = withPageWrapper({
  useQueries: () => {
    const queriesResults = trpc.useQueries((t) => [
      t.getStudentGuideFull({
        guideSlug: 'dev-web-iserdmi',
      }),
    ])
    return queriesResults
  },
  title: ({ queriesResults }) => queriesResults[0].data?.guide?.title,
  eventsContext: ({ queriesResults }) => ({
    guide: queriesResults[0].data?.guide,
  }),
  ErrorLayout: DashboardLayout,
  setProps: ({ queriesResults }) => {
    const guide = queriesResults[0].data.guide
    const guideLessons = queriesResults[0].data.lessons
    const videosHoursCount = getHoursFromDuration(
      guide.plans.find((plan) => plan.name === 'pro')?.videosDuration || '00:00'
    )
    const freeLessonsCount = guide.plans.find((plan) => plan.name === 'free')?.lessonsCount || 0
    return {
      guide,
      guideLessons,
      videosHoursCount,
      freeLessonsCount,
    }
  },
  langs: ['ru', 'en'],
  showLoaderOnFetching: false,
})(({ guide, guideLessons, videosHoursCount, freeLessonsCount }) => {
  useEffect(() => {
    trackVisitEdPromo({
      target: 'junmidshort',
    })
  }, [])
  return (
    <Layout guide={guide}>
      <div className={css.problem}>
        <div className={css.wrapper}>
          <div className={css.sectionHeader}>
            <h1 className={css.sectionTitle}>
              <span className={css.sectionTitleText}>Проблемы каждого джуна</span>
            </h1>
          </div>
          <div className={css.items}>
            <div className={css.problemOuter}>
              <div className={css.problem}>
                <b>Вы не умеете создавать проекты с нуля,</b> и на работе таких задач вам не доверяют, а значит вы и не
                научитесь
              </div>
            </div>
            <div className={css.problemOuter}>
              <div className={css.problem}>
                <b>Вы не учитесь писать хороший код,</b> а учитесь поддерживать чужой код, который не факт что хороший,
                а значит писать хороший код вы и не научитесь
              </div>
            </div>
            <div className={css.problemOuter}>
              <div className={css.problem}>
                <b>Вы не знаете какой стек технологий, библиотек, и подходов самый эффективный,</b> и узнаете это только
                перепробовав огромное множество комбинаций, зря потратив 90% времени
              </div>
            </div>
            <div className={css.problemOuter}>
              <div className={css.problem}>
                Если вы будете просто работать в надежде стать однажды хотя бы мидлом,{' '}
                <b>вы станете мидлом лет через 5</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={css.solution}>
        <div className={css.wrapper}>
          <div className={css.sectionHeader}>
            <h1 className={css.sectionTitle}>
              <span className={css.sectionTitleText}>
                Есть решение, как стать мидлом за пол года, и сеньором за два года
              </span>
            </h1>
          </div>
          <div className={css.buttonsOuter}>
            <Buttons>
              <Button
                href="tg://resolve?domain=junmidbot&start=c1701850009460-ds"
                target="_blank"
                size="l"
                eventElementGroup="edMarketingContinue"
                eventElementId="edMarketingContinueTelegram"
                onClick={() => {
                  trackSuccessEdPromo({
                    target: 'junmidshort',
                  })
                }}
              >
                Продолжить в телеграме
              </Button>
            </Buttons>
          </div>
        </div>
      </div>
    </Layout>
  )
})
