import { useStore } from '@nanostores/react'
import { zSignInInput } from '@svag/backend/src/router/auth/signIn/input'
import Cookies from 'js-cookie'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormItems } from '../../../components/form/FormItems'
import { Input } from '../../../components/form/Input'
import { Alert } from '../../../components/ui/Alert'
import { Button, Buttons } from '../../../components/ui/Button'
import { useCacheRecord, useSyncCacheRecord } from '../../../lib/cache'
import { useForm } from '../../../lib/form'
import { replaceLangInRoutePathname, setLangToCookies, useT } from '../../../lib/i18n'
import { mixpanelIdentify, trackSignIn } from '../../../lib/mixpanel'
import { getRestorePasswordRoute, getSignInRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import { lastVisistedNotAuthRouteStore } from '../../other/NotAuthRouteTracker'
import { closeAuthModal, switchAuthComponentChoice } from '../Auth'

const isStringHasLetters = (value: string) => /[a-zA-Z]/.test(value)

export const SignIn = ({ onSuccess }: { onSuccess?: null | (() => void | Promise<void>) }) => {
  const { t } = useT('signIn')
  const lastVisistedNotAuthRoute = useStore(lastVisistedNotAuthRouteStore)
  const navigate = useNavigate()
  const trpcUtils = trpc.useContext()
  const signIn = trpc.signIn.useMutation()
  const { cacheRecord, clearCacheRecord } = useCacheRecord({
    key: 'authForm',
    defaultValue: { email: '', phone: '' },
  })
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      emailOrPhone: cacheRecord.email || cacheRecord.phone,
      password: '',
    },
    validationSchema: zSignInInput,
    onSubmit: async (values) => {
      const { token, userId, userLang } = await signIn.mutateAsync(values)
      mixpanelIdentify(userId)
      trackSignIn()
      Cookies.set('token-svag', token, { expires: 99999 })
      void trpcUtils.invalidate().then(async () => {
        return await onSuccess?.()
      })
      closeAuthModal()
      clearCacheRecord()
      setLangToCookies(userLang)
      navigate(replaceLangInRoutePathname(lastVisistedNotAuthRoute, userLang), { replace: true })
    },
  })
  useSyncCacheRecord({
    key: 'authForm',
    source: formik.values,
    to: (from) => {
      if (isStringHasLetters(from.emailOrPhone)) {
        return { email: from.emailOrPhone }
      }
      return { phone: from.emailOrPhone }
    },
  })
  const { pathname } = useLocation()
  const isItSignInPage = pathname === getSignInRoute()

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormItems>
        {/* <RadioButtons direction="row" formik={formik} label="Способ входа" name="method">
          <RadioButton formik={formik} label="Почта" name="method" value="email" />
          <RadioButton formik={formik} label="Телефон" name="method" value="phone" />
        </RadioButtons>
        {formik.values.method === 'email' && (
          <Input floatingPlaceholder="Электронная почта" name="email" formik={formik} />
        )}
        {formik.values.method === 'phone' && <Input floatingPlaceholder="Телефон" name="phone" formik={formik} />} */}
        <Input floatingPlaceholder={t('emailOrPhonePlaceholder')} name="emailOrPhone" formik={formik} />
        <Input floatingPlaceholder={t('passwordPlaceholder')} name="password" type="password" formik={formik} />
        <Alert {...alertProps} />
        <Buttons>
          <Button type="submit" {...buttonProps}>
            {t('signInButton')}
          </Button>
          <Button
            color="brandAccentLight"
            {...(isItSignInPage
              ? {
                  to: getRestorePasswordRoute(),
                }
              : {
                  onClick: () => {
                    switchAuthComponentChoice('restorePassword')
                  },
                })}
          >
            {t('restorePasswordButton')}
          </Button>
        </Buttons>
      </FormItems>
    </form>
  )
}
