import { type ConsultationInstance } from '@prisma/client'
import { zStringOptional, zStringRequired, zVideoEmbedCodeOptional } from '@svag/shared/src/zod'
import { z } from 'zod'

export const zUpdateConsultationInstanceInput = z.object({
  consultationInstanceId: zStringRequired,
  desc: zStringOptional.nullable(),
  title: zStringRequired,
  scheduledAt: z.coerce.date().nullable(),
  happendAt: z.coerce.date().nullable(),
  videoEmbedCode: zVideoEmbedCodeOptional.nullable(),
  notes: zStringOptional.nullable(),
  durationMinutes: z.coerce.number().nonnegative().int().nullable(),
  priceUsd: z.coerce.number().nonnegative().nonnegative(),
  prepayRequiredAmountUsd: z.coerce.number().nonnegative().int().default(0),
  prepayDeadlineAt: z.coerce.date().nullable(),
  afterpayRequiredAmountUsd: z.coerce.number().nonnegative().int().default(0),
})

type UpdateConsultationInstanceInput = z.infer<typeof zUpdateConsultationInstanceInput>

export const getDisabledPropsOfUpdateConsultationInstanceInput = ({
  consultationInstance,
}: {
  consultationInstance: Pick<ConsultationInstance, 'prepayPaidAt' | 'afterpayPaidAt' | 'happendAt'>
}) => {
  const disabledProps: Partial<
    Record<
      keyof Pick<
        UpdateConsultationInstanceInput,
        'prepayRequiredAmountUsd' | 'prepayDeadlineAt' | 'afterpayRequiredAmountUsd'
      >,
      true
    >
  > = {}
  if (consultationInstance.prepayPaidAt) {
    disabledProps.prepayRequiredAmountUsd = true
    disabledProps.prepayDeadlineAt = true
  }
  if (consultationInstance.afterpayPaidAt) {
    disabledProps.afterpayRequiredAmountUsd = true
  }
  return disabledProps
}
