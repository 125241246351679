import type { TrpcRouterOutput } from '@svag/backend/src/router'
import type { Direction } from '@svag/backend/src/services/other/prisma'
import cn from 'classnames'
import { useEffect, useState } from 'react'
import { Link, Outlet, useLocation, useParams } from 'react-router-dom'
import { setGuideSettings, useGuideSettings } from '../../../lib/guidesSettingsStore'
import { useT } from '../../../lib/i18n'
import { withPageWrapper } from '../../../lib/pageWrapper'
import {
  getEducationRoute,
  getGuidePlansRoute,
  getGuideSourceCodeRoute,
  type GuideHomeRouteParams,
  type GuideLessonRouteParams,
} from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import { useWindowSize } from '../../../lib/useWindowSize'
import { Icon } from '../../ui/Icon'
import { DashboardLayout } from '../DashboardLayout'
import { Footer } from '../Footer'
import { LessonsMenu } from './LessonsMenu'
import css from './index.module.scss'

const GeneralNavigationHeader = ({ title, direction }: { title: string; direction: Direction }) => {
  const { t } = useT('guideLayout')
  return (
    <div className={css.generalNavigationHeader}>
      <div className={cn({ [css.iconAndLabel]: true, [css[direction]]: true })}>
        <Icon className={css.icon} name="guide" />
        <span className={css.label}>{t('generalNavigationHeader.label')}</span>
      </div>
      <h2 className={css.title}>{title}</h2>
    </div>
  )
}

const GeneralNavigationMenu = ({ guide }: { guide: NonNullable<TrpcRouterOutput['getStudentGuideFull']['guide']> }) => {
  const { t } = useT('guideLayout')
  return (
    <ul className={css.generalNavigationMenu}>
      {!!guide.promoUrlPath && (
        <Link to={guide.promoUrlPath} className={css.link}>
          <Icon className={css.icon} name="info" />
          <span className={css.text}>{t('generalNavigationMenu.info')}</span>
        </Link>
      )}
      <Link
        to={getGuidePlansRoute({
          guideSlug: guide.slug,
        })}
        className={css.link}
      >
        <Icon className={css.icon} name="wallet" />
        <span className={css.text}>{t('generalNavigationMenu.plans')}</span>
      </Link>
      {!!guide.githubRepos[0] && (
        <Link
          to={getGuideSourceCodeRoute({
            guideSlug: guide.slug,
          })}
          className={css.link}
        >
          <Icon className={css.icon} name="code" />
          <span className={css.text}>{t('generalNavigationMenu.sourceCode')}</span>
        </Link>
      )}
      <Link to={getEducationRoute()} className={css.link}>
        <Icon className={css.icon} name="backArrow" />
        <span className={css.text}>{t('generalNavigationMenu.backToDashboard')}</span>
      </Link>
    </ul>
  )
}

const useWindowInfo = () => {
  const { width, height } = useWindowSize()
  const isHeightBig = height > 600
  const isWidthBig = width > 1200
  return { width, height, isWidthBig, isHeightBig }
}

const Navigation = ({
  lessons,
  currentLesson,
  guide,
}: {
  lessons: TrpcRouterOutput['getStudentGuideFull']['lessons']
  currentLesson: TrpcRouterOutput['getStudentGuideFull']['lessons'][number] | null
  guide: NonNullable<TrpcRouterOutput['getStudentGuideFull']['guide']>
}) => {
  const { t } = useT('guideLayout')
  const { isHeightBig, isWidthBig } = useWindowInfo()
  const [view, setView] = useState<'lessons' | 'general'>('general')
  const guideSettings = useGuideSettings(guide.slug)
  return (
    <div className={css.navigation}>
      {view === 'general' && (
        <div className={css.navigationGeneralView}>
          <div className={css.navigationFixedPart}>
            {isWidthBig && (
              <div
                className={css.burger}
                onClick={() => {
                  setGuideSettings(guide.slug, {
                    ...guideSettings,
                    desktopMenuHidden: !guideSettings.desktopMenuHidden,
                  })
                }}
              >
                <Icon name="burger" className={css.icon} />
              </div>
            )}
            <GeneralNavigationHeader {...guide} />
            {!isHeightBig && (
              <div className={css.toLessonsView}>
                <button
                  className={css.link}
                  onClick={() => {
                    setView('lessons')
                  }}
                >
                  <Icon className={css.icon} name="items" />
                  <span className={css.text}>{t('navigation.lessonsNavigation')}</span>
                </button>
              </div>
            )}
            <GeneralNavigationMenu guide={guide} />
          </div>
          {isHeightBig && (
            <div className={css.navigationScrollablePart}>
              <LessonsMenu lessons={lessons} currentLesson={currentLesson} guide={guide} />
            </div>
          )}
        </div>
      )}
      {view === 'lessons' && (
        <div className={css.navigationLessonsView}>
          <div className={css.navigationFixedPart}>
            <div className={css.toGeneralView}>
              <button
                className={css.link}
                onClick={() => {
                  setView('general')
                }}
              >
                <Icon className={css.icon} name="backArrow" />
                <span className={css.text}>{t('navigation.backToMenu')}</span>
              </button>
            </div>
          </div>
          <div className={css.navigationScrollablePart}>
            <LessonsMenu lessons={lessons} currentLesson={currentLesson} guide={guide} />
          </div>
        </div>
      )}
    </div>
  )
}

const HeaderTitle = ({
  lessons,
  currentLesson,
  guide,
}: {
  lessons: TrpcRouterOutput['getStudentGuideFull']['lessons']
  currentLesson: TrpcRouterOutput['getStudentGuideFull']['lessons'][number] | null
  guide: NonNullable<TrpcRouterOutput['getStudentGuideFull']['guide']>
}) => {
  return (
    <div className={css.headerTitle}>
      {!!currentLesson && (
        <div className={css.position}>
          {currentLesson.index + 1}/{lessons.length}
        </div>
      )}
      <div className={css.title}>{currentLesson ? currentLesson.title : guide.title}</div>
    </div>
  )
}

const LinearHeader = ({
  lessons,
  currentLesson,
  guide,
}: {
  lessons: TrpcRouterOutput['getStudentGuideFull']['lessons']
  currentLesson: TrpcRouterOutput['getStudentGuideFull']['lessons'][number] | null
  guide: NonNullable<TrpcRouterOutput['getStudentGuideFull']['guide']>
}) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const { pathname } = useLocation()
  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [menuOpen])
  useEffect(() => {
    setMenuOpen(false)
  }, [pathname])
  return (
    <div className={cn({ [css.linearHeader]: true, [css.menuOpened]: menuOpen })}>
      <div className={css.place}></div>
      <div className={css.fixed}>
        <div className={css.content}>
          <div className={css.linearHeaderTop} id="header-mobile-panel">
            <div
              className={css.burger}
              onClick={() => {
                setMenuOpen(!menuOpen)
              }}
            >
              {!menuOpen && <Icon name="burger" className={css.icon} />}
              {menuOpen && <Icon name="crossMenu" className={css.icon} />}
            </div>
            <HeaderTitle lessons={lessons} currentLesson={currentLesson} guide={guide} />
          </div>
          <div className={css.linearHeaderMenu}>
            <Navigation lessons={lessons} currentLesson={currentLesson} guide={guide} />
          </div>
        </div>
      </div>
    </div>
  )
}

export const GuideLayout = withPageWrapper({
  useQuery: () => {
    const { guideSlug } = useParams() as GuideLessonRouteParams | GuideHomeRouteParams
    return trpc.getStudentGuideFull.useQuery({
      guideSlug,
    })
  },
  setProps: ({ queryResult }) => ({
    guide: queryResult.data.guide,
    lessons: queryResult.data.lessons,
  }),
  showLoaderOnFetching: false,
  ErrorLayout: DashboardLayout,
  langs: ['ru', 'en'],
})(({ guide, lessons }) => {
  const { isWidthBig } = useWindowInfo()
  const params = useParams() as GuideLessonRouteParams | {}
  const guideSettings = useGuideSettings(guide.slug)
  const currentLesson = ('lessonSlug' in params && lessons.find((lesson) => lesson.slug === params.lessonSlug)) || null
  return (
    <div className={css.guideLayout}>
      {!isWidthBig ? (
        <div className={css.layoutVertical}>
          <div className={css.layoutVerticalHeader}>
            <LinearHeader guide={guide} lessons={lessons} currentLesson={currentLesson} />
          </div>
          <div className={css.layoutVerticalContent}>
            <div className={css.contentAndFooter}>
              <div className={css.content}>
                <Outlet />
              </div>
              <Footer className={css.footer} disableFooterPositioning={true} />
            </div>
          </div>
        </div>
      ) : (
        <div className={cn({ [css.layoutColumns]: true, [css.openedSidebar]: !guideSettings.desktopMenuHidden })}>
          <div className={css.layoutColumnSidebar}>
            <Navigation lessons={lessons} currentLesson={currentLesson} guide={guide} />
          </div>
          <div className={css.layoutColumnContent}>
            <div className={css.contentAndFooter}>
              <div className={css.content}>
                <div className={css.headerTitlePlace}>
                  <div className={css.headerTitleFixed}>
                    <HeaderTitle lessons={lessons} currentLesson={currentLesson} guide={guide} />
                  </div>
                </div>
                <Outlet />
              </div>
              <Footer className={css.footer} disableFooterPositioning={true} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
})
