import type React from 'react'
export { ReactComponent as IconStar } from '../../../assets/icons/star.svg'

export const AnimatedStars = ({
  children,
  className,
  visibleClassName,
}: {
  children: React.ReactNode
  className?: string
  visibleClassName?: string
}) => {
  return null
  // const [wasVisible, setWasVisible] = useState(false)
  // const ref = useRef<HTMLDivElement>(null)
  // const { height: windowHeight } = useWindowSize()

  // useEffect(() => {
  //   if (!wasVisible) {
  //     const handler = () => {
  //       if (ref.current) {
  //         const { top: elementTop, height: elementHeight } = ref.current.getBoundingClientRect()
  //         const omission = Math.ceil(windowHeight * 0.3)
  //         // const visibleByTop = elementTop - omission < scrollTop
  //         const visibleInBottom = elementTop < windowHeight - omission
  //         const visibleInTop = elementTop + elementHeight > omission
  //         if (visibleInBottom && visibleInTop) {
  //           setWasVisible(true)
  //         }
  //       }
  //     }
  //     handler()
  //     window.removeEventListener('scroll', handler)
  //     window.addEventListener('scroll', handler, { passive: true })
  //     return () => {
  //       window.removeEventListener('scroll', handler)
  //     }
  //   }
  // }, [windowHeight, wasVisible, ref])

  // return (
  //   <div
  //     ref={ref}
  //     className={cn({
  //       [css.animatedStars]: true,
  //       [css.visible]: wasVisible,
  //       [visibleClassName || '']: wasVisible && visibleClassName,
  //       [className || '']: !!className,
  //     })}
  //   >
  //     {children}
  //   </div>
  // )
}
