import type { TrpcRouterOutput } from '@svag/backend/src/router'
import { type UseTRPCInfiniteQueryResult } from '@trpc/react-query/dist/shared'
import { Alert } from '../../../../components/ui/Alert'
import { Button } from '../../../../components/ui/Button'
import { Cards } from '../../../../components/ui/Card'
import { useT } from '../../../../lib/i18n'
import { ConsultationsPageAttendeeInstance } from './ConsultationsPageAttendeeInstance'
import { ConsultationsPageConsultantInstance } from './ConsultationsPageConsultantInstance'
import css from './index.module.scss'

export const ConsultationsPageInstanceCard = ({
  consultationInstance,
  setRef,
}: {
  consultationInstance:
    | TrpcRouterOutput['getConsultationInstancesForAttendee']['consultationInstances'][number]
    | TrpcRouterOutput['getConsultationInstancesForConsultant']['consultationInstances'][number]
    | TrpcRouterOutput['getDashboardItems']['consultationInstances'][number]
  setRef: React.RefCallback<any>
}) => {
  const { t } = useT('consultationsPageInstances')
  if (consultationInstance.isMeAttendee) {
    return <ConsultationsPageAttendeeInstance setRef={setRef} consultationInstance={consultationInstance} />
  }
  if (consultationInstance.isMeConsultant) {
    return <ConsultationsPageConsultantInstance setRef={setRef} consultationInstance={consultationInstance} />
  }
  return <Alert color="red">{t('unknownConsultationType')}</Alert>
}

export const ConsultationsPageInstances = ({
  queryResult,
}: {
  queryResult:
    | UseTRPCInfiniteQueryResult<TrpcRouterOutput['getConsultationInstancesForAttendee'], any>
    | UseTRPCInfiniteQueryResult<TrpcRouterOutput['getConsultationInstancesForConsultant'], any>
}) => {
  const { t } = useT('consultationsPageInstances')
  const consultationInstances = queryResult.data?.pages.flatMap((page) => page.consultationInstances) || []
  return (
    <div className={css.consultationInstances}>
      <div className={css.items}>
        <Cards
          getColumnsCount={({ windowWidth, items }) => {
            if (windowWidth < 600) {
              // return Math.min(items.length, 1)
              return 1
            }
            if (windowWidth < 1250) {
              // return Math.min(items.length, 2)
              return 2
            }
            // return Math.min(items.length, 3)
            return 3
          }}
          items={consultationInstances}
          itemKeyKey="id"
          cardRender={({ item: consultationInstance, setItemRef }) => {
            return <ConsultationsPageInstanceCard setRef={setItemRef} consultationInstance={consultationInstance} />
          }}
        />
      </div>
      {queryResult.hasNextPage && (
        <div className={css.loadMore}>
          <Button
            loading={queryResult.isFetchingNextPage}
            onClick={() => {
              void queryResult.fetchNextPage()
            }}
            color="brandAccentLight"
            fullWidth
          >
            {t('loadMore')}
          </Button>
        </div>
      )}
    </div>
  )
}
