import differenceInHours from 'date-fns/differenceInHours'
import parseISO from 'date-fns/parseISO'
import z from 'zod'

export const zUTMS = z.object({
  utm_source: z.string().optional(),
  utm_medium: z.string().optional(),
  utm_campaign: z.string().optional(),
  utm_term: z.string().optional(),
  utm_content: z.string().optional(),
})
export const zUTMSDated = zUTMS.extend({
  dateISO: z
    .string()
    .min(1)
    .transform((dateISO) => {
      const date = new Date(dateISO)
      if (isNaN(date.getTime())) {
        return new Date(0).toISOString()
      }
      return dateISO
    }),
})
export const zUTMSHistory = z.array(zUTMSDated)
export type UTMS = z.infer<typeof zUTMS>
export type UTMSDated = z.infer<typeof zUTMSDated>
export type UTMSHistory = z.infer<typeof zUTMSHistory>

export const getEventDataByUtmsHistory = (utmsHistory: UTMSHistory) => {
  const utmsDated = getLastUtmsDatedFromHistory(utmsHistory)
  if (!utmsDated) {
    return {}
  }
  const utmsPropsWithoutDate = {
    utmSource: utmsDated.utm_source,
    utmMedium: utmsDated.utm_medium,
    utmCampaign: utmsDated.utm_campaign,
    utmTerm: utmsDated.utm_term,
    utmContent: utmsDated.utm_content,
  }
  const utmDate = new Date(utmsDated.dateISO)
  if (isNaN(utmDate.getTime())) {
    return { ...utmsPropsWithoutDate, utmDate: new Date(0), utmFresh: false }
  } else {
    return {
      ...utmsPropsWithoutDate,
      utmDate,
      utmFresh: Math.abs(differenceInHours(new Date(), utmDate)) < 24,
    }
  }
}

export const parseJsonUtms = (jsonUtms: string | undefined): UTMS | null => {
  try {
    if (!jsonUtms) {
      return null
    }
    const utms = JSON.parse(jsonUtms)
    return zUTMS.parse(utms)
  } catch (e) {
    return null
  }
}

export const parseJsonUtmsDated = (jsonUtmsDated: string | undefined): UTMSDated | null => {
  try {
    if (!jsonUtmsDated) {
      return null
    }
    const utmsDated = JSON.parse(jsonUtmsDated)
    return zUTMSDated.parse(utmsDated)
  } catch (e) {
    return null
  }
}

export const parseJsonUtmsHistory = (jsonUtmsHistory: string | undefined): UTMSHistory => {
  try {
    if (!jsonUtmsHistory) {
      return []
    }
    const utmsHistory = JSON.parse(jsonUtmsHistory)
    return zUTMSHistory.parse(utmsHistory)
  } catch (e) {
    return []
  }
}

export const appendUtmsHistory = (utmsHistory: UTMSHistory, utmsDated: UTMSDated): UTMSHistory => {
  return mergeUtmsHistories(utmsHistory, [utmsDated])
}

export const getLastUtmsDatedFromHistory = (utmsHistory: UTMSHistory): UTMSDated | null => {
  return utmsHistory[0] || null
}

const isUtmsDatedEqual = (utms1: UTMSDated, utms2: UTMSDated): boolean => {
  const utms1Date = parseISO(utms1.dateISO)
  const utms2Date = parseISO(utms2.dateISO)
  return (
    utms1.utm_source === utms2.utm_source &&
    utms1.utm_medium === utms2.utm_medium &&
    utms1.utm_campaign === utms2.utm_campaign &&
    utms1.utm_term === utms2.utm_term &&
    utms1.utm_content === utms2.utm_content &&
    Math.abs(differenceInHours(utms1Date, utms2Date)) < 24
  )
}

export const isUtmsHistoriesEqual = (utmsHistory1: UTMSHistory, utmsHistory2: UTMSHistory): boolean => {
  if (utmsHistory1.length !== utmsHistory2.length) {
    return false
  }
  let foundSameUtmsCount = 0
  for (const utms1 of utmsHistory1) {
    for (const utms2 of utmsHistory2) {
      if (isUtmsDatedEqual(utms1, utms2)) {
        foundSameUtmsCount++
        break
      }
    }
  }
  return foundSameUtmsCount === utmsHistory1.length
}

export const mergeUtmsHistories = (utmsHistory1: UTMSHistory, utmsHistory2: UTMSHistory): UTMSHistory => {
  const utmsHistoryCombined = [...utmsHistory1, ...utmsHistory2]
  const utmsHistoryUnique = []
  for (const utms1 of utmsHistoryCombined) {
    let utms1ExistsInHistoryUnique = false
    for (const utms2 of utmsHistoryUnique) {
      if (isUtmsDatedEqual(utms1, utms2)) {
        utms1ExistsInHistoryUnique = true
        break
      }
    }
    if (!utms1ExistsInHistoryUnique) {
      utmsHistoryUnique.push(utms1)
    }
  }
  const utmsHistorySorted = utmsHistoryUnique.sort((utmsDated1, utmsDated2) => {
    return parseISO(utmsDated2.dateISO).getTime() - parseISO(utmsDated1.dateISO).getTime()
  })
  return utmsHistorySorted
}
