import { useEffect, useState } from 'react'

export const useScrollTop = () => {
  const [scrollTop, setScrollTop] = useState(0)
  useEffect(() => {
    const onScroll = () => {
      setScrollTop(window.scrollY)
    }
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    onScroll()
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])
  return { scrollTop }
}

export const useElementTopReached = ({ elementEl }: { elementEl: HTMLElement | null }) => {
  const [elementTopReached, setElementTopReached] = useState(false)
  const { scrollTop } = useScrollTop()
  useEffect(() => {
    const elTop = elementEl?.getBoundingClientRect().top || null
    if (elTop === null) {
      return
    }
    if (elTop <= 0 && !elementTopReached) {
      setElementTopReached(true)
    }
    if (elTop > 0 && elementTopReached) {
      setElementTopReached(false)
    }
  }, [scrollTop, elementTopReached, setElementTopReached, elementEl])
  return { elementTopReached }
}
