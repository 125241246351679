import css from './index.module.scss'

export const EdDevPromoNewbiePageProduct = () => {
  return (
    <div className={css.productSection}>
      <div className={css.wrapper}>
        <div className={css.sectionHeader}>
          <h1 className={css.sectionTitle}>
            <span className={css.sectionTitleText}>Решите, какой продукт создавать</span>
          </h1>
        </div>
        <div className={css.mainText}>
          <p>Если вы уже давно мечтаете создать какой-то продукт, то вот эту идею и берите.</p>
          <p>Если у вас идеи ещё нет, то при выборе придерживайтесь следующих принципов:</p>
          <ul>
            <li>Проект должен быть не слишком примитивным, иначе вы получите недостаточно опыта.</li>
            <li>Проект должен быть не слишком сложным, иначе вы будете разрабатывать его слишком долго.</li>
            <li>Не важно, станет ли проект успешным с точки зрения бизнеса, вам главное — его разработать.</li>
            <li>
              Идеально, чтобы в проекте были следующие функции, так как они встречаются практически во всех проектах. И
              если вы сможете реализовать их однажды, вы сможете создать практически любой проект:
              <ul>
                <li>Авторизация: регистрация, вход, восстановление пароля и т. д.</li>
                <li>Подписка или платные продукты.</li>
                <li>Взаимодействие между пользователями.</li>
              </ul>
            </li>
          </ul>
          <p>А вот список готовых идей для вдохновения:</p>
          <ul>
            <li>Социальная сеть для любителей котиков (или любой другой группы людей)</li>
            <li>CRM система для клининговых компаний (или любых других компаний)</li>
            <li>Сервис для знакомств</li>
            <li>Сервис для поиска работы</li>
            <li>Коллективной блог</li>
            <li>Сервис доставки еды</li>
            <li>Приложение для учета расходов</li>
            <li>Платформа для обмена книгами</li>
            <li>Сайт для организации мероприятий</li>
            <li>Приложение для медитаций</li>
            <li>Сервис аренды автомобилей</li>
            <li>Сайт для планирования путешествий</li>
            <li>Приложение для трекинга привычек</li>
            <li>Сервис для поиска соседей по комнате</li>
            <li>Платформа для фрилансеров</li>
            <li>Приложение для планирования бюджета свадьбы</li>
            <li>Сервис для онлайн-консультаций с врачами</li>
            <li>Социальная сеть для домашних питомцев и их владельцев</li>
            <li>Приложение для трекинга фитнес-активности</li>
            <li>Онлайн-галерея для художников</li>
            <li>Платформа для поиска жилья</li>
            <li>Сайт для рецептов и кулинарных блогов</li>
            <li>Таймтрекер с элементами социальной сети</li>
            <li>Платформа для обмена музыкальными инструментами</li>
          </ul>
        </div>
        <div className={css.products}></div>
      </div>
    </div>
  )
}
