import PhotoSwipe from 'photoswipe'

export const openGallery = ({ imagesUrls }: { imagesUrls: string[] }) => {
  const dataSource = imagesUrls.map((imageUrl) => {
    const searchParams = new URLSearchParams(imageUrl.split('?')[1])
    const w = searchParams.get('w')
    const h = searchParams.get('h')
    return {
      src: imageUrl,
      ...(!w ? {} : { width: parseInt(w) }),
      ...(!h ? {} : { height: parseInt(h) }),
    }
  })
  const pswp = new PhotoSwipe({
    index: 0,
    dataSource,
    showHideAnimationType: 'fade',
    initialZoomLevel: 'fill',
  })
  pswp.init()
  const toTop = () => {
    pswp.currSlide?.panTo(0, 0)
    const els = document.querySelectorAll<HTMLElement>('.pswp__zoom-wrap')
    els.forEach((el) => {
      const transformParts = el.style.transform.split(',')
      transformParts[1] = ' 0px'
      el.style.transform = transformParts.join(',')
    })
  }
  pswp.on('change', function () {
    toTop()
  })
  pswp.on('openingAnimationEnd', function () {
    toTop()
  })
}
