/* eslint-disable import/order */
import cn from 'classnames'
import css from './index.module.scss'
import { trackClick } from '../../../../../../lib/mixpanel'

// @index('../../../../../../assets/ed/tech/**/*.svg', f => `import { ReactComponent as ${_.capitalize(f.path.split('/').pop())} } from '${f.path}${f.ext}'`)
import { ReactComponent as Aws } from '../../../../../../assets/ed/tech/aws.svg'
import { ReactComponent as Balsamiq } from '../../../../../../assets/ed/tech/balsamiq.svg'
import { ReactComponent as Brevo } from '../../../../../../assets/ed/tech/brevo.svg'
import { ReactComponent as Cloudflare } from '../../../../../../assets/ed/tech/cloudflare.svg'
import { ReactComponent as Cloudinary } from '../../../../../../assets/ed/tech/cloudinary.svg'
import { ReactComponent as Datadog } from '../../../../../../assets/ed/tech/datadog.svg'
import { ReactComponent as Docker } from '../../../../../../assets/ed/tech/docker.svg'
import { ReactComponent as Eslint } from '../../../../../../assets/ed/tech/eslint.svg'
import { ReactComponent as Express } from '../../../../../../assets/ed/tech/express.svg'
import { ReactComponent as Formik } from '../../../../../../assets/ed/tech/formik.svg'
import { ReactComponent as Heroku } from '../../../../../../assets/ed/tech/heroku.svg'
import { ReactComponent as Husky } from '../../../../../../assets/ed/tech/husky.svg'
import { ReactComponent as Jest } from '../../../../../../assets/ed/tech/jest.svg'
import { ReactComponent as Mixpanel } from '../../../../../../assets/ed/tech/mixpanel.svg'
import { ReactComponent as Mjml } from '../../../../../../assets/ed/tech/mjml.svg'
import { ReactComponent as Node } from '../../../../../../assets/ed/tech/node.svg'
import { ReactComponent as Passport } from '../../../../../../assets/ed/tech/passport.svg'
import { ReactComponent as Pg } from '../../../../../../assets/ed/tech/pg.svg'
import { ReactComponent as Pnpm } from '../../../../../../assets/ed/tech/pnpm.svg'
import { ReactComponent as Prettier } from '../../../../../../assets/ed/tech/prettier.svg'
import { ReactComponent as Prisma } from '../../../../../../assets/ed/tech/prisma.svg'
import { ReactComponent as React } from '../../../../../../assets/ed/tech/react.svg'
import { ReactComponent as Router } from '../../../../../../assets/ed/tech/router.svg'
import { ReactComponent as Scss } from '../../../../../../assets/ed/tech/scss.svg'
import { ReactComponent as Sentry } from '../../../../../../assets/ed/tech/sentry.svg'
import { ReactComponent as Stylelint } from '../../../../../../assets/ed/tech/stylelint.svg'
import { ReactComponent as Trpc } from '../../../../../../assets/ed/tech/trpc.svg'
import { ReactComponent as Ts } from '../../../../../../assets/ed/tech/ts.svg'
import { ReactComponent as Vite } from '../../../../../../assets/ed/tech/vite.svg'
import { ReactComponent as Zod } from '../../../../../../assets/ed/tech/zod.svg'
// @endindex

const stack = [
  { name: 'React', Icon: React, link: 'https://reactjs.org/' },
  { name: 'TypeScript', Icon: Ts, link: 'https://www.typescriptlang.org/' },
  { name: 'Vite', Icon: Vite, link: 'https://vitejs.dev/' },
  { name: 'Node.js', Icon: Node, link: 'https://nodejs.org/en/' },
  { name: 'pnpm', Icon: Pnpm, link: 'https://pnpm.io/' },
  { name: 'Express', Icon: Express, link: 'https://expressjs.com/' },
  { name: 'trpc', Icon: Trpc, link: 'https://trpc.io/' },
  { name: 'PostgreSQL', Icon: Pg, link: 'https://www.postgresql.org/' },
  { name: 'Prisma', Icon: Prisma, link: 'https://www.prisma.io/' },
  { name: 'Formik', Icon: Formik, link: 'https://formik.org/' },
  { name: 'Zod', Icon: Zod, link: 'https://github.com/colinhacks/zod' },
  { name: 'Jest', Icon: Jest, link: 'https://jestjs.io/' },
  { name: 'Prettier', Icon: Prettier, link: 'https://prettier.io/' },
  { name: 'ESLint', Icon: Eslint, link: 'https://eslint.org/' },
  { name: 'Stylelint', Icon: Stylelint, link: 'https://stylelint.io/' },
  { name: 'SCSS', Icon: Scss, link: 'https://sass-lang.com/' },
  { name: 'Husky', Icon: Husky, link: 'https://typicode.github.io/husky/#/' },
  { name: 'Passport', Icon: Passport, link: 'http://www.passportjs.org/' },
  { name: 'React Router', Icon: Router, link: 'https://reactrouter.com/' },
  { name: 'Cloudinary', Icon: Cloudinary, link: 'https://cloudinary.com/' },
  { name: 'AWS S3', Icon: Aws, link: 'https://aws.amazon.com/s3/' },
  { name: 'MJML', Icon: Mjml, link: 'https://mjml.io/' },
  { name: 'Brevo', Icon: Brevo, link: 'https://www.brevo.com/' },
  { name: 'CRON', link: 'https://en.wikipedia.org/wiki/Cron' },
  { name: 'Winston', link: 'https://github.com/winstonjs/winston' },
  { name: 'Balsamiq', Icon: Balsamiq, link: 'https://balsamiq.com/' },
  { name: 'Sentry', Icon: Sentry, link: 'https://sentry.io/' },
  { name: 'Mixpanel', Icon: Mixpanel, link: 'https://mixpanel.com/' },
  { name: 'Cloudflare', Icon: Cloudflare, link: 'https://www.cloudflare.com/' },
  { name: 'Docker', Icon: Docker, link: 'https://www.docker.com/' },
  { name: 'DataDog', Icon: Datadog, link: 'https://www.datadoghq.com/' },
  { name: 'Heroku', Icon: Heroku, link: 'https://www.heroku.com/' },
]

// copyToClipboard(stack.map((tech) => `'${tech.name}': '${tech.link}'`).join('\n'))

export const EdDevPromoPageStack = ({ size, className }: { className?: string; size: 'm' | 's' }) => {
  return (
    <div
      className={cn(css.stack, className, css[`size-${size}`])}
      onClick={(e) => {
        const target = e.target as HTMLElement
        if (target.tagName === 'A') {
          const dataAttributes = target.dataset
          trackClick({
            elementId: 'edPromoStackLink',
            extra: dataAttributes,
          })
        }
      }}
    >
      {stack.map((tech, i) => (
        <a
          href={tech.link}
          target="_blank"
          key={i}
          className={cn({ [css.tech]: true, [css.withIcon]: !!tech.Icon })}
          rel="noreferrer"
          data-stack-item={tech.name}
        >
          {tech.Icon && <tech.Icon className={css.icon} />}
          <span className={css.name}>{tech.name}</span>
        </a>
      ))}
    </div>
  )
}
