import type { TrpcRouterOutput } from '@svag/backend/src/router'
import type { ProductIdentifier } from '@svag/backend/src/services/product'
import { type Currency } from '@svag/shared/src/currency'
import { deepMap } from '@svag/shared/src/deepMap'
import { getEventDataByUtmsHistory } from '@svag/shared/src/utm'
import cloneDeep from 'lodash/cloneDeep'
import mixpanel from 'mixpanel-browser'
import { atom } from 'nanostores'
import { useEffect, useRef } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { type EdItem } from '../components/education/cards/EdCard'
import { appContextStore, useMe } from './ctx'
import { getCurrenciesFromStore } from './currency'
import { env } from './env'
import { getLangByRoutePathname, removeLangFromRoutePathname } from './i18n'
import { trpc } from './trpc'
import { utmsHistoryStore } from './utm'

if (env.VITE_MIXPANEL_TOKEN) {
  mixpanel.init(env.VITE_MIXPANEL_TOKEN)
}

export type EventsContext = {
  guide?: TrpcRouterOutput['getStudentGuideFull']['guide']
  guideLesson?: TrpcRouterOutput['getStudentGuideLesson']['lesson']
  article?: TrpcRouterOutput['getArticle']['article']
  extra?: Record<string, any>
}
export const eventsContextStore = atom<EventsContext>({})

const getEventDataByGuide = ({ guide }: { guide: TrpcRouterOutput['getStudentGuideFull']['guide'] }) => {
  return {
    guideSlug: guide.slug,
    guideId: guide.id,
    guidePlans: guide.plans.map((plan) => plan.name),
    guideCurrentPlan: guide.myPlan,
    guideStartedAt: guide.startedByMeAt,
    guideFinishedAt: guide.finishedByMeAt,
    guideDirection: guide.direction,
    guideAuthorId: guide.author.id,
    guideAuthorSlug: guide.author.slug,
    guideCurrentLessonId: guide.currentGuideLesson?.id,
    guideCurrentLessonSlug: guide.currentGuideLesson?.slug,
    guideCurrentLessonIndex: guide.currentGuideLesson?.index,
  }
}

const getEventDataByGuideLesson = ({
  guideLesson,
}: {
  guideLesson:
    | TrpcRouterOutput['getStudentGuideLesson']['lesson']
    | TrpcRouterOutput['getStudentGuideFull']['lessons'][number]
}) => {
  return {
    guideLessonSlug: guideLesson.slug,
    guideLessonId: guideLesson.id,
    guideLessonIndex: guideLesson.index,
    guideLessonMinRequiredPlan: guideLesson.minRequiredPlan,
    guideLessonPlans: guideLesson.plans,
    guideLessonIsFinished: guideLesson.isFinished,
    guideLessonIsLocked: guideLesson.isLocked,
    guideLessonIsLockedBecouseNotAuthorized: guideLesson.isLockedBecouseNotAuthorized,
    guideLessonIsLockedBecouseEmailNotConfirmed: guideLesson.isLockedBecouseEmailNotConfirmed,
    guideLessonIsLockedBecouseLowPlan: guideLesson.isLockedBecouseLowPlan,
    guideLessonVideoDuration: guideLesson.videoDuration,
  }
}

const getEventDataByProductIdentifier = ({ productIdentifier }: { productIdentifier: ProductIdentifier }) => {
  if (productIdentifier.type === 'guide') {
    return {
      productIdentifierType: productIdentifier.type,
      productIdentifierGuideId: productIdentifier.options.guideId,
      productIdentifierGuidePlan: productIdentifier.options.guidePlan,
    }
  } else if (productIdentifier.type === 'consultationAfterpay') {
    return {
      productIdentifierType: productIdentifier.type,
      productIdentifierConsultationInstanceId: productIdentifier.options.consultationInstanceId,
    }
  } else if (productIdentifier.type === 'consultationPrepay') {
    return {
      productIdentifierType: productIdentifier.type,
      productIdentifierConsultationInstanceId: productIdentifier.options.consultationInstanceId,
    }
  } else if (productIdentifier.type === 'groupRenew') {
    return {
      productIdentifierType: productIdentifier.type,
      productIdentifierGroupInstanceId: productIdentifier.options.groupInstanceId,
    }
  } else if (productIdentifier.type === 'groupStart') {
    return {
      productIdentifierType: productIdentifier.type,
      productIdentifierGroupInstanceId: productIdentifier.options.groupInstanceId,
    }
  }
  return {
    productIdentifierType: (productIdentifier as any)?.type,
  }
}

const getEventDataByProduct = ({ product }: { product: TrpcRouterOutput['getProduct']['product'] }) => {
  return {
    productType: product.type,
    productAmountUsd: product.amountUsd,
    productAmountRub: product.amountRub,
    productExtra: product.extra,
  }
}

const getEventDataByArticle = ({ article }: { article: TrpcRouterOutput['getArticle']['article'] }) => {
  return {
    articleId: article.id,
    articleSlug: article.slug,
    articleAuthorId: article.author.id,
    articleAuthorSlug: article.author.slug,
    articleEdItemsIds: article.edItemsIds,
  }
}

const whenMixpanelEnabled = <T,>(fn: T): T => {
  return env.VITE_MIXPANEL_TOKEN ? fn : ((() => {}) as T)
}

type EventProps = { eventName: string; eventData?: Record<string, any> }
function defTrack(getEventProps: () => EventProps): () => void
function defTrack<T>(getEventProps: (input: T) => EventProps): (input: T) => void
function defTrack<T>(getEventProps: (input: T) => EventProps) {
  return (input: T) => {
    const eventProps = getEventProps(input)
    const eventsContext = eventsContextStore.get()
    const allEventProps = {
      hostEnv: env.HOST_ENV,
      userCurrency: getCurrenciesFromStore(),
      userLang: getLangByRoutePathname(window.location.pathname),
      userAuthorized: !!appContextStore.get().me?.id,
      pathname: removeLangFromRoutePathname(window.location.pathname),
      prevPathname: prevPathnameStore.get(),
      searchParams: Object.fromEntries(new URLSearchParams(window.location.search)),
      ...getEventDataByUtmsHistory(utmsHistoryStore.get().utmsHistory),
      ...(eventsContext.guide ? getEventDataByGuide({ guide: eventsContext.guide }) : {}),
      ...(eventsContext.guideLesson ? getEventDataByGuideLesson({ guideLesson: eventsContext.guideLesson }) : {}),
      ...(eventsContext.article ? getEventDataByArticle({ article: eventsContext.article }) : {}),
      ...(eventsContext.extra || {}),
      ...eventProps.eventData,
    }
    const normalizedAllEventProps = deepMap<typeof allEventProps>(allEventProps, ({ value, path }) => {
      if (value instanceof Date) {
        return value.toISOString()
      }
      return value
    })
    track(eventProps.eventName, normalizedAllEventProps)
  }
}

const track = (eventName: EventProps['eventName'], eventData?: EventProps['eventData']) => {
  if (env.HOST_ENV !== 'production') {
    console.info('track', eventName, cloneDeep(eventData))
  }
  whenMixpanelEnabled(() => {
    mixpanel.track(eventName, cloneDeep(eventData))
  })()
  ;(window as any).ym?.(95540190, 'reachGoal', eventName, cloneDeep(eventData))
  ;(window as any).gtag?.('event', eventName, cloneDeep(eventData))
}

// Identification

export const mixpanelIdentify = whenMixpanelEnabled((userId: string) => {
  mixpanel.identify(userId)
})

export const mixpanelAlias = whenMixpanelEnabled((userId: string) => {
  mixpanel.alias(userId)
})

export const mixpanelReset = whenMixpanelEnabled(() => {
  mixpanel.reset()
})

// General

export const trackVisitPage = defTrack(
  ({
    pathname,
    searchParams,
    prevPathname,
  }: {
    pathname: string
    searchParams: URLSearchParams
    prevPathname?: string
  }) => {
    return {
      eventName: 'visitPage',
      eventData: {
        prevPathname: prevPathname ? removeLangFromRoutePathname(prevPathname) : undefined,
        pathname: removeLangFromRoutePathname(pathname),
        lang: getLangByRoutePathname(pathname),
        searchParams: Object.fromEntries(searchParams),
      },
    }
  }
)

export const trackClick = defTrack(
  ({
    elementId,
    elementGroup,
    to,
    extra,
  }: {
    elementId?: string
    elementGroup?: string
    to?: string
    extra?: Record<string, any>
  }) => {
    return {
      eventName: 'click',
      eventData: {
        elementId,
        elementGroup,
        to,
        ...extra,
      },
    }
  }
)

// Auth

export const trackSignUpStart = defTrack(({ name, phone, email }: { name: string; email: string; phone: string }) => {
  return {
    eventName: 'signUpStart',
    eventData: {
      name,
      phone,
      email,
    },
  }
})

export const trackSignUp = defTrack(({ name, phone, email }: { name: string; email: string; phone: string }) => {
  return {
    eventName: 'signUp',
    eventData: {
      name,
      phone,
      email,
    },
  }
})

export const trackEmailVerificationSuccess = defTrack(() => {
  return {
    eventName: 'emailVerificationSuccess',
  }
})

export const trackEmailVerificationFail = defTrack(() => {
  return {
    eventName: 'emailVerificationFail',
  }
})

export const trackSignIn = defTrack(() => {
  return {
    eventName: 'signIn',
  }
})

// Guides
// Guides Promo

export const trackVisitEdPromo = defTrack(({ target }: { target: string }) => {
  return {
    eventName: 'visitEdPromo',
    eventData: {
      target,
    },
  }
})

export const trackSuccessEdPromo = defTrack(({ target }: { target: string }) => {
  return {
    eventName: 'successEdPromo',
    eventData: {
      target,
    },
  }
})

// Guides Lessons

export const trackBlockedGuideLessonBecouseNotAuthorized = defTrack(() => {
  return {
    eventName: 'blockedGuideLessonBecouseNotAuthorized',
  }
})

export const trackBlockedGuideLessonBecouseEmailNotConfirmed = defTrack(() => {
  return {
    eventName: 'blockedGuideLessonBecouseEmailNotConfirmed',
  }
})

export const trackBlockedGuideLessonBecouseLowPlan = defTrack(
  ({ currentPlan, minRequiredPlan }: { currentPlan: string; minRequiredPlan: string }) => {
    return {
      eventName: 'blockedGuideLessonBecouseLowPlan',
      eventData: {
        currentPlan,
        minRequiredPlan,
      },
    }
  }
)

export const trackVisitGuideLesson = defTrack(
  ({
    guideLesson,
  }: {
    guideLesson:
      | TrpcRouterOutput['getStudentGuideLesson']['lesson']
      | TrpcRouterOutput['getStudentGuideFull']['lessons'][number]
  }) => {
    return {
      eventName: 'visitGuideLesson',
      eventData: {
        ...getEventDataByGuideLesson({ guideLesson }),
      },
    }
  }
)

export const trackViewGuideLessonOneMinute = defTrack(
  ({
    guideLesson,
  }: {
    guideLesson:
      | TrpcRouterOutput['getStudentGuideLesson']['lesson']
      | TrpcRouterOutput['getStudentGuideFull']['lessons'][number]
  }) => {
    return {
      eventName: 'viewGuideLessonOneMinute',
      eventData: {
        ...getEventDataByGuideLesson({ guideLesson }),
      },
    }
  }
)

export const trackMarkGuideLessonAsCompleted = defTrack(
  ({
    guideLesson,
  }: {
    guideLesson:
      | TrpcRouterOutput['getStudentGuideLesson']['lesson']
      | TrpcRouterOutput['getStudentGuideFull']['lessons'][number]
  }) => {
    return {
      eventName: 'markGuideLessonAsCompleted',
      eventData: {
        ...getEventDataByGuideLesson({ guideLesson }),
      },
    }
  }
)

// Guides Source Code

export const trackBlockedGuideSourceCodeBecouseNotAuthorized = defTrack(() => {
  return {
    eventName: 'blockedGuideSourceCodeBecouseNotAuthorized',
  }
})

export const trackBlockedGuideSourceCodeBecouseEmailNotConfirmed = defTrack(() => {
  return {
    eventName: 'blockedSourceCodeBecouseEmailNotConfirmed',
  }
})

export const trackBlockedGuideSourceCodeBecouseLowPlan = defTrack(
  ({ currentPlan, minRequiredPlan }: { currentPlan: string; minRequiredPlan: string }) => {
    return {
      eventName: 'blockedGuideSourceCodeBecouseLowPlan',
      eventData: {
        currentPlan,
        minRequiredPlan,
      },
    }
  }
)

export const trackVisitGuideSourceCode = defTrack(() => {
  return {
    eventName: 'visitGuideSourceCode',
  }
})

export const trackGuideSourceCodeAccessAllowed = defTrack(
  ({ githubUsername, githubRepoName }: { githubUsername: string; githubRepoName: string }) => {
    return {
      eventName: 'guideSourceCodeAccessAllowed',
      eventData: {
        githubUsername,
        githubRepoName,
      },
    }
  }
)

// Purchases

export const trackBlockedPurchaseBecouseNotAuthorized = defTrack(
  ({ productIdentifier }: { productIdentifier: ProductIdentifier }) => {
    return {
      eventName: 'blockedPurchaseBecouseNotAuthorized',
      eventData: {
        ...getEventDataByProductIdentifier({ productIdentifier }),
      },
    }
  }
)

export const trackBlockedPurchaseBecouseEmailNotConfirmed = defTrack(
  ({ productIdentifier }: { productIdentifier: ProductIdentifier }) => {
    return {
      eventName: 'blockedPurchaseBecouseEmailNotConfirmed',
      eventData: {
        ...getEventDataByProductIdentifier({ productIdentifier }),
      },
    }
  }
)

export const trackStartPurchase = defTrack(
  ({
    product,
    productIdentifier,
  }: {
    product: TrpcRouterOutput['getProduct']['product']
    productIdentifier: ProductIdentifier
  }) => {
    return {
      eventName: 'startPurchase',
      eventData: {
        ...getEventDataByProductIdentifier({ productIdentifier }),
        ...getEventDataByProduct({ product }),
      },
    }
  }
)

export const trackPressPurchaseButton = defTrack(
  ({
    product,
    productIdentifier,
    currency,
    amount,
  }: {
    product: TrpcRouterOutput['getProduct']['product']
    productIdentifier: ProductIdentifier
    currency: Currency
    amount: number
  }) => {
    return {
      eventName: 'pressPurchaseButton',
      eventData: {
        ...getEventDataByProductIdentifier({ productIdentifier }),
        ...getEventDataByProduct({ product }),
        currency,
        amount,
      },
    }
  }
)

// Videos

export const trackStartWatchVideo = defTrack(
  ({
    elementId,
    elementGroup,
    guide,
    guideLesson,
  }: {
    elementId?: string
    elementGroup?: string
    guide?: TrpcRouterOutput['getStudentGuideFull']['guide']
    guideLesson?: TrpcRouterOutput['getStudentGuideLesson']['lesson']
  }) => {
    return {
      eventName: 'startWatchVideo',
      eventData: {
        elementId,
        elementGroup,
        ...(guide ? getEventDataByGuide({ guide }) : {}),
        ...(guideLesson ? getEventDataByGuideLesson({ guideLesson }) : {}),
      },
    }
  }
)

export const trackWatchVideoOneMinute = defTrack(
  ({
    elementId,
    elementGroup,
    guide,
    guideLesson,
  }: {
    elementId?: string
    elementGroup?: string
    guide?: TrpcRouterOutput['getStudentGuideFull']['guide']
    guideLesson?: TrpcRouterOutput['getStudentGuideLesson']['lesson']
  }) => {
    return {
      eventName: 'watchVideoOneMinute',
      eventData: {
        elementId,
        elementGroup,
        ...(guide ? getEventDataByGuide({ guide }) : {}),
        ...(guideLesson ? getEventDataByGuideLesson({ guideLesson }) : {}),
      },
    }
  }
)

export const trackFinishWatchVideo = defTrack(
  ({
    elementId,
    elementGroup,
    guide,
    guideLesson,
  }: {
    elementId?: string
    elementGroup?: string
    guide?: TrpcRouterOutput['getStudentGuideFull']['guide']
    guideLesson?: TrpcRouterOutput['getStudentGuideLesson']['lesson']
  }) => {
    return {
      eventName: 'finishWatchVideo',
      eventData: {
        elementId,
        elementGroup,
        ...(guide ? getEventDataByGuide({ guide }) : {}),
        ...(guideLesson ? getEventDataByGuideLesson({ guideLesson }) : {}),
      },
    }
  }
)

// Consultations

export const trackRequestConsultation = defTrack(
  ({ consultation }: { consultation: Extract<EdItem, { type: 'consultation' }> }) => {
    return {
      eventName: 'requestConsultation',
      eventData: {
        consultationSlug: consultation.slug,
        consultationConsultantSlug: consultation.consultant.slug,
        consultationConsultantId: consultation.consultant.id,
      },
    }
  }
)

// Groups

export const trackRequestGroup = defTrack(
  ({
    group,
  }: {
    group: Extract<EdItem, { type: 'group' }> | TrpcRouterOutput['getGroupInstance']['groupInstance']
  }) => {
    return {
      eventName: 'requestGroup',
      eventData: {
        groupSlug: group.slug,
        groupTeacherSlug: group.teacher.slug,
        groupTeacherId: group.teacher.id,
      },
    }
  }
)

// Community

export const trackJoinCommunityDiscordStart = defTrack(() => {
  return {
    eventName: 'joinCommunityDiscordStart',
  }
})

export const trackJoinCommunityDiscord = defTrack(() => {
  return {
    eventName: 'joinCommunityDiscord',
  }
})

export const trackJoinCommunityTelegram = defTrack(({ link, slug }: { link: string; slug: string }) => {
  return {
    eventName: 'joinCommunityTelegram',
    eventData: {
      link,
      slug,
    },
  }
})

// Article

export const trackVisitArticle = defTrack(({ article }: { article: TrpcRouterOutput['getArticle']['article'] }) => {
  return {
    eventName: 'visitArticle',
    eventData: {
      ...getEventDataByArticle({ article }),
    },
  }
})

export const trackViewArticleOneMinute = defTrack(
  ({ article }: { article: TrpcRouterOutput['getArticle']['article'] }) => {
    return {
      eventName: 'viewArticleOneMinute',
      eventData: {
        ...getEventDataByArticle({ article }),
      },
    }
  }
)

export const trackUserData = whenMixpanelEnabled((data: Record<string, any>) => {
  mixpanel.people.set(data)
})

export const MixpanelUser = () => {
  const me = useMe()
  useEffect(() => {
    if (!me?.id) {
      mixpanelReset()
    } else {
      mixpanelIdentify(me.id)
    }
  }, [me?.id])
  return null
}

export const UntrackedEvents = () => {
  const me = useMe()
  const trpcUtils = trpc.useContext()
  const forgetUntrackedEvents = trpc.forgetUntrackedEvents.useMutation()
  useEffect(() => {
    if (!me?.untrackedEvents.length) {
      return
    }
    void forgetUntrackedEvents.mutateAsync().then((result) => {
      trpcUtils.getMe.setData(undefined, result)
    })
    const untrackedEvents = me.untrackedEvents
    for (const untrackedEvent of untrackedEvents) {
      track(untrackedEvent.eventName, untrackedEvent.eventData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(me?.untrackedEvents)])
  return null
}

const prevPathnameStore = atom<string | undefined>()

export const TrackVisitPage = () => {
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const prevPathnameRef = useRef<string>()
  useEffect(() => {
    setTimeout(() => {
      trackVisitPage({ pathname, searchParams, prevPathname: prevPathnameRef.current })
      ;(window as any).gtag?.('config', 'G-HMZQDTDGEN', {
        page_title: window.document.title,
        page_location: window.location.href,
        page_path: pathname,
      })
      ;(window as any).ym?.(95540190, 'hit', window.location.href)
    }, 10)
    prevPathnameStore.set(prevPathnameRef.current)
    prevPathnameRef.current = pathname
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])
  return null
}

export const TrackClick = () => {
  useEffect(() => {
    const handler = (event: MouseEvent) => {
      const element = event.target as HTMLElement
      const elementId = element.getAttribute('data-event-element-id') || undefined
      const elementGroup = element.getAttribute('data-event-element-group') || undefined
      const to = element.getAttribute('href') || undefined
      if (!elementId && !elementGroup) {
        return
      }
      trackClick({ elementId, elementGroup, to })
    }
    document.body.addEventListener('click', handler)
    return () => {
      document.body.removeEventListener('click', handler)
    }
  }, [])
  return null
}
