import type { ClientSpecialistPreview } from '@svag/backend/src/services/general/specialist'
import type { ClientTeamPreview } from '@svag/backend/src/services/general/team'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { useT } from '../../../lib/i18n'
import { getTeamRoute, getUserRoute } from '../../../lib/routes'
import { Icon } from '../../ui/Icon'
import css from './index.module.scss'

type MemeberData = { team: ClientTeamPreview; specialist: null } | { specialist: ClientSpecialistPreview; team: null }

const Item = ({ member }: { member: MemeberData }) => {
  const { t } = useT('members')
  const { t: tg } = useT('general')
  return member.specialist ? (
    <div className={css.memberOuter}>
      <div className={css.member}>
        <Link className={css.avatarsPlace} to={getUserRoute({ userSlug: member.specialist.slug })}>
          <img className={css.specialistAvatar} src={member.specialist.avatar || ''} alt="" />
          {/* <img className={css.specialistTeamAvatar} src={member.specialist.teamAvatar || ''} alt="" /> */}
        </Link>
        <h3 className={css.name}>{member.specialist.name}</h3>
        <ul className={css.directions}>
          {member.specialist.directions.map((d, i) => (
            <li key={i} className={cn(css.direction, css[d])}>
              {tg('directions', { returnObjects: true })[d]}
            </li>
          ))}
        </ul>
        <p className={css.desc}>{member.specialist.desc}</p>
        <Link
          color="brandAccentLight"
          className={css.buttonProfile}
          to={getUserRoute({ userSlug: member.specialist.slug })}
        >
          {t('profile')}
          <Icon className={css.buttonIcon} name="chevronRight" />
        </Link>
      </div>
    </div>
  ) : member.team ? (
    <div className={css.memberOuter}>
      <div className={css.member}>
        <Link className={css.avatarsPlace} to={getTeamRoute({ teamSlug: member.team.slug })}>
          <img className={css.teamAvatar} src={member.team.avatar || ''} alt="" />
        </Link>
        <h3 className={css.name}>
          {t('team')} {member.team.name}
        </h3>
        <ul className={css.directions}>
          {member.team.directions.map((d, i) => (
            <li key={i} className={cn(css.direction, css[d])}>
              {tg('directions', { returnObjects: true })[d]}
            </li>
          ))}
        </ul>
        <p className={css.desc}>{member.team.desc}</p>
        <Link color="brandAccentLight" className={css.buttonProfile} to={getTeamRoute({ teamSlug: member.team.slug })}>
          {t('teamPage')}
          <Icon className={css.buttonIcon} name="chevronRight" />
        </Link>
      </div>
    </div>
  ) : null
}

export const Members = ({
  members,
  teams,
  specialists,
  className,
  hideTeams = true,
}: {
  className?: string
  members?: MemeberData[]
  teams?: ClientTeamPreview[]
  specialists?: ClientSpecialistPreview[]
  hideTeams?: boolean
}) => {
  const memebrsHere: MemeberData[] = [
    ...(members || []),
    ...(teams || []).map((team) => ({ team, specialist: null })),
    ...(specialists || []).map((specialist) => ({ specialist, team: null })),
  ]
  return (
    <div className={cn(css.members, className)}>
      {memebrsHere
        .filter((m) => !hideTeams || !m.team)
        .map((m, i) => (
          <Item key={m.team?.id || m.specialist?.id || i} member={m} />
        ))}
    </div>
  )
}
