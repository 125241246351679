import type { TrpcRouterOutput } from '@svag/backend/src/router'
import { plural } from '@svag/shared/src/plural'
import cn from 'classnames'
import { Button, Buttons } from '../../../../../../components/ui/Button'
import { useT } from '../../../../../../lib/i18n'
import css from './index.module.scss'

export const EdDevPromoPageYoutube = ({ guide }: { guide: TrpcRouterOutput['getStudentGuideFull']['guide'] }) => {
  const { t } = useT('edDevPromoPageYoutube')
  const { t: tg } = useT('general')
  const lessonsCount = guide.plans.find((plan) => plan.name === 'free')?.lessonsCount || 0

  return (
    <div className={cn({ [css.youtubeSection]: true })} id="form">
      <div className={css.wrapper}>
        <h1 className={css.sectionTitle}>
          {t('title', { lessons: plural(lessonsCount, tg('lessons', { returnObjects: true })) })}
        </h1>
        <p className={css.text}>{t('desc')}</p>
        <Buttons>
          <Button
            target="_bank"
            href={guide.author.youtube || '#'}
            eventElementId="edPromoYoutube"
            size="xl"
            color="systemPureWhite"
          >
            {t('button')}
          </Button>
        </Buttons>
      </div>
    </div>
  )
}
