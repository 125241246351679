import { useParams } from 'react-router-dom'
import { Auth } from '../../../components/auth/Auth'
import { ConfirmEmailBlocker } from '../../../components/auth/ConfirmEmailBlocker'
import { FormItems } from '../../../components/form/FormItems'
import { GithubInvitation } from '../../../components/other/GithubInvitation'
import { OnRender } from '../../../components/other/OnRender'
import { Alert } from '../../../components/ui/Alert'
import { Segment } from '../../../components/ui/Segment'
import { useT } from '../../../lib/i18n'
import {
  trackBlockedGuideSourceCodeBecouseEmailNotConfirmed,
  trackBlockedGuideSourceCodeBecouseLowPlan,
  trackBlockedGuideSourceCodeBecouseNotAuthorized,
  trackGuideSourceCodeAccessAllowed,
  trackVisitGuideSourceCode,
} from '../../../lib/mixpanel'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { type GuideHomeRouteParams } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import { GuidePlans } from '../GuidePlans'

export const GuideSourceCodePage = withPageWrapper({
  title: ({ queryResult }) => queryResult.data?.guide?.title,
  eventsContext: ({ queryResult }) => ({
    guide: queryResult.data?.guide,
  }),
  useQuery: () => {
    const { guideSlug } = useParams() as GuideHomeRouteParams
    return trpc.getStudentGuideFull.useQuery(
      {
        guideSlug,
      },
      {
        staleTime: 3000,
      }
    )
  },
  showLoaderOnFetching: false,
  setProps: ({ queryResult }) => ({
    guide: queryResult.data.guide,
  }),
  langs: ['ru', 'en'],
})(({ guide }) => {
  const { t } = useT('guideSourceCodePage')
  const githubRepo = guide.githubRepos[0]
  const trpcUtils = trpc.useContext()

  if (!githubRepo || !guide.isReady) {
    return (
      <Segment title={t('noSourceCodeTitle')}>
        <Alert message={t('noSourceCodeMessage')} color="brown" />
      </Segment>
    )
  }

  if (githubRepo.isLockedBecouseNotAuthorized) {
    return (
      <Segment title={githubRepo.title} desc={githubRepo.desc}>
        <OnRender
          handler={() => {
            trackBlockedGuideSourceCodeBecouseNotAuthorized()
          }}
          deps={[githubRepo.id]}
        />
        <Auth segmentSize="M" policy="component" initialChoice="signUp" alertMessage={t('lockedNotAuthorized')} />
      </Segment>
    )
  }

  if (githubRepo.isLockedBecouseLowPlan) {
    return (
      <Segment title={githubRepo.title} desc={githubRepo.desc}>
        <OnRender
          handler={() => {
            trackBlockedGuideSourceCodeBecouseLowPlan({
              currentPlan: guide.myPlan,
              minRequiredPlan: githubRepo.minRequiredPlan,
            })
          }}
          deps={[githubRepo.id]}
        />
        <FormItems>
          <Alert color="brown" message={t('lockedLowPlan')} />
          <GuidePlans guideSlug={guide.slug} />
        </FormItems>
      </Segment>
    )
  }

  if (githubRepo.isLockedBecouseEmailNotConfirmed) {
    return (
      <>
        <OnRender
          handler={() => {
            trackBlockedGuideSourceCodeBecouseEmailNotConfirmed()
          }}
          deps={[githubRepo.id]}
        />
        <ConfirmEmailBlocker alertMessage={t('lockedEmailNotConfirmed')} />
      </>
    )
  }

  return (
    <Segment title={githubRepo.title} desc={githubRepo.desc}>
      <OnRender
        handler={() => {
          trackVisitGuideSourceCode()
        }}
        deps={[githubRepo.id]}
      />
      <GithubInvitation
        githubRepo={githubRepo}
        onSuccess={({ githubRepo: updatedGithubRepo }) => {
          trackGuideSourceCodeAccessAllowed({
            githubRepoName: updatedGithubRepo.name,
            githubUsername: updatedGithubRepo.invitedGithubUsername,
          })
          trpcUtils.getStudentGuideFull.setData({ guideSlug: guide.slug }, (prevData) => {
            return !prevData
              ? prevData
              : {
                  ...prevData,
                  guide: {
                    ...prevData.guide,
                    githubRepos: prevData.guide.githubRepos.map((prevGithubRepo) => {
                      if (prevGithubRepo.id !== updatedGithubRepo.id) {
                        return prevGithubRepo
                      }
                      return { ...prevGithubRepo, ...updatedGithubRepo }
                    }),
                  },
                }
          })
        }}
      />
    </Segment>
  )
})
