import countries from 'i18n-iso-countries'
import localeRu from 'i18n-iso-countries/langs/ru.json'
import { sortBy } from 'lodash'
import memoize from 'lodash/memoize'

countries.registerLocale(localeRu)

export const getCountriesCodesAndNames = memoize(() => {
  const capitalzied = countries.getNames('ru', { select: 'official' })
  const lowercased = Object.entries(capitalzied).reduce<Record<string, string>>((acc, [code, name]) => {
    acc[code.toLowerCase()] = name
    return acc
  }, {})
  return lowercased
})

export const getCountriesSelectOptions = memoize(() => {
  const codesAndNames = getCountriesCodesAndNames()
  const options = Object.entries(codesAndNames).map(([code, name]) => ({
    value: code,
    label: name,
  }))
  const sortedOptions = sortBy(options, (option) => {
    const index = ['ge', 'am', 'ru', 'ua', 'by', 'kz', 'uz'].indexOf(option.value)
    return index === -1 ? options.length : index
  })
  return sortedOptions
})

export { countries }
