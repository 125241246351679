import { EdCards, type EdItem } from '../../../../components/education/cards/EdCard'
import { Alert } from '../../../../components/ui/Alert'
import { Loader } from '../../../../components/ui/Loader'
import { Segment } from '../../../../components/ui/Segment'
import { useT } from '../../../../lib/i18n'
import { trpc } from '../../../../lib/trpc'

export const ConsultationsPageOffers = () => {
  const { t } = useT('consultationsPageOffers')
  const queryResult = trpc.getEdItems.useQuery()
  const allConsultations = (queryResult.data?.edItems.filter((edItem) => edItem.type === 'consultation') ||
    []) as Array<Extract<EdItem, { type: 'consultation' }>>
  return (
    <Segment size="M" title={t('title')}>
      {queryResult.isLoading ? (
        <Loader type="section" />
      ) : queryResult.error ? (
        <Alert color="red">{queryResult.error.message}</Alert>
      ) : allConsultations.length ? (
        <EdCards edItems={allConsultations} />
      ) : (
        <Alert color="brown">{t('noOffersAlert')}</Alert>
      )}
    </Segment>
  )
}
