import React from 'react'
import { useCurrency } from '../../../../../lib/currency'
import { useT } from '../../../../../lib/i18n'
import { getGroupRoute } from '../../../../../lib/routes'
import { Button, Buttons } from '../../../../ui/Button'
import { Card } from '../../../../ui/Card'
import { LabelValueInfo } from '../../../../ui/LabelValueInfo'
import { type EdItem } from '../../EdCard'

export const GroupCardTeacher = ({
  setRef,
  groupInstance,
}: {
  setRef: React.RefCallback<any>
  groupInstance: Extract<EdItem, { type: 'group' }>
}) => {
  const { toMoneyWithCurrency } = useCurrency()
  const { t } = useT('groupCardTeacher')
  const { t: tg } = useT('general')
  return (
    <Card
      setRef={setRef}
      title={`${groupInstance.title}`}
      hintColor={groupInstance.direction}
      hintIconName="group"
      hintText={t('hintPrefix') + ' / ' + tg(`directions.${groupInstance.direction}`)}
      content={
        <LabelValueInfo
          size="s"
          items={[
            {
              label: t('content.activeStudents'),
              value: groupInstance.activeStudentsCount,
            },
            {
              label: t('content.monthlyPrice'),
              value: toMoneyWithCurrency({ rub: groupInstance.priceRub, usd: groupInstance.priceUsd }),
            },
          ]}
        />
      }
      buttons={
        <Buttons stretch>
          <Button
            to={getGroupRoute({
              groupInstanceSlug: groupInstance.slug,
            })}
            color="brandAccentLight"
          >
            {t('buttons.details')}
          </Button>
        </Buttons>
      }
    />
  )
}
