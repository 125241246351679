import { type Currency } from './currency'

export const toMoney = ({
  amount,
  currency,
  hideSymbol = false,
}: {
  amount: number
  currency: Currency
  hideSymbol?: boolean
}) => {
  if (!amount && amount !== 0) {
    return ''
  }
  if (currency === 'rub') {
    const formattedSum = amount.toLocaleString('de-DE').replace(/\./g, ' ')
    return hideSymbol ? formattedSum : `${formattedSum} ₽`
  } else if (currency === 'usd') {
    const formattedSum = amount.toLocaleString('de-DE').replace(/\./g, ',')
    return hideSymbol ? formattedSum : `$${formattedSum}`
  } else {
    throw new Error(`Unknown currency: ${currency}`)
  }
}

export const toMoneyByCurrency = ({
  rub,
  usd,
  currency,
  hideSymbol = false,
}: {
  rub: number
  usd: number
  currency: Currency
  hideSymbol?: boolean
}) => {
  if (currency === 'rub') {
    return toMoney({ amount: rub, currency, hideSymbol })
  } else if (currency === 'usd') {
    return toMoney({ amount: usd, currency, hideSymbol })
  } else {
    throw new Error(`Unknown currency: ${currency}`)
  }
}

export const normalizePrices = ({
  text,
  currency,
  rubInUsd,
}: {
  text: string
  currency: Currency
  rubInUsd: number
}) => {
  // Example of price inside text: "$100–300", "$1200–3600", "5000–15000$"
  const text1 = text.replace(/\$(\d+)[-–—](\d+)/g, (match, p1, p2) => {
    const price1Usd = parseInt(p1)
    const price1Rub = price1Usd * rubInUsd
    const price2Usd = parseInt(p2)
    const price2Rub = price2Usd * rubInUsd
    const price1 = currency === 'rub' ? price1Rub : price1Usd
    const price2 = currency === 'rub' ? price2Rub : price2Usd

    return `${toMoney({ currency, amount: price1, hideSymbol: currency === 'rub' })}–${toMoney({
      currency,
      amount: price2,
      hideSymbol: currency !== 'rub',
    })}`
  })
  const text2 = text1.replace(/\$(\d+)/g, (match, p1, p2) => {
    const priceUsd = parseInt(p1)
    const priceRub = priceUsd * rubInUsd
    const price = currency === 'rub' ? priceRub : priceUsd
    return toMoney({ currency, amount: price })
  })
  return text2
}
