import { zStringRequired } from '@svag/shared/src/zod'
import { z } from 'zod'

// export const zSigninByPhoneInput = z.object({
//   phone: zStringRequired,
//   password: zStringRequired,
// })

// export const zSigninByEmailInput = z.object({
//   email: zEmailRequired,
//   password: zStringRequired,
// })

// export const zSignInInput = z.union([zSigninByPhoneInput, zSigninByEmailInput])

export const zSignInInput = z.object({
  emailOrPhone: zStringRequired,
  password: zStringRequired,
})
