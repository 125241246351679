import { useParams } from 'react-router-dom'
import { Alert } from '../../../components/ui/Alert'
import { useT } from '../../../lib/i18n'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getConsultationsRoute, getEducationRoute, type ConsultationRouteParams } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import { ConsultationPageAttendee } from './ConsultationPageAttendee'
import { ConsultationPageConsultant } from './ConsultationPageConsultant'

export const ConsultationPage = withPageWrapper({
  title: ({ t }) => t('consultationPage.title'),
  showLoaderOnFetching: false,
  authorizedOnly: true,
  getBreadCrumbs: ({ t, queryResult }) => {
    return [
      { label: t('consultationPage.breadcrumbs.education'), to: getEducationRoute() },
      { label: t('consultationPage.breadcrumbs.consultations'), to: getConsultationsRoute() },
      {
        label: t('consultationPage.breadcrumbs.consultation', {
          serialNumber: queryResult.data.consultationInstance.serialNumber,
        }),
      },
    ]
  },
  useQuery: () => {
    const { consultationInstanceSerialNumber } = useParams() as ConsultationRouteParams
    return trpc.getConsultationInstance.useQuery({
      consultationInstanceSerialNumber: +consultationInstanceSerialNumber || -1,
    })
  },
  setProps: ({ queryResult, getAuthorizedMe }) => {
    return {
      consultationInstance: queryResult.data.consultationInstance,
      me: getAuthorizedMe(),
    }
  },
  langs: ['ru', 'en'],
})(({ consultationInstance, me }) => {
  const { t } = useT('consultationPage')
  if (consultationInstance.isMeConsultant) {
    return <ConsultationPageConsultant consultationInstance={consultationInstance} />
  }
  if (consultationInstance.isMeAttendee) {
    return <ConsultationPageAttendee consultationInstance={consultationInstance} />
  }
  return <Alert color="red">{t('alert.notParticipant')}</Alert>
})
