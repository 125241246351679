import type { TrpcRouterOutput } from '@svag/backend/src/router'
import cn from 'classnames'
import { useT } from '../../../../../../lib/i18n'
import { GuidePlans } from '../../../../../guide/GuidePlans'
import css from './index.module.scss'

export const EdDevPromoPagePlans = ({
  showTopMargin = true,
  showBottomPadding,
  guide,
  title,
  forcePromoCodeValue,
}: {
  showTopMargin?: boolean
  showBottomPadding?: boolean
  guide: TrpcRouterOutput['getStudentGuideFull']['guide']
  title?: string
  forcePromoCodeValue?: string
}) => {
  const { t } = useT('edDevPromoPagePlans')

  return (
    <div
      className={cn({
        [css.plansSection]: true,
        [css.showBottomPadding]: showBottomPadding,
        [css.showTopMargin]: showTopMargin,
      })}
      id="form"
    >
      <div className={css.wrapper}>
        <h1 className={css.sectionTitle}>{title || t('title')}</h1>
        <GuidePlans
          forcePromoCodeValue={forcePromoCodeValue}
          withStartButton={true}
          hideBorder={true}
          guideSlug={guide.slug}
        />
      </div>
    </div>
  )
}
