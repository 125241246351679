import css from './index.module.scss'

const content = [
  {
    title: 'Шаг 1. Создайте свой первый продукт',
    text: 'Выбрали идею продукта, выбрали стек, и вперёд! Только если будете делать это без поддержки, у вас это займёт больше времени, потому что сразу хорошо сделать вряд ли получится. Уже в следующем блоке я вам предложу свою поддержку!',
  },
  {
    title: 'Шаг 2. Составьте уникальное резюме и устройтесь мидлом на ЗП от 1000$',
    text: 'Резюме человека, который умеет создавать продукты с нуля от и до разительно отличается от резюме человека, который умеет только поддерживать чужой код. Поэтому вы сможете устроиться на работу мидлом, даже если у вас нет опыта работы в компании. Пример реюзме будет ниже.',
  },
  {
    title: 'Шаг 3. Уволитьтесь и разрабатывайте стартапы свои, на заказ и за долю',
    text: 'Вы возможно не ожидали, но разрабатывать полезные продукты с нуля это очень увлекательно и может быть даже прибыльнее работы в компании. Если желаете куража и интересной работы, настоятельно рекомендую поработать разработчиком стартапов. Там год опыта идёт за два, так много там интересных и сложных задач.',
  },
  {
    title: 'Шаг 4. Разочаруйтесь в стартапах и идите работать сеньором на ЗП от 2000$',
    text: 'Однако, не всем по душе работа над стартапами. И если вы попробовали и поняли, что это не ваше, то вы всегда сможете устроиться на работу сеньором, ведь вы уже будете матёрым разработчиком.',
  },
]

export const EdDevPromoJTMPageStartegy = () => {
  return (
    <div className={css.target}>
      <div className={css.wrapper}>
        <h1 className={css.sectionTitle}>
          <span className={css.sectionTitleText}>Следуйте плану и станьте успешным</span>
        </h1>
        <div className={css.items}>
          {content.map((item, index) => (
            <div className={css.itemOuter} key={index}>
              <div className={css.item}>
                <div className={css.title}>{item.title}</div>
                <div className={css.text}>{item.text}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
