import {
  persistentAtom,
  setPersistentEngine,
  type PersistentEvent,
  type PersistentListener,
} from '@nanostores/persistent'
import Cookies from 'js-cookie'

let listeners: PersistentListener[] = []
const onChange = (key: string, newValue: any) => {
  const event: PersistentEvent = { key, newValue }
  for (const listener of listeners) {
    listener(event)
  }
}

// Must implement storage[key] = value, storage[key], and delete storage[key]
const storage = new Proxy(
  {},
  {
    set(target: Record<string, any>, name: string, value: any) {
      Cookies.set(name, value, { expires: 99999 })
      target[name] = value
      onChange(name, value)
      return true
    },
    get(target: Record<string, any>, name: string) {
      return Cookies.get(name) || target[name]
    },
    deleteProperty(target: Record<string, any>, name: string) {
      Cookies.remove(name)
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete target[name]
      onChange(name, undefined)
      return true
    },
  }
)

// Must implement addEventListener and removeEventListener
const events = {
  addEventListener(key: string, callback: PersistentListener) {
    listeners.push(callback)
  },
  removeEventListener(key: string, callback: PersistentListener) {
    listeners = listeners.filter((listener) => listener !== callback)
  },
  // window dispatches "storage" events for any key change
  // => One listener for all map keys is enough
  perKey: false,
}

setPersistentEngine(storage, events)

export const cookiesAtom = persistentAtom
