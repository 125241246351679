import type { TrpcRouterOutput } from '@svag/backend/src/router'
import { Segment } from '../../../../components/ui/Segment'
import { useT } from '../../../../lib/i18n'
import css from './index.module.scss'

export const ConsultationPageVideo = ({
  consultationInstance,
}: {
  consultationInstance: TrpcRouterOutput['getConsultationInstance']['consultationInstance']
}) => {
  const { t } = useT('consultationPageVideo')

  if (!consultationInstance.videoEmbedCode) {
    return null
  }
  return (
    <Segment size="M" title={t('title')}>
      <div className={css.video}>
        <div dangerouslySetInnerHTML={{ __html: consultationInstance.videoEmbedCode }} />
      </div>
    </Segment>
  )
}
