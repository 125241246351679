import cn from 'classnames'
import React from 'react'
import css from './index.module.scss'

export const toPrettyText = (text: string, p: boolean = true) => {
  let result = !text
    ? ''
    : text
        .replace(/^\n*/, '') // Выпиливаем переносы строки из начала
        .replace(/\n*$/, '\n\n') // Выпиливаем переносы из конца и добавляем 2 в конец, чтобы удобно было оборачивать в <p>
        .replace(/\n\n\n+/g, '\n\n') // Заменяем все \n встречающиесе более 2 раз подряд, на двойные \n
        .replace(/([^\n])\n([^\n])/g, '$1<br>$2') // Преваращаем одиночные переносы в <br>
  if (p) {
    result = result.replace(/([^\n]*)\n\n/g, '<p>$1</p>') // Обращаем всё между двойными переносами в <p></p>
  } else {
    result = result.replace(/([^\n]*)\n\n/g, '$1<br>') // Обращаем всё между двойными переносами в <p></p>
  }
  // replace all links with <a target="_blank" href="...">...</a>
  result = result.replace(
    /((https?:\/\/|www\.)([-\w.]+)+(:\d+)?(\/([\w/_.]*(\?\S+)?)?)?)/gi,
    '<a target="_blank" href="$1">$1</a>'
  )
  return result
}

export const PrettyText: React.FC<{
  text: string
  className?: string
  style?: Record<string, any>
  limitedWidth?: boolean
}> = ({ text, className, limitedWidth = true, ...restProps }) => {
  const classNameHere = cn({
    [css.prettyText]: true,
    [className || '']: className,
    [css.limitedWidth]: limitedWidth,
  })

  return <div className={classNameHere} dangerouslySetInnerHTML={{ __html: toPrettyText(text) }} />
}
