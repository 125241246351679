import { ReactComponent as TriggerHat } from '../../../../../assets/home/triggers/hat.svg'
import { ReactComponent as TriggerPig } from '../../../../../assets/home/triggers/pig.svg'
import { ReactComponent as TriggerRocket } from '../../../../../assets/home/triggers/rocket.svg'
import { ReactComponent as TriggerStar } from '../../../../../assets/home/triggers/star.svg'
import { useT } from '../../../../../lib/i18n'
import css from './index.module.scss'

export const ClientsPageTriggers = () => {
  const { t } = useT('clientsPageTriggers')
  return (
    <div className={css.triggers}>
      <div className={css.wrapper}>
        <div className={css.items}>
          {[
            { texts: t('startup', { returnObjects: true }), Icon: TriggerRocket },
            { texts: t('money', { returnObjects: true }), Icon: TriggerPig },
            { texts: t('quality', { returnObjects: true }), Icon: TriggerStar },
            { texts: t('teach', { returnObjects: true }), Icon: TriggerHat },
          ].map(({ texts, Icon }, i) => (
            <div className={css.item} key={i}>
              <Icon className={css.icon} />
              <h3 className={css.title}>{texts.title}</h3>
              <p className={css.text}>{texts.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
