import { canConsulting } from '@svag/backend/src/services/user/can'
import { Alert } from '../../../../components/ui/Alert'
import { Loader } from '../../../../components/ui/Loader'
import { Segment } from '../../../../components/ui/Segment'
import { useMe } from '../../../../lib/ctx'
import { useT } from '../../../../lib/i18n'
import { trpc } from '../../../../lib/trpc'
import { ConsultationsPageInstances } from '../ConsultationsPageInstances'

export const Component = () => {
  const { t } = useT('consultationsPageConsultantInactive')
  const queryResult = trpc.getConsultationInstancesForConsultant.useInfiniteQuery(
    {
      limit: 9,
      active: false,
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage.nextCursor
      },
    }
  )
  return (
    <Segment size="M" title={t('title')}>
      {queryResult.isLoading ? (
        <Loader type="section" />
      ) : queryResult.error ? (
        <Alert color="red">{queryResult.error.message}</Alert>
      ) : queryResult.data?.pages[0].consultationInstances.length ? (
        <ConsultationsPageInstances queryResult={queryResult} />
      ) : (
        <Alert color="brown">{t('noConsultations')}</Alert>
      )}
    </Segment>
  )
}

export const ConsultationsPageConsultantInactive = () => {
  const me = useMe()
  if (!canConsulting(me)) {
    return null
  }
  return <Component />
}
