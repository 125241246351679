import type { TrpcRouterOutput } from '@svag/backend/src/router'
import React from 'react'
import { Button, Buttons } from '../../../../../components/ui/Button'
import { Card } from '../../../../../components/ui/Card'
import { useT } from '../../../../../lib/i18n'
import { getConsultationRoute } from '../../../../../lib/routes'
import {
  ConsultationPageInstanceInfo,
  useGetConsultationInstanceInfoWithCurrency,
} from '../../../ConsultationPageInstanceInfo'

export const ConsultationsPageConsultantInstance = ({
  setRef,
  consultationInstance,
}: {
  setRef: React.RefCallback<any>
  consultationInstance: TrpcRouterOutput['getConsultationInstancesForAttendee']['consultationInstances'][number]
}) => {
  const { t } = useT('consultationsPageConsultantInstance')
  const { t: tg } = useT('general')
  const { getConsultationInstanceInfoWithCurrency } = useGetConsultationInstanceInfoWithCurrency()
  return (
    <Card
      setRef={setRef}
      title={`${consultationInstance.title} #${consultationInstance.serialNumber}`}
      hintColor={consultationInstance.direction}
      hintIconName="consultation"
      hintText={t('hintPrefix') + ' / ' + tg(`directions.${consultationInstance.direction}`)}
      content={
        <ConsultationPageInstanceInfo
          size="s"
          info={getConsultationInstanceInfoWithCurrency({
            consultationInstance,
            pickKeys: ['status', 'attendee', 'happendAt', 'scheduledAt', 'prepay', 'afterpay', 'price'],
          })}
        />
      }
      buttons={
        <Buttons>
          <Button
            to={getConsultationRoute({
              consultationInstanceSerialNumber: consultationInstance.serialNumber.toString(),
            })}
            color="brandAccentLight"
          >
            {t('detailsButton')}
          </Button>
        </Buttons>
      }
    />
  )
}
