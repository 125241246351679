import { Alert } from '../../../../components/ui/Alert'
import { Loader } from '../../../../components/ui/Loader'
import { Segment } from '../../../../components/ui/Segment'
import { useT } from '../../../../lib/i18n'
import { trpc } from '../../../../lib/trpc'
import { ConsultationsPageInstances } from '../ConsultationsPageInstances'

export const ConsultationsPageAttendeeActive = () => {
  const { t } = useT('consultationsPageAttendeeActive')
  const queryResult = trpc.getConsultationInstancesForAttendee.useInfiniteQuery(
    {
      limit: 9,
      active: true,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
    }
  )

  return (
    <Segment size="M" title={t('title')}>
      {queryResult.isLoading ? (
        <Loader type="section" />
      ) : queryResult.error ? (
        <Alert color="red">{queryResult.error.message}</Alert>
      ) : queryResult.data?.pages[0].consultationInstances.length ? (
        <ConsultationsPageInstances queryResult={queryResult} />
      ) : (
        <Alert color="brown">{t('noActiveConsultations')}</Alert>
      )}
    </Segment>
  )
}
