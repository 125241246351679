import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const scrollToHash = (hash: string, event?: Event) => {
  if (event) {
    event.preventDefault()
  }
  const el = document.querySelector(hash)
  const scrollWrapperEl = document.querySelector('#scroll-wrapper')
  const headerDesktopParent = document.querySelector<HTMLElement>('#header-desktop-parent')
  const headerDesktopPanel = document.querySelector<HTMLElement>('#header-desktop-panel')
  const headerMobilePanel = document.querySelector<HTMLElement>('#header-mobile-panel')
  const isHeaderDesktopVisible = headerDesktopParent && window.getComputedStyle(headerDesktopParent).display !== 'none'
  const headerHeight = isHeaderDesktopVisible
    ? headerDesktopPanel?.offsetHeight || 0
    : headerMobilePanel?.offsetHeight || 0

  if (el) {
    if (scrollWrapperEl) {
      scrollWrapperEl.scrollTo({
        top: el.getBoundingClientRect().top + window.pageYOffset - headerHeight - 20,
        behavior: 'smooth',
      })
    } else {
      window.scrollTo({
        top: el.getBoundingClientRect().top + window.pageYOffset - headerHeight - 20,
        behavior: 'smooth',
      })
    }
  }
  return false
}

const ScrollToHash = () => {
  const { hash, pathname } = useLocation()

  useEffect(() => {
    if (hash) {
      scrollToHash(hash)
      setTimeout(() => {
        scrollToHash(hash)
      }, 1000)
    }
  }, [hash, pathname])

  return null
}

;(window as any).scrollToHash = scrollToHash

export default ScrollToHash
