import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { notify } from '../../../lib/notify'

export const UrlParamsMessage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchParamsObject = Object.fromEntries(searchParams.entries())
  useEffect(() => {
    const errorMessage = searchParamsObject.errorMessage
    const successMessage = searchParamsObject.successMessage
    if (errorMessage || successMessage) {
      setSearchParams((sp) => {
        sp.delete('errorMessage')
        sp.delete('successMessage')
        return sp
      })
    }
    if (errorMessage) {
      notify({
        type: 'error',
        message: errorMessage,
        duration: 5000,
      })
    }
    if (successMessage) {
      notify({
        type: 'success',
        message: successMessage,
        duration: 5000,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  return null
}
