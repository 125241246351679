import css from './index.module.scss'

export const EdDevPromoJTMPageProblem = () => {
  return (
    <>
      <div className={css.problemSection}>
        <div className={css.wrapper}>
          <div className={css.sectionHeader}>
            <h1 className={css.sectionTitle}>
              <span className={css.sectionTitleTextX}>
                <b>Проблемы</b> каждого джуна
              </span>
            </h1>
          </div>
          <div className={css.items}>
            <div className={css.problemOuter}>
              <div className={css.problem}>
                <b>Вы не умеете создавать проекты с нуля,</b> и на работе таких задач вам не доверяют, а значит вы и не
                научитесь
              </div>
            </div>
            <div className={css.problemOuter}>
              <div className={css.problem}>
                <b>Вы не учитесь писать хороший код,</b> а учитесь поддерживать чужой код, который не факт что хороший,
                а значит писать хороший код вы и не научитесь
              </div>
            </div>
            <div className={css.problemOuter}>
              <div className={css.problem}>
                <b>Вы не знаете какой стек технологий, библиотек, и подходов самый эффективный,</b> и узнаете это только
                перепробовав огромное множество комбинаций, зря потратив 90% времени
              </div>
            </div>
            <div className={css.problemOuter}>
              <div className={css.problem}>
                Если вы будете просто работать в надежде стать однажды хотя бы мидлом,{' '}
                <b>вы станете мидлом лет через 5</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={css.solutionSection}>
        <div className={css.wrapper}>
          <div className={css.sectionHeader}>
            <h1 className={css.sectionTitle}>
              <span className={css.sectionTitleTextX}>
                Есть решение, как стать мидлом за пол года, и сеньором за два года:{' '}
                <b>придумайте продукт и сделайте его с нуля!</b>
              </span>
            </h1>
          </div>
          <div className={css.items}>
            <div className={css.solutionOuter}>
              <div className={css.solution}>
                <b>Тот, кто смог создать полноценный продукт с нуля — мидл.</b> Тот, кто создал хотя бы три проекта —
                сеньор. Ниже на сайте будет пример вашего будущего резюме. Увидите — убедитесь.
              </div>
            </div>
            <div className={css.solutionOuter}>
              <div className={css.solution}>
                <b>Пол года разработки с нуля дают больше опыта, чем годы поддержки чужого кода.</b> Ведь вы решите
                огромное количество реальных проблем, и само это достижение будет определять вас минимум как мидла.
              </div>
            </div>
            <div className={css.solutionOuter}>
              <div className={css.solution}>
                <b>Изучите Typescript, React.js, Node.js, PostgreSQL и прочие технологии в разы быстрее.</b> один раз
                подняв проект с нуля от и до, вы реально поймёте как всё работает. Так проще быстрее изучать новые
                технологии.
              </div>
            </div>
            <div className={css.solutionOuter}>
              <div className={css.solution}>
                <b>Научитесь писать хороший код.</b> Код, который вы видели раньше был кем-то написан, дописан,
                переписан и превратился во что попало. <b>С нуля вы создадите сразу отличный код, ведь я вам помогу!</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
