import type { TrpcRouterOutput } from '@svag/backend/src/router'
import cn from 'classnames'
import { useState } from 'react'
import { useT } from '../../../../lib/i18n'
import { Alert } from '../../../ui/Alert'
import { Cards, type GetColumnsCountType } from '../../../ui/Card'
import { Icon } from '../../../ui/Icon'
import { ConsultationCard } from '../ConsultationCard'
import { GroupCard } from '../GroupCard'
import { GuideCard } from '../GuideCard'
import css from './index.module.scss'

export type EdItem = TrpcRouterOutput['getEdItems']['edItems'][number]

export const EdCard = ({ edItem, setRef }: { edItem: EdItem; setRef: React.RefCallback<any> }) => {
  if (edItem.type === 'guide') {
    return <GuideCard setRef={setRef} {...edItem} />
  }
  if (edItem.type === 'group') {
    return <GroupCard setRef={setRef} {...edItem} />
  }
  if (edItem.type === 'consultation') {
    return <ConsultationCard setRef={setRef} {...edItem} />
  }
  return null
}

export const EdCards = ({
  edItems,
  getColumnsCount,
  className,
}: {
  edItems: EdItem[]
  getColumnsCount?: GetColumnsCountType<any>
  className?: string
}) => {
  const { t } = useT('edCard')
  return (
    <div className={cn(css.edCards, className)}>
      {edItems.length ? (
        <Cards
          items={edItems}
          itemKeyKey="id"
          cardRender={({ item: edItem, setItemRef }) => <EdCard setRef={setItemRef} edItem={edItem} />}
          getColumnsCount={getColumnsCount}
        />
      ) : (
        <Alert color="brown">{t('noAvailable')}</Alert>
      )}
    </div>
  )
}

export const EdCardsWithFilter = ({
  edItems,
  getColumnsCount,
  showTypeFilter = true,
}: {
  edItems: EdItem[]
  getColumnsCount?: GetColumnsCountType<any>
  showTypeFilter?: boolean
}) => {
  const { t } = useT('edCard')
  const { t: tg } = useT('general')
  const existingTypes = edItems.reduce<Array<EdItem['type']>>((acc, edItem) => {
    if (!acc.includes(edItem.type)) {
      acc.push(edItem.type)
    }
    return acc
  }, [])
  const existingDirections = edItems.reduce<Array<EdItem['direction']>>((acc, edItem) => {
    if (!acc.includes(edItem.direction)) {
      acc.push(edItem.direction)
    }
    return acc
  }, [])
  const [selectedDirectionFilter, setSelectedDirectionFilter] = useState<EdItem['direction'] | null>(null)
  const [selectedTypeFilter, setSelectedTypeFilter] = useState<EdItem['type'] | null>(null)
  const filteredEdItems = edItems.filter((edItem) => {
    if (selectedDirectionFilter && edItem.direction !== selectedDirectionFilter) {
      return false
    }
    if (selectedTypeFilter && edItem.type !== selectedTypeFilter) {
      return false
    }
    return true
  })
  const showTypeFilterHere = showTypeFilter && existingTypes.length > 1
  const showDirectionFilterHere = existingDirections.length > 1
  const showFilter = showTypeFilterHere || showDirectionFilterHere
  return (
    <div className={css.edCardsWithFilter}>
      {showFilter && (
        <div className={css.filters}>
          {showDirectionFilterHere && (
            <div className={cn(css.filter, css.filterDirection)}>
              {existingDirections.map((direction) => (
                <button
                  onClick={() => {
                    if (direction === selectedDirectionFilter) {
                      setSelectedDirectionFilter(null)
                    } else {
                      setSelectedDirectionFilter(direction)
                    }
                  }}
                  key={direction}
                  className={cn({
                    [css.filterItem]: true,
                    [css.selected]: direction === selectedDirectionFilter,
                    [css[direction]]: true,
                  })}
                >
                  <span className={css.filterItemName}>{tg(`directions.${direction}`)}</span>
                </button>
              ))}
            </div>
          )}
          {showTypeFilterHere && (
            <div className={cn(css.filter, css.filterType)}>
              {existingTypes.map((type) => (
                <button
                  onClick={() => {
                    if (type === selectedTypeFilter) {
                      setSelectedTypeFilter(null)
                    } else {
                      setSelectedTypeFilter(type)
                    }
                  }}
                  key={type}
                  className={cn({ [css.filterItem]: true, [css.selected]: type === selectedTypeFilter })}
                >
                  <Icon className={css.filterItemIcon} name={type} />
                  <span className={css.filterItemName}>{t(`types.${type}`)}</span>
                </button>
              ))}
            </div>
          )}
        </div>
      )}
      <div className={css.edCardsPlace}>
        <EdCards getColumnsCount={getColumnsCount} edItems={filteredEdItems} />
      </div>
    </div>
  )
}
