import { useState } from 'react'
import { useMe } from '../../../lib/ctx'
import { useForm } from '../../../lib/form'
import { useT } from '../../../lib/i18n'
import { getEditProfileRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import { FormItems } from '../../form/FormItems'
import { Alert, type AlertProps } from '../../ui/Alert'
import { Button, Buttons } from '../../ui/Button'
import { Segment, type SegmentProps } from '../../ui/Segment'
import css from './index.module.scss'

const Resend = () => {
  const { t } = useT('confirmEmailBlocker')
  const resendEmailConfirmation = trpc.resendEmailConfirmation.useMutation()
  const [sent, setSent] = useState(false)
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {},
    onSubmit: async () => {
      await resendEmailConfirmation.mutateAsync()
      setSent(true)
      setTimeout(() => {
        setSent(false)
      }, 10000)
    },
    successMessage: t('resend.successMessage'),
    successMessageDuration: false,
    resetOnSuccess: false,
  })
  return (
    <form onSubmit={formik.handleSubmit}>
      <FormItems>
        <Alert {...alertProps} />
        {!sent && (
          <Button type="submit" {...buttonProps}>
            {t('resend.button')}
          </Button>
        )}
      </FormItems>
    </form>
  )
}

const Check = () => {
  const { t } = useT('confirmEmailBlocker')
  const checkEmailConfirmation = trpc.checkEmailConfirmation.useMutation()
  const trpcUtils = trpc.useContext()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {},
    onSubmit: async () => {
      await checkEmailConfirmation.mutateAsync()
      await trpcUtils.invalidate()
    },
    successMessage: false,
    successMessageDuration: false,
    resetOnSuccess: false,
  })
  return (
    <form onSubmit={formik.handleSubmit}>
      <FormItems>
        <Alert {...alertProps} />
        <Button type="submit" {...buttonProps}>
          {t('check.button')}
        </Button>
      </FormItems>
    </form>
  )
}

export const ConfirmEmailBlocker = ({
  alertMessage,
  segmentSize,
}: {
  alertMessage?: AlertProps['message']
  segmentSize?: SegmentProps['size']
}) => {
  const { t } = useT('confirmEmailBlocker')
  const me = useMe()
  if (!me) {
    return null
  }
  return (
    <>
      {!!alertMessage && (
        <div className={css.alert}>
          <Alert color="brown">{alertMessage}</Alert>
        </div>
      )}
      <Segment
        size={segmentSize}
        title={t('segment.title')}
        desc={
          <>
            {t('segment.desc.part1')} <span style={{ wordBreak: 'break-word' }}>«{me.email}»</span>{' '}
            {t('segment.desc.part2')}
          </>
        }
      >
        <FormItems>
          <Resend />
          <Buttons>
            <Button to={getEditProfileRoute({ anchor: 'email' })} color="brandAccent">
              {t('segment.changeEmailButton')}
            </Button>
          </Buttons>
          <Check />
        </FormItems>
      </Segment>
    </>
  )
}
