import { zCurrency } from '@svag/shared/src/currency'
import { zLang } from '@svag/shared/src/lang'
import { zEmailRequired, zStringOptional, zStringRequired } from '@svag/shared/src/zod'
import { z } from 'zod'

export const zSignUpInput = z.object({
  name: zStringRequired,
  country: zStringOptional,
  email: zEmailRequired,
  phone: zStringRequired,
  password: zStringOptional,
  verificationCode: zStringRequired.nullable(),
  lang: zLang,
  currency: zCurrency,
  interests: zStringRequired.array().optional(),
})
