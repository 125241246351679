import { useStore } from '@nanostores/react'
import type { TrpcRouterOutput } from '@svag/backend/src/router'
import { atom } from 'nanostores'
import { useEffect } from 'react'
import { Alert } from '../components/ui/Alert'
import { Loader } from '../components/ui/Loader'
import { trpc } from './trpc'

export type AppContext = {
  loading: boolean
  me: TrpcRouterOutput['getMe']['me']
  client: TrpcRouterOutput['getClient']['client']
  config: TrpcRouterOutput['getConfig']['config']
}

export const appContextStore = atom<AppContext>({
  loading: true,
  me: null,
  client: {
    ip: null,
    country: null,
    lang: 'en',
  },
  config: {
    rubInUsd: 100,
    now: new Date(),
    nowISO: new Date().toISOString(),
  },
})

export const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [getMeQueryResult, getClientQueryResult, getConfigQueryResult] = trpc.useQueries((t) => [
    t.getMe(undefined, { refetchInterval: 10000 }),
    t.getClient(),
    t.getConfig(),
  ])
  const appContext = useAppContext()
  useEffect(() => {
    if (getMeQueryResult.data && getClientQueryResult.data && getConfigQueryResult.data) {
      appContextStore.set({
        loading: false,
        me: getMeQueryResult.data.me,
        client: getClientQueryResult.data.client,
        config: getConfigQueryResult.data.config,
      })
    }
  }, [getMeQueryResult.data, getClientQueryResult.data, getConfigQueryResult.data])
  const error = getMeQueryResult.error || getClientQueryResult.error || getConfigQueryResult.error
  return (
    <>
      {error ? <Alert color="red" message={error.message} /> : appContext.loading ? <Loader type="page" /> : children}
    </>
  )
}

export const useAppContext = () => {
  return useStore(appContextStore)
}

export const useMe = () => {
  const { me } = useAppContext()
  return me
}

export const useClient = () => {
  const { client } = useAppContext()
  return client
}

export const useConfig = () => {
  const { config } = useAppContext()
  return config
}
