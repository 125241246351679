import { AnimatedStars, IconStar } from '../../../../../components/ui/AnimatedStars'
import { useT } from '../../../../../lib/i18n'
import css from './index.module.scss'

export const SpecialistsPagePlan = () => {
  const { t } = useT('specialistsPagePlan')
  return (
    <div className={css.plan} id="plan">
      <div className={css.wrapper}>
        <h2 className={css.sectionTitle}>
          <span className={css.sectionTitleText}>{t('title')}</span>
          <AnimatedStars className={css.animatedStars} visibleClassName={css.visible}>
            <IconStar />
            <IconStar />
            <IconStar />
          </AnimatedStars>
        </h2>
        <div className={css.text} dangerouslySetInnerHTML={{ __html: t('text') }} />
      </div>
    </div>
  )
}
