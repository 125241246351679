import { type CardProps } from '../../../ui/Card'
import { type EdItem } from '../EdCard'
import { GroupCardActiveStudent } from './GroupCardActiveStudent'
import { GroupCardDefault } from './GroupCardDefault'
import { GroupCardTeacher } from './GroupCardTeacher'

export const GroupCard = ({ setRef, ...restProps }: Extract<EdItem, { type: 'group' }> & Pick<CardProps, 'setRef'>) => {
  if (restProps.isMeActiveStudent) {
    return <GroupCardActiveStudent setRef={setRef} groupInstance={restProps} />
  }
  if (restProps.isMeTeacher) {
    return <GroupCardTeacher setRef={setRef} groupInstance={restProps} />
  }
  return <GroupCardDefault setRef={setRef} groupInstance={restProps} />
}
