import { HelmetProvider } from 'react-helmet-async'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AuthModal } from './components/auth/Auth'
import { PurchaseProductModal } from './components/billing/PurchaseProduct'
import { DashboardLayout } from './components/layouts/DashboardLayout'
import { GuideLayout } from './components/layouts/GuideLayout'
import { CopyCode } from './components/other/CopyCode'
import { NotAuthRouteTracker } from './components/other/NotAuthRouteTracker'
import ScrollToHash from './components/other/ScrollToHash'
import { ScrollTopRouter } from './components/other/ScrollTopRouter'
import { UrlParamsMessage } from './components/other/UrlParamsMessage'
import { NotFoundPageComponent } from './components/pages/NotFoundPageComponent'
import { Loader } from './components/ui/Loader'
import { AppContextProvider } from './lib/ctx'
import { CurrencyNormalizer } from './lib/currency'
import { LangNormalizer } from './lib/i18n'
import { MixpanelUser, TrackClick, TrackVisitPage, UntrackedEvents } from './lib/mixpanel'
import * as routes from './lib/routes'
import { SentryUser } from './lib/sentry'
import { TrpcProvider } from './lib/trpc'
import { UtmsWatcher } from './lib/utm'
import { DashboardPage } from './pages/account/DashboardPage'
import { EditProfilePage } from './pages/account/EditProfilePage'
import { EducationPage } from './pages/account/EducationPage'
import { GuidesPage } from './pages/account/GuidesPage'
import { ArticlePage } from './pages/articles/ArticlePage'
import { ConfirmEmailPage } from './pages/auth/ConfirmEmailPage'
import { ConfirmNewEmailPage } from './pages/auth/ConfirmNewEmailPage'
import { ResetPasswordPage } from './pages/auth/ResetPasswordPage'
import { RestorePasswordPage } from './pages/auth/RestorePasswordPage'
import { SignInPage } from './pages/auth/SignInPage'
import { SignOutPage } from './pages/auth/SignOutPage'
import { SignUpPage } from './pages/auth/SignUpPage'
import { ReturnPawPage } from './pages/billing/ReturnPawPage'
import { ConsultationPage } from './pages/consultation/ConsultationPage'
import { ConsultationsPage } from './pages/consultation/ConsultationsPage'
import { NewConsultationPage } from './pages/consultation/NewConsultationPage'
import { PolicyPage } from './pages/docs/PolicyPage'
import { TermsPage } from './pages/docs/TermsPage'
import { TeamPage } from './pages/general/TeamPage'
import { UserPage } from './pages/general/UserPage'
import { GroupPage } from './pages/group/GroupPage'
import { GroupsPage } from './pages/group/GroupsPage'
import { NewGroupStudentPage } from './pages/group/NewGroupStudentPage'
import { GuideHomePage } from './pages/guide/GuideHomePage'
import { GuideLessonCommitsPage } from './pages/guide/GuideLessonCommitsPage'
import { GuideLessonPage } from './pages/guide/GuideLessonPage'
import { GuidePlansPage } from './pages/guide/GuidePlansPage'
import { GuideSourceCodePage } from './pages/guide/GuideSourceCodePage'
import { EdDevPromoJTMPage } from './pages/promo/courses/dev-web/EdDevPromoJTMPage'
import { EdDevPromoJTMShortPage } from './pages/promo/courses/dev-web/EdDevPromoJTMShortPage'
import { EdDevPromoNewbiePage } from './pages/promo/courses/dev-web/EdDevPromoNewbiePage'
import { EdDevPromoPage } from './pages/promo/courses/dev-web/EdDevPromoPage'
import { ClientsPromoPage } from './pages/promo/general/ClientsPromoPage'
import { HomePromoPage } from './pages/promo/general/HomePromoPage'
import { SpecialistsPromoPage } from './pages/promo/general/SpecialistsPromoPage'
import './styles/global.scss'

export const App = () => (
  <TrpcProvider>
    <HelmetProvider>
      <Toaster />
      <AppContextProvider>
        <BrowserRouter>
          <LangNormalizer>
            <CurrencyNormalizer>
              <UrlParamsMessage />
              <UtmsWatcher />
              <TrackClick />
              <TrackVisitPage />
              <ScrollTopRouter />
              <SentryUser />
              <MixpanelUser />
              <UntrackedEvents />
              <NotAuthRouteTracker />
              <AuthModal />
              <PurchaseProductModal />
              <ScrollToHash />
              <CopyCode />
              <Routes>
                <Route path={routes.getHomeRoute.definition} element={<HomePromoPage />} />
                <Route path={routes.getSpecialistsPromoRoute.definition} element={<SpecialistsPromoPage />} />
                <Route path={routes.getClientsPromoRoute.definition} element={<ClientsPromoPage />} />
                <Route path={routes.getEdDevPromoRoute.definition} element={<EdDevPromoPage />} />
                <Route path={routes.getEdDevPromoNewbieRoute.definition} element={<EdDevPromoNewbiePage />} />
                <Route path={routes.getEdDevPromoJTMRoute.definition} element={<EdDevPromoJTMPage />} />
                <Route path={routes.getEdDevPromoJTMShortRoute.definition} element={<EdDevPromoJTMShortPage />} />

                <Route path={routes.getUserRoute.definition} element={<UserPage />} />
                <Route path={routes.getTeamRoute.definition} element={<TeamPage />} />

                <Route path={routes.articleRouteGroup.definition} element={<DashboardLayout showSidebar={false} />}>
                  <Route path={routes.getArticleRoute.definition} element={<ArticlePage />} />
                </Route>

                <Route path={routes.authRouteGroup.definition} element={<DashboardLayout showSidebar={false} />}>
                  <Route path={routes.getSignInRoute.definition} element={<SignInPage />} />
                  <Route path={routes.getSignUpRoute.definition} element={<SignUpPage />} />
                  <Route path={routes.getRestorePasswordRoute.definition} element={<RestorePasswordPage />} />
                  <Route path={routes.getResetPasswordRoute.definition} element={<ResetPasswordPage />} />
                  <Route path={routes.getEmailConfirmationRoute.definition} element={<ConfirmEmailPage />} />
                  <Route path={routes.getNewEmailConfirmationRoute.definition} element={<ConfirmNewEmailPage />} />
                  <Route path={routes.getSignUpRoute.definition} element={<SignUpPage />} />
                  <Route path="*" element={<NotFoundPageComponent />} />
                </Route>

                <Route path={routes.accountRouteGroup.definition} element={<DashboardLayout />}>
                  <Route path={routes.getEditProfileRoute.definition} element={<EditProfilePage />} />
                  <Route path={routes.getDashboardRoute.definition} element={<DashboardPage />} />
                  <Route path={routes.getEducationRoute.definition} element={<EducationPage />} />
                  <Route path={routes.getGroupsRoute.definition} element={<GroupsPage />} />
                  <Route path={routes.getGroupsRoute.definition} element={<GroupPage />} />
                  <Route path={routes.getNewGroupStudentRoute.definition} element={<NewGroupStudentPage />} />
                  <Route path={routes.getConsultationsRoute.definition} element={<ConsultationsPage />} />
                  <Route path={routes.getConsultationRoute.definition} element={<ConsultationPage />} />
                  <Route path={routes.getNewConsultationRoute.definition} element={<NewConsultationPage />} />
                  <Route path={routes.getGuidesRoute.definition} element={<GuidesPage />} />
                  <Route path={routes.getGroupRoute.definition} element={<GroupPage />} />
                  {/* <Route path={routes.getCommuinityRoute.definition} element={<CommuinityPage />} /> */}
                  <Route path="*" element={<NotFoundPageComponent />} />
                  <Route path="" element={<NotFoundPageComponent />} />
                </Route>

                <Route path={routes.guidesRouteGroup.definition} element={<GuideLayout />}>
                  <Route path={routes.getGuideHomeRoute.definition} element={<GuideHomePage />} />
                  <Route path={routes.getGuideLessonRoute.definition} element={<GuideLessonPage />} />
                  <Route path={routes.getGuideSourceCodeRoute.definition} element={<GuideSourceCodePage />} />
                  <Route path={routes.getGuidePlansRoute.definition} element={<GuidePlansPage />} />
                  <Route path="*" element={<NotFoundPageComponent />} />
                  <Route path="" element={<NotFoundPageComponent />} />
                </Route>

                <Route
                  path={routes.billingReturnRouteGroup.definition}
                  element={<DashboardLayout showSidebar={false} />}
                >
                  <Route path={routes.getReturnPawRoute.definition} element={<ReturnPawPage />} />
                  <Route path="*" element={<NotFoundPageComponent />} />
                  <Route path="" element={<NotFoundPageComponent />} />
                </Route>

                <Route path={routes.docsRouteGroup.definition} element={<DashboardLayout showSidebar={false} />}>
                  <Route path={routes.getTermsRoute.definition} element={<TermsPage />} />
                  <Route path={routes.getPolicyRoute.definition} element={<PolicyPage />} />
                  <Route path="*" element={<NotFoundPageComponent />} />
                  <Route path="" element={<NotFoundPageComponent />} />
                </Route>

                <Route path="/:lang" element={<DashboardLayout showSidebar={false} />}>
                  <Route path={routes.getGuideLessonCommitsRoute.definition} element={<GuideLessonCommitsPage />} />
                  <Route path={routes.getSignOutRoute.definition} element={<SignOutPage />} />
                  <Route path="*" element={<NotFoundPageComponent />} />
                </Route>

                <Route path="/" element={<DashboardLayout showSidebar={false} />}>
                  <Route path="*" element={<Loader type="page" />} />
                </Route>
              </Routes>
            </CurrencyNormalizer>
          </LangNormalizer>
        </BrowserRouter>
      </AppContextProvider>
    </HelmetProvider>
  </TrpcProvider>
)
