import { EdCards, type EdItem } from '../../../components/education/cards/EdCard'
import { Alert } from '../../../components/ui/Alert'
import { Segment, Segments } from '../../../components/ui/Segment'
import { useT } from '../../../lib/i18n'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { trpc } from '../../../lib/trpc'

export const GuidesPage = withPageWrapper({
  title: ({ t }) => t('guidesPage.title'),
  showLoaderOnFetching: false,
  useQuery: () => {
    return trpc.getEdItems.useQuery()
  },
  setProps: ({ queryResult }) => {
    return {
      edItems: queryResult.data.edItems,
    }
  },
  langs: ['ru', 'en'],
})(({ edItems }) => {
  const { t } = useT('guidesPage')
  const allGuides = edItems.filter((edItem) => edItem.type === 'guide') as Array<Extract<EdItem, { type: 'guide' }>>
  const myGuides = allGuides.filter((edItem) => !!edItem.startedByMeAt)
  const otherGuides = allGuides.filter((edItem) => !edItem.startedByMeAt)
  return (
    <Segment title={t('title')}>
      <Segments>
        <Segment size="M" title={t('myGuides.title')}>
          {myGuides.length ? <EdCards edItems={myGuides} /> : <Alert color="brown">{t('myGuides.alert')}</Alert>}
        </Segment>
        <Segment size="M" title={t('otherGuides.title')}>
          {otherGuides.length ? (
            <EdCards edItems={otherGuides} />
          ) : (
            <Alert color="brown">{t('otherGuides.alert')}</Alert>
          )}
        </Segment>
      </Segments>
    </Segment>
  )
})
