import cn from 'classnames'
import React from 'react'
import css from './index.module.scss'

export type AlertProps = {
  color: 'red' | 'green' | 'brown'
  title?: string | React.ReactNode
  message?: React.ReactNode
  className?: string
  children?: React.ReactNode
  buttons?: React.ReactNode
  hidden?: boolean
  hideBorder?: boolean
  fullWidth?: boolean
  html?: string
}

export const Alert: React.FC<AlertProps> = ({
  color,
  title,
  message,
  className,
  html,
  children,
  buttons,
  hidden,
  hideBorder = false,
  fullWidth = false,
  ...restProps
}) =>
  hidden ? null : (
    <div
      className={cn({
        [css.alertPlace]: true,
        [css.fullWidth]: !!fullWidth,
      })}
    >
      <div
        className={cn({
          [css.alert]: true,
          [css.hideBorder]: !!hideBorder,
          [css.fullWidth]: !!fullWidth,
          [css[`color-${color}`]]: true,
          [className || '']: !!className,
        })}
        {...restProps}
      >
        {!!title && <p className={css.title}>{title}</p>}
        {!!html && <div className={css.message} dangerouslySetInnerHTML={{ __html: html }} />}
        {(!!message || !!children) && <div className={css.message}>{message || children}</div>}
        {!!buttons && <p className={css.buttons}>{buttons}</p>}
      </div>
    </div>
  )
