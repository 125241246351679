import { useStore } from '@nanostores/react'
import { omit } from '@svag/shared/src/omit'
import { pick } from '@svag/shared/src/pick'
// import pick from 'lodash/pick'
import { action } from 'nanostores'
import { useCallback, useEffect, useMemo } from 'react'
import { cookiesAtom } from '../utils/cookiesNanostores'

type FlatRecordValue = undefined | null | string | number | Date
type DeepRecordValue = FlatRecordValue | FlatRecordValue[] | Record<string, FlatRecordValue>
type CacheRecord = Record<string, DeepRecordValue>
type Cache = Record<string, CacheRecord>

const defaultCache: Cache = {}

export const cacheStore = cookiesAtom<Cache>(
  'cacheStore',
  {},
  {
    encode(value) {
      return JSON.stringify(value)
    },
    decode(value) {
      try {
        return JSON.parse(value)
      } catch (err) {
        return defaultCache
      }
    },
  }
)

const updateCacheRecord = action(
  cacheStore,
  'updateCacheRecord',
  (
    store,
    {
      key,
      update,
    }: {
      key: string
      update: CacheRecord
    }
  ) => {
    const currentRecord = store.get()[key] || {}
    store.set({
      ...store.get(),
      [key]: {
        ...currentRecord,
        ...update,
      },
    })
  }
)

export const clearCacheRecord = action(
  cacheStore,
  'clearCacheRecord',
  (
    store,
    {
      key,
    }: {
      key: string
    }
  ) => {
    store.set({
      ...omit(store.get(), [key]),
    })
  }
)

export const useCacheRecord = <T extends CacheRecord>({ key, defaultValue }: { key: string; defaultValue: T }) => {
  const cache = useStore(cacheStore)
  useEffect(() => {
    if (!cache[key]) {
      updateCacheRecord({
        key,
        update: defaultValue,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key])
  useEffect(() => {
    if (!cache[key]) {
      return
    }
    const missingKeys = Object.keys(defaultValue).filter((defaultValueKey) => {
      return !(defaultValueKey in cache[key])
    })
    if (missingKeys.length) {
      updateCacheRecord({
        key,
        update: pick(defaultValue, missingKeys),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(defaultValue), JSON.stringify(cache[key])])
  const updateThisCacheRecord = useCallback(
    (update: CacheRecord) => {
      updateCacheRecord({
        key,
        update,
      })
    },
    [key]
  )
  const clearThisCacheRecord = useCallback(() => {
    clearCacheRecord({
      key,
    })
  }, [key])
  return {
    cacheRecord: (cache[key] || defaultValue) as T,
    updateCacheRecord: updateThisCacheRecord,
    clearCacheRecord: clearThisCacheRecord,
  }
}

export const useSyncCacheRecord = <T extends CacheRecord | undefined = undefined>({
  key,
  deps = [],
  onChange,
  ...restProps
}: {
  key: string
  deps?: any[]
  onChange?: (cacheRecord: T) => void | Promise<void>
} & (
  | {
      source: T
      toPickFromSource: Array<keyof T>
    }
  | {
      source?: T
      to: (source: T) => CacheRecord
    }
)) => {
  const toPickFromSource = 'toPickFromSource' in restProps ? restProps.toPickFromSource : undefined
  const source = 'source' in restProps ? restProps.source : undefined
  const to = 'to' in restProps ? restProps.to : undefined
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const recordData = useMemo(() => {
    if (toPickFromSource && source) {
      return pick(source, toPickFromSource)
    }
    if (to) {
      return to(source as T)
    }
    return {} as never
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(source), JSON.stringify(toPickFromSource), ...deps])
  useEffect(() => {
    updateCacheRecord({
      key,
      update: recordData,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordData, key, ...deps])
  useEffect(() => {
    if (onChange) {
      void onChange(recordData as T)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(recordData), ...deps])
}

const clearCache = action(cacheStore, 'clearCache', (store) => {
  store.set(defaultCache)
})

Object.assign(window, {
  clearCache,
})
