import type { TrpcRouterOutput } from '@svag/backend/src/router'
import { useEffect } from 'react'
import z from 'zod'
import { RadioButton, RadioButtons } from '../../../../components/form/RadioButton'
import { Segment } from '../../../../components/ui/Segment'
import { useForm } from '../../../../lib/form'
import { setGuideSettings, useGuideSettings } from '../../../../lib/guidesSettingsStore'
import { useT } from '../../../../lib/i18n'
import css from './index.module.scss'

export const GuideLessonCommits = ({
  commits,
  guideSlug,
}: {
  commits: NonNullable<TrpcRouterOutput['getStudentGuideLesson']['lesson']['commits']>
  guideSlug: string
}) => {
  const { t } = useT('guideLessonCommits')
  const guideSettings = useGuideSettings(guideSlug)
  useEffect(() => {
    const normalizeIframeHeights = () => {
      const iframes = document.querySelectorAll('iframe')
      for (const iframe of iframes) {
        const iframeUrl = iframe.src
        if (!iframeUrl) {
          continue
        }
        const iframeHost = new URL(iframeUrl).host
        const hostUrl = window.location.href
        const hostHost = new URL(hostUrl).host
        if (iframeHost !== hostHost) {
          continue
        }
        const height = iframe.contentWindow?.document?.documentElement?.offsetHeight
        if (height) {
          iframe.height = `${height + 5}px`
        }
      }
    }
    normalizeIframeHeights()
    const interval = setInterval(normalizeIframeHeights, 100)
    return () => {
      clearInterval(interval)
    }
  }, [])
  const { formik } = useForm({
    validationSchema: z.object({
      format: z.enum(['line', 'side', 'hidden']),
    }),
    initialValues: {
      format: guideSettings.commitsFormat,
    },
  })
  useEffect(() => {
    setGuideSettings(guideSlug, {
      commitsFormat: formik.values.format,
    })
  }, [formik.values.format, guideSlug])
  return (
    <Segment size="M" h={2} title={t('title')}>
      <div className={css.format}>
        <RadioButtons formik={formik} name="format" direction="column" label={t('format.display')}>
          <RadioButton formik={formik} label={t('format.line')} name="format" value="line" />
          <RadioButton formik={formik} label={t('format.side')} name="format" value="side" />
          <RadioButton formik={formik} label={t('format.hidden')} name="format" value="hidden" />
        </RadioButtons>
      </div>
      {formik.values.format !== 'hidden' && (
        <div className={css.commits}>
          {commits.map((commit) => (
            <iframe
              title={commit.message}
              key={commit.message}
              src={`/files/guides/${guideSlug}/diffs/${encodeURIComponent(commit.message)}.${
                formik.values.format
              }.html`}
              className={css.commit}
            />
          ))}
        </div>
      )}
    </Segment>
  )
}
