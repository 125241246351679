import { zCreateConsultationInstanceInput } from '@svag/backend/src/router/consultation/createConsultationInstance/input'
import { canConsulting } from '@svag/backend/src/services/user/can'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { DatePicker } from '../../../components/form/DatePicker'
import { FormItems } from '../../../components/form/FormItems'
import { Input } from '../../../components/form/Input'
import { Select } from '../../../components/form/Select'
import { Textarea } from '../../../components/form/Textarea'
import { Alert } from '../../../components/ui/Alert'
import { Button } from '../../../components/ui/Button'
import { Segment } from '../../../components/ui/Segment'
import { useMe } from '../../../lib/ctx'
import { useForm } from '../../../lib/form'
import { useT } from '../../../lib/i18n'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getConsultationRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'

export const NewConsultationPage = withPageWrapper({
  title: ({ t }) => t('newConsultationPage.title'),
  showLoaderOnFetching: false,
  useQuery: () => {
    const me = useMe()
    return trpc.getConsultationOffers.useQuery({
      consultantId: me?.id,
    })
  },
  checkAccess: ({ ctx }) => canConsulting(ctx.me),
  setProps: ({ queryResult }) => {
    return {
      consultationOffers: queryResult.data.consultationOffers,
    }
  },
  langs: ['ru', 'en'],
})(({ consultationOffers }) => {
  const { t } = useT('newConsultationPage')
  const navigate = useNavigate()
  const createConsultationInstance = trpc.createConsultationInstance.useMutation()
  const form = useForm({
    validationSchema: zCreateConsultationInstanceInput,
    initialValues: {
      title: '',
      desc: '',
      direction: 'other',
      consultationOfferId: '',
      attendeeEmail: '',
      scheduledAt: new Date(),
      happendAt: null,
      videoEmbedCode: '',
      notes: '',
      durationMinutes: null,
      priceUsd: 60,
      prepayRequiredAmountUsd: 0,
      prepayDeadlineAt: null,
      afterpayRequiredAmountUsd: 0,
    },
    onSubmit: async (values) => {
      const data = await createConsultationInstance.mutateAsync(values)
      navigate(
        getConsultationRoute({
          consultationInstanceSerialNumber: data.consultationInstance.serialNumber.toString(),
        })
      )
    },
    showValidationAlert: true,
    resetOnSuccess: false,
  })

  const consultationOffer = consultationOffers.find((offer) => offer.id === form.formik.values.consultationOfferId)
  useEffect(() => {
    if (!consultationOffer) {
      return
    }
    void form.formik.setFieldValue('title', consultationOffer.title)
    void form.formik.setFieldValue('priceUsd', consultationOffer.priceUsd)
    void form.formik.setFieldValue('direction', consultationOffer.direction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultationOffer])

  return (
    <Segment title={t('title')}>
      <form onSubmit={form.formik.handleSubmit}>
        <FormItems>
          {!!consultationOffers.length && (
            <Select
              formik={form.formik}
              name="consultationOfferId"
              label={t('consultationOffer')}
              options={[
                ...consultationOffers.map((offer) => ({
                  value: offer.id,
                  label: offer.title,
                })),
              ]}
            />
          )}
          <Input formik={form.formik} name="attendeeEmail" label={t('attendeeEmail')} />
          <Input formik={form.formik} name="priceUsd" label={t('pricePerHour')} />
          <Input formik={form.formik} name="title" label={t('title')} />
          <Input formik={form.formik} name="desc" label={t('description')} />
          <Select
            disabled={!!consultationOffer?.direction}
            formik={form.formik}
            name="direction"
            label={t('direction')}
            options={[
              { value: 'other', label: t('directionOther') },
              { value: 'development', label: t('directionDevelopment') },
              { value: 'design', label: t('directionDesign') },
              { value: 'productology', label: t('directionProductology') },
            ]}
          />
          <DatePicker formik={form.formik} name="scheduledAt" label={t('scheduledDate')} />

          <Input formik={form.formik} name="prepayRequiredAmountUsd" label={t('prepayAmount')} />
          <DatePicker formik={form.formik} name="prepayDeadlineAt" label={t('prepayDeadline')} />
          <Input formik={form.formik} name="afterpayRequiredAmountUsd" label={t('afterpayAmount')} />

          <DatePicker formik={form.formik} name="happendAt" label={t('happendDate')} />
          <Input formik={form.formik} name="durationMinutes" label={t('duration')} />
          <Textarea formik={form.formik} name="videoEmbedCode" label={t('videoEmbedCode')} maxWidth={800} />
          <Textarea formik={form.formik} name="notes" label={t('notes')} maxWidth={800} />
          <Alert {...form.alertProps} />
          <Button type="submit" {...form.buttonProps}>
            {t('create')}
          </Button>
        </FormItems>
      </form>
    </Segment>
  )
})
