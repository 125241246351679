import { Projects } from '../../../../../components/general/Projects'
import { AnimatedStars, IconStar } from '../../../../../components/ui/AnimatedStars'
import { useT } from '../../../../../lib/i18n'
import { trpc } from '../../../../../lib/trpc'
import css from './index.module.scss'

export const ClientsPageProjects = () => {
  const { t } = useT('clientsPageProjects')
  const qr = trpc.getProjects.useQuery({})
  if (!qr.data) {
    return null
  }
  return (
    <div className={css.projects} id="projects">
      <div className={css.wrapper}>
        <h2 className={css.sectionTitle}>
          <span className={css.sectionTitleText}>{t('title')}</span>
          <AnimatedStars className={css.animatedStars} visibleClassName={css.visible}>
            <IconStar />
            <IconStar />
            <IconStar />
          </AnimatedStars>
        </h2>
        <Projects projects={qr.data.projects} className={css.items} />
      </div>
    </div>
  )
}
