import { IntroLayout } from '../../../../components/layouts/IntroLayout'
import { withPageWrapper } from '../../../../lib/pageWrapper'
import { ClientsPageContacts } from './ClientsPageContacts'
import { ClientsPageIntro } from './ClientsPageIntro'
import { ClientsPageMembers } from './ClientsPageMembers'
import { ClientsPagePlan } from './ClientsPagePlan'
import { ClientsPageProjects } from './ClientsPageProjects'
import { ClientsPageTriggers } from './ClientsPageTriggers'

export const ClientsPromoPage = withPageWrapper({
  langs: ['ru', 'en'],
  showLoaderOnFetching: false,
})(() => {
  return (
    <IntroLayout>
      <ClientsPageIntro />
      <ClientsPageTriggers />
      <ClientsPagePlan />
      <ClientsPageMembers />
      <ClientsPageProjects />
      <ClientsPageContacts />
    </IntroLayout>
  )
})
