import type { User, UserPermission } from '@prisma/client'
import { TRPCError } from '@trpc/server'
import { type TRPC_ERROR_CODE_KEY } from '@trpc/server/dist/rpc'

type MaybeUser = Pick<User, 'permissions' | 'id'> | null
// type MaybeIdea = Pick<Idea, 'authorId'> | null

export const hasPermission = (user: MaybeUser, permission: UserPermission) => {
  return user?.permissions.includes(permission) || user?.permissions.includes('all') || false
}

export const canConsulting = (user: MaybeUser) => {
  return hasPermission(user, 'consulting')
}

export const canGrouping = (user: MaybeUser) => {
  return hasPermission(user, 'grouping')
}
new TRPCError({
  code: 'xx' as TRPC_ERROR_CODE_KEY,
})
// export const canEditIdea = (user: MaybeUser, idea: MaybeIdea) => {
//   return !!user && !!idea && user?.id === idea?.authorId
// }
