import cn from 'classnames'
import css from './index.module.scss'

export const Loader = ({ type, className }: { type: 'page' | 'section'; className?: string }) => (
  <span
    className={cn({
      [css.loader]: true,
      [css[`type-${type}`]]: true,
      [className || '']: !!className,
    })}
  />
)
