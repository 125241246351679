import { VideoKinescope } from '../../../../../../components/other/VideoKinescope'
import css from './index.module.scss'

export const EdDevPromoNewbiePageHow = () => {
  return (
    <div className={css.howSection}>
      <div className={css.wrapper}>
        <div className={css.sectionHeader}>
          <h1 className={css.sectionTitle}>
            <span className={css.sectionTitleText}>
              Как вам прямо сейчас начать создавать свой продукт с нуля, если вы ещё вообще не умеете программировать
            </span>
          </h1>
        </div>
        <div className={css.mainText}>
          <p>
            Выберите проект. Начните разработку по моему авторскому учебнику, где я объясню всё, что надо знать. А что
            не объясню подсмотрите в бесплатных учебниках представленных выше.
          </p>
          <p>
            Это работает, это эффективно. Это естественный процесс обучения — пытаться что-то сделать и для этого что-то
            учить. Так проще чем что-то бесконечно учить, чтобы однажды не понятно что сделать.
          </p>
          <p>
            Не бойтесь такого подхода, если хотите стать программистами. Действующие программисты в своей работе
            постоянно приступают к задачам, которые не знают как решить и разбираются по ходу. И так почти каждый день.
            Присоединяйтесь к нам 🙂
          </p>
        </div>

        <VideoKinescope
          className={css.videoPlace}
          videoId="67bd36c5-042b-401a-95e2-c37354dec616"
          width={560}
          height={364}
          eventElementGroup="edPromoVideo"
          eventElementId="edPromoHowVideo"
        />
      </div>
    </div>
  )
}
