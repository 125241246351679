import cn from 'classnames'
import { type FormikProps } from 'formik'
import React from 'react'
import css from './index.module.scss'

export const RadioButton: React.FC<{
  label: string
  formik: FormikProps<any>
  onChange?: Function
  name: string
  value: string
}> = ({ label, formik, name, onChange, value, ...restProps }) => {
  const formikValue = formik.values[name]
  return (
    <label className={css.radioButton}>
      <input
        type="radio"
        name={name}
        value={value}
        checked={value === formikValue}
        onChange={(...agrs) => {
          onChange?.(...agrs)
          formik.handleChange(...agrs)
        }}
        onBlur={formik.handleBlur}
        {...restProps}
      />{' '}
      {label}
    </label>
  )
}

export const RadioButtons: React.FC<{
  label?: string
  formik: FormikProps<any>
  name: string
  hint?: string
  children: React.ReactNode
  direction: 'row' | 'column'
}> = ({ label, formik, name, hint, children, direction = 'row', ...restProps }) => {
  const error = formik.errors[name]
  const touched = formik.touched[name]
  return (
    <div
      className={cn({
        [css.radioButtons]: true,
        [css[`direction-${direction}`]]: !!direction,
      })}
      {...restProps}
    >
      {!!label && <label className={css.label}>{label}</label>}
      <div className={css.items}>{children}</div>
      {!!error && touched && <p className={css.error}>{error as string}</p>}
      {!!hint && <p className={css.hint}>{hint}</p>}
    </div>
  )
}
