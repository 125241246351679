import { Link, useLocation, type LinkProps } from 'react-router-dom'

export const NavLink = ({
  className,
  to,
  ...restProps
}: Omit<LinkProps, 'className' | 'to'> & {
  to: string
  className?: (classNameProps: { isActive: boolean; isParent: boolean }) => string
}) => {
  const { pathname } = useLocation()
  const isActive = pathname === to
  const isParent = pathname.startsWith(to) && pathname !== to
  const classNameHere = className ? className({ isActive, isParent }) : ''
  return <Link className={classNameHere} to={to} {...restProps} />
}
