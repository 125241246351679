import type { TrpcRouterOutput } from '@svag/backend/src/router'
import React, { useEffect } from 'react'
import { type EdItem } from '../../../../../components/education/cards/EdCard'
import { DashboardLayout } from '../../../../../components/layouts/DashboardLayout'
import {
  GeneralLayout,
  GeneralLayoutNavItem,
  closeGeneralLayoutMenu,
} from '../../../../../components/layouts/GeneralLayout'
import { scrollToHash } from '../../../../../components/other/ScrollToHash'
import { Button } from '../../../../../components/ui/Button'
import { trackVisitEdPromo } from '../../../../../lib/mixpanel'
import { withPageWrapper } from '../../../../../lib/pageWrapper'
import { usePromoCodeValue } from '../../../../../lib/promoCode'
import { trpc } from '../../../../../lib/trpc'
import { EdDevPromoPageAbout } from '../EdDevPromoPage/EdDevPromoPageAbout'
import { EdDevPromoPageArchitecture } from '../EdDevPromoPage/EdDevPromoPageArchitecture'
import { EdDevPromoPagePlans } from '../EdDevPromoPage/EdDevPromoPagePlans'
import { EdDevPromoPageProgram } from '../EdDevPromoPage/EdDevPromoPageProgram'
import { EdDevPromoNewbiePageFree } from './EdDevPromoNewbiePageFree'
import { EdDevPromoNewbiePageHelp } from './EdDevPromoNewbiePageHelp'
import { EdDevPromoNewbiePageHow } from './EdDevPromoNewbiePageHow'
import { EdDevPromoNewbiePageProblem } from './EdDevPromoNewbiePageProblem'
import { EdDevPromoNewbiePageProduct } from './EdDevPromoNewbiePageProduct'
import { EdDevPromoNewbiePageResume } from './EdDevPromoNewbiePageResume'
import { EdDevPromoNewbiePageStack } from './EdDevPromoNewbiePageStack'
import { EdDevPromoNewbiePageStartegy } from './EdDevPromoNewbiePageStartegy'

const forcePromoCodeValue = 'NEWBIE123'

const Layout = ({
  children,
  guide,
}: {
  children: React.ReactNode
  guide: TrpcRouterOutput['getStudentGuideFull']['guide']
}) => {
  return (
    <GeneralLayout
      fullWidthContent
      // logoLinkPolicy="scrollToTop"
      logoLinkPolicy="navigateToHome"
      logoImagePolicy="education"
      showSidebar={false}
      parseWindowSize={(size) => ({
        isMobile: size.width <= 600,
        isDesktop: size.width > 600,
      })}
      // footerBackgroundColor="brandAccent"
      generalNavigationChildren={
        <>
          <GeneralLayoutNavItem>
            <Button
              color="brandAccentLight"
              onClick={() => {
                closeGeneralLayoutMenu()
                scrollToHash('#form')
              }}
              eventElementGroup="edPromoStartButton"
              eventElementId="edPromoMenuStartButton"
            >
              Начать учиться
            </Button>
          </GeneralLayoutNavItem>
        </>
      }
    >
      {children}
    </GeneralLayout>
  )
}

export const EdDevPromoNewbiePage = withPageWrapper({
  useQueries: () => {
    const { promoCodeValue } = usePromoCodeValue(forcePromoCodeValue)
    const queriesResults = trpc.useQueries((t) => [
      t.getStudentGuideFull({
        guideSlug: 'dev-web-iserdmi',
        promoCodeValue,
      }),
      t.getEdItems({
        ids: ['co-iserdmi-ed', 'group-dev-startup-iserdmi'],
      }),
    ])
    return queriesResults
  },
  title: ({ queriesResults }) => queriesResults[0].data?.guide?.title,
  eventsContext: ({ queriesResults }) => ({
    guide: queriesResults[0].data?.guide,
  }),
  ErrorLayout: DashboardLayout,
  setProps: ({ queriesResults }) => {
    const guide = queriesResults[0].data.guide
    const guideLessons = queriesResults[0].data.lessons
    const consultation = queriesResults[1].data.edItems.find((item) => item.id === 'co-iserdmi-ed') as Extract<
      EdItem,
      { type: 'consultation' }
    >
    const group = queriesResults[1].data.edItems.find((item) => item.id === 'group-dev-startup-iserdmi') as Extract<
      EdItem,
      { type: 'group' }
    >
    if (!guide || !consultation || !group) {
      throw new Error('Oops, error')
    }
    return {
      guide,
      guideLessons,
      consultation,
      group,
    }
  },
  langs: ['ru', 'en'],
  showLoaderOnFetching: false,
})(({ guide, guideLessons, consultation, group }) => {
  useEffect(() => {
    trackVisitEdPromo({
      target: 'junmid',
    })
  }, [])
  return (
    <Layout guide={guide}>
      <EdDevPromoNewbiePageProblem />
      <EdDevPromoNewbiePageFree />
      <EdDevPromoNewbiePageHow />
      <EdDevPromoNewbiePageProduct />
      <EdDevPromoNewbiePageHelp
        guide={guide}
        title="Помочь определиться с идеей продукта?"
        desc="Свяжитесь со мной, я с радостью помогу вам придумать и выбрать идею проекта"
      />
      <EdDevPromoNewbiePageStack />
      <EdDevPromoNewbiePageHelp
        guide={guide}
        title="Хотите использовать другой стек технологий?"
        desc="Свяжитесь со мной, обсудим на сколько это будет эффективно для поставленной задачи"
      />
      <EdDevPromoNewbiePageStartegy />
      <EdDevPromoPageAbout
        title="Теории учитесь бесплатно, а практие в моём курсе по фулстек-разработке веб-сервиса с нуля по видео-урокам с исходным кодом и поддержкой"
        guide={guide}
        consultation={consultation}
        group={group}
        hideButtons={true}
      />
      <EdDevPromoNewbiePageHelp
        guide={guide}
        title="Есть вопросы по формату обучения?"
        desc="Свяжитесь со мной, с радостью отвечу на все вопросы"
      />
      <EdDevPromoNewbiePageResume />
      <EdDevPromoPageProgram disableLinks={true} guide={guide} guideLessons={guideLessons} />
      <EdDevPromoPageArchitecture />
      <EdDevPromoPagePlans
        showBottomPadding={true}
        guide={guide}
        title="Начинаем обучение"
        forcePromoCodeValue={forcePromoCodeValue}
      />
      <EdDevPromoNewbiePageHelp
        hideBottomPadding={true}
        backgroundColor="systemPureWhite"
        guide={guide}
        title="Жалаю вам успехов!"
        desc="Я искренне желаю вам достичь ваших профессиональных успехов! Я буду рад помочь вам в этом и поддержать вас на пути к цели. Свяжитесь со мной, если вам нужна помощь в обучении или просто хотите познакомиться 🙂"
      />
    </Layout>
  )
})
