import cn from 'classnames'
import React from 'react'
import css from './index.module.scss'

export const LabelValueInfo = ({
  items,
  size,
}: {
  items: Array<{
    label: React.ReactNode
    value: React.ReactNode
  }>
  size: 's' | 'm'
}) => {
  return (
    <div className={cn({ [css.labelValueInfo]: true, [css[`size-${size}`]]: true })}>
      {items.map(({ label, value }, i) => (
        <div className={css.item} key={i}>
          <span className={css.label}>{label}:</span>
          <span className={css.value}>{value}</span>
        </div>
      ))}
    </div>
  )
}
