import css from './index.module.scss'

export const EdDevPromoNewbiePageProblem = () => {
  return (
    <>
      <div className={css.offerSection}>
        <div className={css.wrapper}>
          <div className={css.sectionHeader}>
            <h1 className={css.sectionTitle}>
              <span className={css.sectionTitleText}>
                Станьте востребованным фулстек тайпскрипт программистом, изучив программирование с нуля в обратном
                порядке
              </span>
            </h1>
          </div>
        </div>
      </div>
      <div className={css.problemSection}>
        <div className={css.wrapper}>
          <div className={css.sectionHeader}>
            <h1 className={css.sectionTitle}>
              <span className={css.sectionTitleTextX}>
                Если вы будете сначала <b>лишь изучать программирование,</b> а только потом применять знания в реальных
                проектах пройдёт <b>очень много времени</b> и может <b>не хватить мотивации</b> дойти до конца, тем
                более учитывая, что:
              </span>
            </h1>
          </div>
          <div className={css.items}>
            <div className={css.problemOuter}>
              <div className={css.problem}>
                Не понятно, какие именно технологии, фреймворки и библиотеки изучать, чтобы быть востребованным,
                учитывая что их бесконечно много и становится всё больше.
              </div>
            </div>
            <div className={css.problemOuter}>
              <div className={css.problem}>
                Не понятно, что надо выучить сразу, а что можно выучить в процессе работы. Чем определяется конец
                обучения?
              </div>
            </div>
            <div className={css.problemOuter}>
              <div className={css.problem}>
                Не понятно, как найти первую работу в условиях бешеной конкуренции без опыта работы. Как доказать
                работодателю, что вы лучше других новичков?
              </div>
            </div>
            <div className={css.problemOuter}>
              <div className={css.problem}>
                Не понятно, как эффективно выстроить процесс обучения, чтобы потратить на него как можно меньше времени
                и денег, и усвоить максимум материала
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={css.solutionSection}>
        <div className={css.wrapper}>
          <div className={css.sectionHeader}>
            <h1 className={css.sectionTitle}>
              <span className={css.sectionTitleTextX}>
                <b>Лучше обучайтесь бесплатно азам программирования</b> по направлениям JavaScript, TypeScript,
                React.js, вёрстка, Node.js, базы данных по подборке из 42 учебников и курсов в то время, как{' '}
                <b>будете создавать свой первый реальный фулстек проект</b>
              </span>
            </h1>
          </div>
          <div className={css.items}>
            <div className={css.solutionOuter}>
              <div className={css.solution}>
                <b>Учитесь не чтобы получить знания, а чтобы решить реальную задачу.</b> Изученное на практике
                запоминается, изученное ради теории забывается.
              </div>
            </div>
            <div className={css.solutionOuter}>
              <div className={css.solution}>
                <b>Изучите JavaScript, TypeScript, React.js, Node.js, PostgreSQL и прочие технологии в разы быстрее.</b>{' '}
                Один раз подняв проект с нуля от и до, вы реально поймёте как всё работает.
              </div>
            </div>
            <div className={css.solutionOuter}>
              <div className={css.solution}>
                <b>Пол года разработки с нуля дают больше знаний и опыта, чем банальное штудирование учебников.</b> Ведь
                вы решите огромное количество реальных проблем, и само это достижение будет определять вас минимум как
                мидла, даже не джуна.
              </div>
            </div>
            <div className={css.solutionOuter}>
              <div className={css.solution}>
                <b>Тот, кто смог создать полноценный продукт с нуля — уже даже не джун, а мидл.</b> Тот, кто создал
                хотя бы три проекта — сеньор. Ниже на сайте будет пример вашего будущего резюме. Увидите — убедитесь.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
