import {
  zDirection,
  zEmailRequired,
  zStringOptional,
  zStringRequired,
  zVideoEmbedCodeOptional,
} from '@svag/shared/src/zod'
import { z } from 'zod'

export const zCreateConsultationInstanceInput = z.object({
  desc: zStringOptional.nullable(),
  title: zStringRequired,
  direction: zDirection,
  consultationOfferId: zStringOptional.nullable(),
  attendeeEmail: zEmailRequired,
  scheduledAt: z.coerce.date().nullable(),
  happendAt: z.coerce.date().nullable(),
  videoEmbedCode: zVideoEmbedCodeOptional.nullable(),
  notes: zStringOptional.nullable(),
  durationMinutes: z.coerce.number().nonnegative().int().nullable(),
  priceUsd: z.coerce.number().nonnegative().nonnegative(),
  prepayRequiredAmountUsd: z.coerce.number().nonnegative().int().default(0),
  prepayDeadlineAt: z.coerce.date().nullable(),
  afterpayRequiredAmountUsd: z.coerce.number().nonnegative().int().default(0),
})
