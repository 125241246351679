import cn from 'classnames'
import React from 'react'
import { Alert, type AlertProps } from '../Alert'
import css from './index.module.scss'

export type SegmentProps = {
  children?: React.ReactNode
  title?: string | false | React.ReactNode
  desc?: string | false | React.ReactNode
  size?: '2XL' | 'XL' | 'L' | 'M' | 'S' | 'XS' | 'SemiXL'
  h?: 1 | 2 | 3
  rightRender?: React.ReactNode
  alertMessage?: AlertProps['message']
  id?: string
}
export const Segment = ({
  title,
  children,
  size = 'L',
  h = 1,
  rightRender,
  desc,
  alertMessage,
  ...restProps
}: SegmentProps) => (
  <div className={cn({ [css.segment]: true, [css[`size-${size}`]]: true })} {...restProps}>
    {(title || rightRender) && (
      <div className={css.header}>
        {h === 1 ? (
          <h1 className={css.title}>{title}</h1>
        ) : h === 2 ? (
          <h2 className={css.title}>{title}</h2>
        ) : (
          <h3 className={css.title}>{title}</h3>
        )}
        {rightRender && <div className={css.right}>{rightRender}</div>}
      </div>
    )}
    {!!desc && <div className={css.desc}>{desc}</div>}
    {!!alertMessage && (
      <div className={css.alertMessage}>
        <Alert color="brown">{alertMessage}</Alert>
      </div>
    )}
    {children && <div className={css.content}>{children}</div>}
  </div>
)

export const Segments = ({ children }: { children: React.ReactNode }) => {
  return <div className={css.segments}>{children}</div>
}
