import type { TrpcRouterOutput } from '@svag/backend/src/router'
import { PublicUserPreview } from '../../../../components/other/PublicUserPreview'
import { Alert } from '../../../../components/ui/Alert'
import { Loader } from '../../../../components/ui/Loader'
import { Segment } from '../../../../components/ui/Segment'
import { useT } from '../../../../lib/i18n'
import { trpc } from '../../../../lib/trpc'
import css from './index.module.scss'

export const GroupPageActiveStudents = ({
  groupInstance,
}: {
  groupInstance: TrpcRouterOutput['getGroupInstance']['groupInstance']
}) => {
  const { t } = useT('groupPageActiveStudents')
  const queryResult = trpc.getGroupInstanceStudents.useInfiniteQuery(
    {
      limit: 9,
      groupInstanceSlug: groupInstance.slug,
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage.nextCursor
      },
    }
  )
  const studentsInGroup = queryResult.data?.pages.flatMap((page) => page.studentsInGroup) ?? []
  return (
    <Segment size="M" title={t('title')}>
      {queryResult.isLoading ? (
        <Loader type="section" />
      ) : queryResult.error ? (
        <Alert color="red">{queryResult.error.message}</Alert>
      ) : studentsInGroup.length ? (
        <div className={css.students}>
          {studentsInGroup.map((studentInGroup) => (
            <Segment key={studentInGroup.id} size="S" title={studentInGroup.name}>
              <PublicUserPreview publicUser={studentInGroup} size="s" />
            </Segment>
          ))}
        </div>
      ) : (
        <Alert color="brown">{t('noActiveStudentsMessage')}</Alert>
      )}
    </Segment>
  )
}
