import type { TrpcRouterOutput } from '@svag/backend/src/router'
import cn from 'classnames'
import { useT } from '../../../../../../lib/i18n'
import { EdDevPromoPageStack } from '../EdDevPromoPageStack'
import css from './index.module.scss'

export const EdDevPromoPageIntro = ({
  guide,
  isFirstSection = true,
}: {
  guide: TrpcRouterOutput['getStudentGuideFull']['guide']
  isFirstSection?: boolean
}) => {
  const { t } = useT('edDevPromoPageIntro')
  return (
    <div className={cn({ [css.intro]: true, [css.isFirstSection]: isFirstSection })}>
      <div className={css.wrapper}>
        <div className={css.textAndMem}>
          <div className={css.text}>
            <h1 className={css.sectionTitle}>
              <span className={css.sectionTitleText}>{t('title')}</span>
            </h1>
            <p className={css.paragraph}>{t('description')}</p>
          </div>
        </div>
        <EdDevPromoPageStack className={css.stack} size="m" />
      </div>
    </div>
  )
}
