import { useParams, useSearchParams } from 'react-router-dom'
import { Alert } from '../../../components/ui/Alert'
import { Button, Buttons } from '../../../components/ui/Button'
import { Loader } from '../../../components/ui/Loader'
import { Segment } from '../../../components/ui/Segment'
import { useT } from '../../../lib/i18n'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getDashboardRoute, type ReturnPawRouteParams } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'

const pawStatuses = ['success', 'fail', 'in-progress', 'cancel'] as const
type PawStatus = (typeof pawStatuses)[number]

const OrderPawIdRelatedContent = ({ orderPawId, pawStatus }: { orderPawId: string; pawStatus: PawStatus }) => {
  const { t } = useT('returnPawPage')
  const queryResult = trpc.getRedirectRouteByOrderPawId.useQuery({
    orderPawId,
  })

  if (queryResult.isLoading) {
    return <Loader type="page" />
  }

  if (queryResult.isError) {
    return (
      <Segment title={t('errorTitle')}>
        <Alert color="red" message={queryResult.error.message} />
        <Buttons>
          <Button to={getDashboardRoute()}>{t('continue')}</Button>
        </Buttons>
      </Segment>
    )
  }

  return (
    <Segment title={t(`status.${pawStatus}`)}>
      <Buttons>
        <Button to={queryResult.data.redirectRoute}>{t('continue')}</Button>
      </Buttons>
    </Segment>
  )
}

export const ReturnPawPage = withPageWrapper({
  title: ({ t }) => t('returnPawPage.completionTitle'),
  langs: ['ru', 'en'],
})(() => {
  const { pawStatus } = useParams() as ReturnPawRouteParams as { pawStatus: PawStatus }
  const [searchParams] = useSearchParams()
  const { t } = useT('returnPawPage')

  if (!pawStatuses.includes(pawStatus)) {
    return (
      <Segment title={t('completionTitle')}>
        <Alert color="red" message={t('invalidStatus')} />
      </Segment>
    )
  }

  const orderPawId = searchParams.get('MNT_TRANSACTION_ID')
  if (!orderPawId) {
    return (
      <Segment title={t(`status.${pawStatus}`)}>
        <Buttons>
          <Button to={getDashboardRoute()}>{t('continue')}</Button>
        </Buttons>
      </Segment>
    )
  }

  return <OrderPawIdRelatedContent orderPawId={orderPawId} pawStatus={pawStatus} />
})
