import type { TrpcRouterOutput } from '@svag/backend/src/router'
import { plural } from '@svag/shared/src/plural'
import React from 'react'
import { CurrencySwitchPrice } from '../../../../../../components/other/CurrencySwitch'
import { useCurrency } from '../../../../../../lib/currency'
import { useT } from '../../../../../../lib/i18n'
import { getHoursFromDuration } from '../../../../../../utils/getHoursFromDuration'

export const EdDevPromoPagePlansShort = ({ guide }: { guide: TrpcRouterOutput['getStudentGuideFull']['guide'] }) => {
  const { toMoneyWithCurrency } = useCurrency()
  const { t: tg } = useT('general')
  const { t } = useT('edDevPromoPagePlansShort')

  return (
    <>
      {guide.plans.map((plan, i) => {
        const oldPriceExists = plan.priceUsd !== plan.defaultPriceUsd
        const priceExists = plan.priceUsd !== 0 || !oldPriceExists
        const pricesExists = oldPriceExists || priceExists
        const withSale = oldPriceExists && priceExists && pricesExists
        if (plan.deprecated) {
          return null
        }
        return (
          <React.Fragment key={plan.name}>
            {i > 0 && <br />}
            {plan.name === 'free' && (
              <>
                {t(`plans.free.description`, {
                  lessons: plural(plan.lessonsCount, tg('lessons', { returnObjects: true })),
                  hours: plural(getHoursFromDuration(plan.videosDuration), tg('hours', { returnObjects: true })),
                })}
              </>
            )}
            {plan.name === 'basic' && (
              <>
                {t(`plans.basic.description`, {
                  lessons: plural(plan.lessonsCount, tg('lessons', { returnObjects: true })),
                  hours: plural(getHoursFromDuration(plan.videosDuration), tg('hours', { returnObjects: true })),
                })}{' '}
                {withSale ? (
                  <CurrencySwitchPrice>
                    <s>{toMoneyWithCurrency({ usd: plan.defaultPriceUsd, rub: plan.defaultPriceRub })}</s>
                    {' → '}
                    {toMoneyWithCurrency({ usd: plan.priceUsd, rub: plan.priceRub })}
                  </CurrencySwitchPrice>
                ) : (
                  <CurrencySwitchPrice>
                    {toMoneyWithCurrency({ usd: plan.defaultPriceUsd, rub: plan.defaultPriceRub })}
                  </CurrencySwitchPrice>
                )}
              </>
            )}
            {plan.name === 'pro' && (
              <>
                {t(`plans.pro.description`, {
                  lessons: plural(plan.lessonsCount, tg('lessons', { returnObjects: true })),
                  hours: plural(getHoursFromDuration(plan.videosDuration), tg('hours', { returnObjects: true })),
                })}{' '}
                {withSale ? (
                  <CurrencySwitchPrice>
                    <s>{toMoneyWithCurrency({ usd: plan.defaultPriceUsd, rub: plan.defaultPriceRub })}</s>
                    {' → '}
                    {toMoneyWithCurrency({ usd: plan.priceUsd, rub: plan.priceRub })}
                  </CurrencySwitchPrice>
                ) : (
                  <CurrencySwitchPrice>
                    {toMoneyWithCurrency({ usd: plan.defaultPriceUsd, rub: plan.defaultPriceRub })}
                  </CurrencySwitchPrice>
                )}
              </>
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}
