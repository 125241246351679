import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Alert } from '../../../components/ui/Alert'
import { Loader } from '../../../components/ui/Loader'
import { Segment } from '../../../components/ui/Segment'
import { useForm } from '../../../lib/form'
import { useT } from '../../../lib/i18n'
import { trackEmailVerificationFail, trackEmailVerificationSuccess } from '../../../lib/mixpanel'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { type NewEmailConfirmationRouteParams } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'

export const ConfirmNewEmailPage = withPageWrapper({
  title: ({ t }) => t('confirmNewEmailPage.title'),
  langs: ['ru', 'en'],
})(() => {
  const { newEmailConfirmationToken } = useParams() as NewEmailConfirmationRouteParams
  const confirmNewEmail = trpc.confirmNewEmail.useMutation()
  const trpcUtils = trpc.useContext()
  const { t } = useT('confirmNewEmailPage')
  const { formik, alertProps } = useForm({
    onSubmit: async () => {
      try {
        const { email } = await confirmNewEmail.mutateAsync({ newEmailConfirmationToken })
        trackEmailVerificationSuccess()
        trpcUtils.getMe.setData(undefined, (prevData) => {
          return !prevData
            ? prevData
            : {
                ...prevData,
                me: !prevData.me
                  ? null
                  : {
                      ...prevData.me,
                      email,
                      emailConfirmed: true,
                    },
              }
        })
      } catch (error) {
        trackEmailVerificationFail()
        throw error
      }
    },
    successMessage: t('successMessage'),
    successMessageDuration: false,
    resetOnSuccess: false,
  })
  useEffect(() => {
    formik.handleSubmit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  if (!formik.submitCount || formik.isSubmitting) {
    return <Loader type="page" />
  }
  return (
    <Segment title={t('segmentTitle')}>
      <Alert {...alertProps} />
    </Segment>
  )
})
