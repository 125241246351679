import { sharedEnv } from '@svag/shared/src/env'
import { type Lang } from '@svag/shared/src/lang'
import { useParams as useReactRouterParams } from 'react-router-dom'

export const getLangByLocationPathname = () => {
  const path = typeof window !== 'undefined' ? window.location.pathname : null
  if (!path) {
    return null
  }
  const lang = path.split('/')[1]
  return lang === 'ru' || lang === 'en' ? lang : null
}

type PumpedGetRouteInputBase = {
  abs?: boolean
  lang?: Lang | null
  searchParams?: Record<string, any>
  anchor?: string
}

function pumpGetRoute<T extends Record<string, boolean>>(
  routeParamsDefinition: T,
  getRoute: (routeParams: Record<keyof T, string>) => string
): {
  (routeParams: Record<keyof T, string> & PumpedGetRouteInputBase): string
  placeholders: Record<keyof T, string>
  useParams: () => Record<keyof T, string>
  definition: string
}
function pumpGetRoute(getRoute: () => string): {
  (routeParams?: PumpedGetRouteInputBase): string
  placeholders: {}
  useParams: () => {}
  definition: string
}
function pumpGetRoute(routeParamsOrGetRoute?: any, maybeGetRoute?: any) {
  const routeParamsDefinition = typeof routeParamsOrGetRoute === 'function' ? {} : routeParamsOrGetRoute
  const getRoute = typeof routeParamsOrGetRoute === 'function' ? routeParamsOrGetRoute : maybeGetRoute
  const placeholders = Object.keys(routeParamsDefinition).reduce((acc, key) => ({ ...acc, [key]: `:${key}` }), {})
  const definition = '/:lang' + (getRoute(placeholders) as string)

  const pumpedGetRoute = (routeParams?: PumpedGetRouteInputBase) => {
    const route = getRoute(routeParams)
    const langPrefix = (() => {
      if (routeParams?.lang === null) {
        return ``
      }
      if (routeParams?.lang) {
        return `/${routeParams.lang}`
      }
      const langByLocationPathname = getLangByLocationPathname()
      if (langByLocationPathname) {
        return `/${langByLocationPathname}`
      }
      return ''
    })()
    const searchParamsString = (() => {
      if (!routeParams?.searchParams) {
        return ''
      }
      const searchParams = new URLSearchParams(routeParams.searchParams)
      return `?${searchParams.toString()}`
    })()
    const anchorString = (() => {
      if (!routeParams?.anchor) {
        return ''
      }
      return `#${routeParams.anchor}`
    })()
    const relativePath = `${langPrefix}${route}${searchParamsString}${anchorString}`
    if (routeParams?.abs) {
      return `${sharedEnv.WEBAPP_URL}${relativePath}`
    } else {
      return relativePath
    }
  }
  pumpedGetRoute.placeholders = placeholders
  pumpedGetRoute.definition = definition
  pumpedGetRoute.useParams = useReactRouterParams as any
  return pumpedGetRoute
}

export type RouteParams<T extends { placeholders: Record<string, string> }> = T['placeholders']

export const pgr = pumpGetRoute
