import type { ClientProjectPreview } from '@svag/backend/src/services/general/project'
import cn from 'classnames'
import format from 'date-fns/format'
import { Link } from 'react-router-dom'
import { useT } from '../../../lib/i18n'
import { openGallery } from '../../../lib/openGallery'
import { getTeamRoute, getUserRoute } from '../../../lib/routes'
import { useWindowSize } from '../../../lib/useWindowSize'
import { Button } from '../../ui/Button'
import { ColumnFiller } from '../../ui/ColumnFiller'
import css from './index.module.scss'

const Item = ({
  item,
  setItemRef,
  buttonText,
  hideTeams,
}: {
  item: ClientProjectPreview
  setItemRef: (ref: HTMLDivElement) => void
  buttonText?: string
  hideTeams?: boolean
}) => {
  const { t: tg } = useT('general')
  const { t } = useT('projects')
  const year = (() => {
    if (item.startedAt && item.finishedAt) {
      return `${format(item.startedAt, 'yyyy')}–${format(item.finishedAt, 'yyyy')}`
    }
    if (item.startedAt) {
      return `${t('from')} ${format(item.startedAt, 'yyyy')}`
    }
    if (item.finishedAt) {
      return `${format(item.finishedAt, 'yyyy')}`
    }
    return null
  })()
  const isHeaderExists = !!item.name || (!!item.siteName && !!item.siteUrl)
  return (
    <div ref={setItemRef} className={css.item}>
      {year && <div className={cn({ [css.year]: true, [css.lowered]: isHeaderExists })}>{year}</div>}
      {isHeaderExists && (
        <div className={css.info}>
          {item.name && (
            <div className={css.iconAndName}>
              {item.icon && <img className={css.icon} src={item.icon} alt={item.name} />}
              {item.name && <div className={css.name}>{item.name}</div>}
            </div>
          )}
          {!!item.siteName && !!item.siteUrl && (
            <a href={item.siteUrl} target="_blank" rel="noopener noreferrer" className={css.site}>
              {item.siteName}
            </a>
          )}
        </div>
      )}
      {!!item.image && <img src={item.image} alt="" className={css.preview} />}
      <div className={css.desc}>{item.desc}</div>
      {!!item.images.length && (
        <Button
          size="s"
          color="brandAccentLight"
          className={css.more}
          onClick={() => {
            openGallery({
              imagesUrls: item.images,
            })
          }}
        >
          {t('gallery')}
        </Button>
      )}
      {/* {item.buttonOnClick && 'buttonText' in texts && (
        <Button size="s" color="brandAccentLight" className={css.more} onClick={item.buttonOnClick}>
          {texts.buttonText}
        </Button>
      )}
      {item.buttonLink && 'buttonText' in texts && (
        <Button
          size="s"
          color="brandAccentLight"
          className={css.more}
          href={item.buttonLink}
          rel="noreferrer"
          target="_blank"
        >
          {texts.buttonText}
        </Button>
      )} */}
      <div className={css.members}>
        {item.members.map((member, i) =>
          member.specialist ? (
            <Link to={getUserRoute({ userSlug: member.specialist.slug })} className={css.member} key={i}>
              <img className={css.specialistAvatar} src={member.specialist.avatar || ''} alt="" />
              <span className={css.memberInfo}>
                <span className={css.name}>{member.specialist.name}</span>
                {member.specialist.memberDirections && (
                  <span className={css.directions}>
                    {member.specialist.memberDirections.map((d, i) => (
                      <span key={i} className={cn(css.direction, css[d])}>
                        {tg('directions', { returnObjects: true })[d]}
                      </span>
                    ))}
                  </span>
                )}
              </span>
            </Link>
          ) : member.team && !hideTeams ? (
            <Link to={getTeamRoute({ teamSlug: member.team.slug })} className={css.member} key={i}>
              <img className={css.teamAvatar} src={member.team.avatar || ''} alt="" />
              <span className={css.memberInfo}>
                <span className={css.name}>
                  {t('team')} {member.team.name}
                </span>
                {!!member.team.memberDirections.length && (
                  <span className={css.directions}>
                    {member.team.memberDirections.map((d, i) => (
                      <span key={i} className={cn(css.direction, css[d])}>
                        {tg('directions', { returnObjects: true })[d]}
                      </span>
                    ))}
                  </span>
                )}
              </span>
            </Link>
          ) : null
        )}
      </div>
    </div>
  )
}

export const Projects = ({
  projects,
  className,
  hideTeams = true,
}: {
  className?: string
  projects: ClientProjectPreview[]
  hideTeams?: boolean
}) => {
  const { width: windowWidth } = useWindowSize()
  const columnsCount = windowWidth > 1100 ? 4 : windowWidth > 800 ? 3 : windowWidth > 600 ? 2 : 1
  return (
    <div className={cn(css.projects, className)}>
      <ColumnFiller
        items={projects.map((p, i) => ({ ...p, key: i }))}
        columnsCount={columnsCount}
        columnRender={({ children, index, width }) => (
          <div key={index} style={{ width }} className={css.column}>
            {children}
          </div>
        )}
        itemRender={({ item, index, setItemRef }) => (
          <Item key={item.key} item={item} setItemRef={setItemRef} hideTeams={hideTeams} />
        )}
      />
    </div>
  )
}
