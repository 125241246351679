import type { ClientPublication } from '@svag/backend/src/services/general/publication'
import cn from 'classnames'
import { useLang } from '../../../lib/i18n'
import { Icon } from '../../ui/Icon'
import css from './index.module.scss'

const Item = ({ publication }: { publication: ClientPublication }) => {
  const { formatDate } = useLang()
  const sourceText = [publication.source, formatDate(publication.publishedAt, 'LLLL yyyy').toLowerCase()]
    .filter(Boolean)
    .join(', ')
  const sourceIconName = publication.url?.includes('habr')
    ? 'habrRound'
    : publication.url?.includes('dev.to')
    ? 'devtoRound'
    : null
  return (
    <a href={publication.url} className={css.publication} target="_blank" rel="noopener noreferrer">
      <p className={css.link}>{publication.title}</p>
      <div className={css.authorAndSource}>
        {publication.owner && (
          <div className={css.author}>
            {publication.owner.avatar && <img src={publication.owner.avatar} alt="" className={css.authorAvatar} />}
            <div className={css.authorName}>{publication.owner.name}</div>
          </div>
        )}
        <div className={css.source}>
          {sourceIconName && <Icon className={css.sourceIcon} name={sourceIconName} />}
          <div className={css.sourceText}>{sourceText}</div>
        </div>
      </div>
      {publication.desc && <p className={css.desc}>{publication.desc}</p>}
    </a>
  )
}

export const Publications = ({
  publications,
  className,
}: {
  className?: string
  publications: ClientPublication[]
}) => {
  return (
    <div className={cn(css.publications, className)}>
      {publications.map((p, i) => (
        <Item key={i} publication={p} />
      ))}
    </div>
  )
}
