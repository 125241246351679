import cn from 'classnames'
import ru from 'date-fns/locale/ru'
import { type FormikProps } from 'formik'
import React, { forwardRef, useEffect, useState, type ChangeEvent } from 'react'
import ReactDatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useLang, useT } from '../../../lib/i18n'
import css from './index.module.scss'

registerLocale('ru', ru)
setDefaultLocale('ru')

export const DatePicker: React.FC<{
  label: string
  formik: FormikProps<any>
  name: string
  hint?: string
  onChange?: (date: Date | null) => void
  fullWidth?: boolean
  maxWidth?: number
  disabled?: boolean
  withTime?: boolean
  floatingPlaceholder?: string
}> = ({
  label,
  formik,
  name,
  hint,
  onChange,
  fullWidth,
  maxWidth,
  disabled,
  withTime = true,
  floatingPlaceholder,
  ...restProps
}) => {
  const { lang } = useLang()
  const value = formik.values[name]
  const error = formik.errors[name] as string | undefined
  const touched = formik.touched[name] as boolean
  const invalid = touched && !!error
  const disabledHere = disabled || formik.isSubmitting
  const [focused, setFocused] = useState(false)
  const { t } = useT('datePicker')

  useEffect(() => {
    if (lang === 'ru') {
      registerLocale('ru', ru)
      setDefaultLocale('ru')
    } else {
      setDefaultLocale('en')
    }
  }, [lang])

  const Input = forwardRef<any, any>(
    (
      {
        value,
        onClick,
        onChange,
        onBlur,
        onFocus,
      }: {
        value: string
        onClick: () => void
        onBlur: () => void
        onFocus: () => void
        onChange: (e: ChangeEvent<HTMLInputElement>) => void
      },
      ref
    ) => (
      <input
        onClick={disabled ? () => {} : onClick}
        value={value}
        ref={ref}
        className={cn({
          [css.datepicker]: true,
          [css.fullWidth]: !!fullWidth,
          [css.invalid]: !!error && touched,
        })}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
        {...restProps}
      />
    )
  )
  return (
    <div
      className={cn({
        [css.field]: true,
        [css.disabled]: disabledHere,
        [css.filled]: !!value?.toString().length,
        [css.invalid]: invalid,
        [css.focused]: focused,
      })}
      style={{ maxWidth }}
    >
      {label && (
        <label className={css.label} htmlFor={name}>
          {label}
        </label>
      )}
      {floatingPlaceholder && (
        <div className={css.floatingPlaceholder}>
          <div className={css.floatingPlaceholderText}>{floatingPlaceholder}</div>
        </div>
      )}
      <div className={cn({ [css.datepickerPlace]: true, [css.invalid]: invalid })}>
        <ReactDatePicker
          selected={value}
          onChange={(date) => {
            onChange?.(date)
            void formik.setFieldValue(name, date || '')
            formik.setFieldTouched(name)
          }}
          onFocus={() => {
            setFocused(true)
          }}
          onBlur={() => {
            formik.setFieldTouched(name)
            setFocused(false)
          }}
          disabled={disabled}
          showTimeSelect={withTime}
          timeFormat="HH:mm"
          timeIntervals={15}
          timeCaption={t('timeCaption')}
          dateFormat="dd.MM.yyyy HH:mm"
          customInput={<Input />}
        />
      </div>
      {invalid && <div className={css.error}>{error}</div>}
      {hint && <div className={css.hint}>{hint}</div>}
    </div>
  )
}
