import { useStore } from '@nanostores/react'
import { action, atom } from 'nanostores'
import { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useT } from '../../../lib/i18n'
import { getSignInRoute, getSignUpRoute } from '../../../lib/routes'
import { Alert, type AlertProps } from '../../ui/Alert'
import { Modal } from '../../ui/Modal'
import { Segment, type SegmentProps } from '../../ui/Segment'
import { RestorePassword } from '../RestorePassword'
import { SignIn } from '../SignIn'
import { SignUp } from '../SignUp'
import css from './index.module.scss'

type Choice = 'signIn' | 'signUp' | 'restorePassword'
type Policy = 'component' | 'page'

export const authStateStore = atom({
  isModalOpen: false,
  componentChoice: 'signIn' as Choice,
  alertMessage: null as null | string,
  onSuccess: null as null | (() => void | Promise<void>),
  isSimpleSignUp: true,
  interests: [] as string[],
})

type OpenModalInput = {
  alertMessage?: null | string
  onSuccess?: null | (() => void | Promise<void>)
  isSimpleSignUp?: boolean
  interests?: string[]
}

export const openSignInModal = action(authStateStore, 'openSignInModal', (store, props?: OpenModalInput) => {
  const alertMessage = props?.alertMessage !== undefined ? props.alertMessage : store.get().alertMessage
  const onSuccess = props?.onSuccess !== undefined ? props.onSuccess : store.get().onSuccess
  store.set({ ...store.get(), isModalOpen: true, componentChoice: 'signIn', alertMessage, onSuccess })
})

export const openSignUpModal = action(authStateStore, 'openSignUpModal', (store, props?: OpenModalInput) => {
  const alertMessage = props?.alertMessage !== undefined ? props.alertMessage : store.get().alertMessage
  const onSuccess = props?.onSuccess !== undefined ? props.onSuccess : store.get().onSuccess
  const isSimpleSignUp = props?.isSimpleSignUp !== undefined ? props.isSimpleSignUp : store.get().isSimpleSignUp
  const interests = props?.interests !== undefined ? props.interests : store.get().interests
  store.set({
    ...store.get(),
    isModalOpen: true,
    componentChoice: 'signUp',
    alertMessage,
    onSuccess,
    isSimpleSignUp,
    interests,
  })
})

export const openRestorePasswordModal = action(authStateStore, 'openRestorePasswordModal', (store) => {
  store.set({ ...store.get(), isModalOpen: true, componentChoice: 'restorePassword' })
})

export const closeAuthModal = action(authStateStore, 'closeAuthModal', (store) => {
  store.set({ ...store.get(), isModalOpen: false, alertMessage: null, onSuccess: null })
})

export const switchAuthComponentChoice = action(authStateStore, 'openSignInModal', (store, componentChoice: Choice) => {
  store.set({ ...store.get(), componentChoice })
})

export const Auth = ({
  policy,
  initialChoice,
  alertMessage,
  segmentSize,
}: {
  policy: Policy
  initialChoice?: Choice
  alertMessage?: AlertProps['message']
  segmentSize?: SegmentProps['size']
}) => {
  const { pathname } = useLocation()
  const authState = useStore(authStateStore)
  const { t } = useT('auth')
  const choiceWhenPage: Choice = pathname === getSignInRoute() ? 'signIn' : 'signUp'
  const choiceWhenComponent = authState.componentChoice
  const choice: Choice = {
    page: choiceWhenPage,
    component: choiceWhenComponent,
  }[policy]
  useEffect(() => {
    if (initialChoice) {
      switchAuthComponentChoice(initialChoice)
    }
  }, [initialChoice])
  return (
    <div className={css.auth}>
      {(!!alertMessage || !!authState.alertMessage) && (
        <div className={css.alert}>
          <Alert color="brown">{alertMessage || authState.alertMessage}</Alert>
        </div>
      )}
      {choice === 'restorePassword' ? (
        <RestorePassword segmentSize={segmentSize} />
      ) : choice === 'signIn' ? (
        <Segment
          size={segmentSize}
          title={t('signIn.title')}
          desc={
            <p className={css.switchText}>
              {t('signIn.desc')} —{' '}
              {policy === 'page' && (
                <Link className={css.link} to={getSignUpRoute()}>
                  {t('signIn.registerLink')}
                </Link>
              )}
              {policy === 'component' && (
                <button
                  className={css.link}
                  onClick={() => {
                    switchAuthComponentChoice('signUp')
                  }}
                >
                  {t('signIn.registerLink')}
                </button>
              )}
            </p>
          }
        >
          <SignIn onSuccess={authState.onSuccess} />
        </Segment>
      ) : choice === 'signUp' ? (
        <Segment
          size={segmentSize}
          title={t('signUp.title')}
          desc={
            <p className={css.switchText}>
              {policy === 'page' && (
                <Link className={css.link} to={getSignInRoute()}>
                  {t('signUp.loginLink')}
                </Link>
              )}
              {policy === 'component' && (
                <button
                  className={css.link}
                  onClick={() => {
                    switchAuthComponentChoice('signIn')
                  }}
                >
                  {t('signUp.loginLink')}
                </button>
              )}{' '}
              {t('signUp.desc')}
            </p>
          }
        >
          <SignUp onSuccess={authState.onSuccess} isSimple={authState.isSimpleSignUp} interests={authState.interests} />
        </Segment>
      ) : null}
    </div>
  )
}

export const AuthModal = () => {
  const authModalState = useStore(authStateStore)

  return (
    <Modal
      maxWidth={480}
      isOpen={authModalState.isModalOpen}
      onRequestClose={() => {
        closeAuthModal()
      }}
    >
      <Auth policy="component" />
    </Modal>
  )
}
