import type { Direction } from '@svag/backend/src/services/other/prisma'
import cn from 'classnames'
import { useWindowSize } from '../../../lib/useWindowSize'
import {
  ColumnFiller,
  type ColumnFillerItemKeyKeyType,
  type ColumnFillerItemRenderType,
  type ColumnFillerItemType,
  type ColumnFillerItemsType,
} from '../ColumnFiller'
import { Icon, type IconName } from '../Icon'
import css from './index.module.scss'

export type CardProps = {
  setRef: React.RefCallback<any>
  hintColor?: Direction | 'gray' | 'gray-except-icon' | 'blue-except-icon'
  hintIconName?: IconName
  hintText?: React.ReactNode
  title: React.ReactNode
  content?: React.ReactNode
  buttons?: React.ReactNode
  price?: string
}
export const Card = ({
  setRef,
  price,
  hintColor = 'gray',
  hintIconName,
  hintText,
  title,
  content,
  buttons,
}: CardProps) => {
  return (
    <div ref={setRef} className={css.card}>
      {!!hintText && (
        <div className={cn({ [css.hint]: true, [css[`color-${hintColor}`]]: true })}>
          {!!hintIconName && <Icon className={css.hintIcon} name={hintIconName} />}
          <span className={css.hintText}>{hintText}</span>
        </div>
      )}
      <div className={css.title}>{title}</div>
      {!!content && <div className={css.content}>{content}</div>}
      {!!price && (
        <div className={css.price}>
          <Icon className={css.priceIcon} name="wallet" />
          <span className={css.priceText}>{price}</span>
        </div>
      )}
      {!!buttons && <div className={css.buttons}>{buttons}</div>}
    </div>
  )
}

export type GetColumnsCountType<T extends ColumnFillerItemType> = ({
  items,
  windowWidth,
}: {
  items: ColumnFillerItemsType<T>
  windowWidth: number
}) => number
export const Cards = <T extends ColumnFillerItemType>({
  items,
  itemKeyKey,
  cardRender,
  getColumnsCount = ({ items, windowWidth }) => (items.length < 2 ? 1 : windowWidth > 900 ? 2 : 1),
}: {
  items: ColumnFillerItemsType<T>
  itemKeyKey?: ColumnFillerItemKeyKeyType<T>
  cardRender?: ColumnFillerItemRenderType<T>
  getColumnsCount?: GetColumnsCountType<T>
}) => {
  const { width: windowWidth } = useWindowSize()
  const columnsCount = getColumnsCount({ items, windowWidth })
  return (
    <div className={css.cards}>
      <ColumnFiller
        items={items}
        itemKeyKey={itemKeyKey}
        columnsCount={columnsCount}
        columnRender={({ children, index, width }) => (
          <div key={index} style={{ width }} className={css.column}>
            {children}
          </div>
        )}
        itemRender={cardRender}
      />
    </div>
  )
}
