import { EdCards } from '../../../components/education/cards/EdCard'
import { Contacts } from '../../../components/general/Contacts'
import { Projects } from '../../../components/general/Projects'
import { Publications } from '../../../components/general/Publications'
import { IntroLayout } from '../../../components/layouts/IntroLayout'
import { RichText } from '../../../components/ui/RichText'
import { Segment } from '../../../components/ui/Segment'
import { useT } from '../../../lib/i18n'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getUserRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

export const UserPage = withPageWrapper({
  showLoaderOnFetching: false,
  useQuery: () => {
    const params = getUserRoute.useParams()
    return trpc.getSpecialist.useQuery({
      specialistSlug: params.userSlug,
    })
  },
  setProps: ({ queryResult }) => {
    return {
      ...queryResult.data,
    }
  },
  title: ({ queryResult }) => queryResult.data.specialist.name,
  langs: ['ru', 'en'],
})(({ projects, publications, specialist, offers }) => {
  const { t } = useT('userPage')

  return (
    <IntroLayout>
      <div className={css.wrapper}>
        <div className={css.sections}>
          <Segment h={1} size="2XL" title={specialist.name}>
            <div className={css.avatarAndInfo}>
              <img src={specialist.avatar || ''} alt="" className={css.avatar} />
              <div className={css.info}>
                <div className={css.text}>
                  <p>{specialist.desc}</p>
                  {/* {!!specialist.contacts.length && (
                    <p>
                      <Contacts contacts={specialist.contacts} />
                    </p>
                  )} */}
                </div>
              </div>
            </div>
          </Segment>
          {!!specialist.contacts.length && (
            <Segment h={2} size="L" title={t('contacts.title')}>
              <div className={css.text}>
                <Contacts contacts={specialist.contacts} />
              </div>
            </Segment>
          )}
          {!!specialist.aboutHtml && (
            <Segment h={2} size="L" title={t('about.title')}>
              <RichText html={specialist.aboutHtml} />
            </Segment>
          )}
          {!!projects.length && (
            <Segment h={2} size="L" title={t('projects.title')}>
              <Projects projects={projects} />
            </Segment>
          )}
          {!!publications.length && (
            <Segment h={2} size="L" title={t('publications.title')}>
              <Publications publications={publications} />
            </Segment>
          )}
          {!!offers.length && (
            <Segment h={2} size="L" title={t('offers.title')}>
              <EdCards
                edItems={offers}
                getColumnsCount={({ items, windowWidth }) =>
                  items.length < 2 ? 1 : items.length < 3 ? 2 : windowWidth > 1100 ? 3 : windowWidth > 900 ? 2 : 1
                }
              />
            </Segment>
          )}
          {/* {!!teams.length && (
            <Segment h={2} size="L" title={teams.length === 1 ? t('teams.title') : t('teams.titlePlural')}>
              <Members teams={teams} />
            </Segment>
          )} */}
        </div>
      </div>
    </IntroLayout>
  )
})
