import { useStore } from '@nanostores/react'
import { currencies, type Currency } from '@svag/shared/src/currency'
import { langs, type Lang } from '@svag/shared/src/lang'
import cn from 'classnames'
import { action, atom } from 'nanostores'
import { useEffect, useRef, useState } from 'react'
import { Link, Outlet, useLocation, type NavLinkProps } from 'react-router-dom'
import { ReactComponent as LogoEdRu } from '../../../assets/ed/logo.svg'
import { ReactComponent as IconBurger } from '../../../assets/icons/burger.svg'
import { ReactComponent as IconCrossMenu } from '../../../assets/icons/crossMenu.svg'
import { ReactComponent as LogoEn } from '../../../assets/logo-en.svg'
import { ReactComponent as LogoRu } from '../../../assets/logo-ru.svg'
import { useMe } from '../../../lib/ctx'
import { toHumanCurrency, useCurrency } from '../../../lib/currency'
import { removeLangFromRoutePathname, toHumanLang, useLang, useT } from '../../../lib/i18n'
import {
  getDashboardRoute,
  getEditProfileRoute,
  getEducationRoute,
  getHomeRoute,
  getRestorePasswordRoute,
  getSignInRoute,
  getSignOutRoute,
  getSignUpRoute,
} from '../../../lib/routes'
import { useElementTopReached, useScrollTop } from '../../../lib/useScrollTop'
import { useDesktopOrMobile, useWindowSize, type WindowSize } from '../../../lib/useWindowSize'
import { openSignInModal } from '../../auth/Auth'
import { NavLink } from '../../other/NavLink'
import { scrollToHash } from '../../other/ScrollToHash'
import { Icon } from '../../ui/Icon'
import { Footer } from '../Footer'
import css from './index.module.scss'

export const generalLayoutMenuStore = atom({
  isOpen: false,
})

export const openGeneralLayoutMenu = action(generalLayoutMenuStore, 'openGeneralLayoutMenu', (store) => {
  store.set({ ...store.get(), isOpen: true })
})

export const closeGeneralLayoutMenu = action(generalLayoutMenuStore, 'closeGeneralLayoutMenu', (store) => {
  store.set({ ...store.get(), isOpen: false })
})

export const toggleGeneralLayoutMenu = action(generalLayoutMenuStore, 'toggleGeneralLayoutMenu', (store) => {
  store.set({ ...store.get(), isOpen: !store.get().isOpen })
})

export const GeneralLayoutNavLink = ({
  children,
  className,
  to,
  onClick,
  subLinks,
}: {
  children: React.ReactNode
  className?: string
  to: string
  onClick?: NavLinkProps['onClick']
  subLinks?: React.ReactNode
}) => {
  return (
    <div className={css.linkWrapper}>
      <NavLink
        to={to}
        onClick={(e) => {
          onClick?.(e)
          closeGeneralLayoutMenu()
          if (to.startsWith('#')) {
            e.preventDefault()
            scrollToHash(to)
          }
        }}
        className={(props) => {
          return cn({
            [css.link]: true,
            [css.active]: props.isActive && !to.startsWith('#'),
            [className || '']: !!className,
          })
        }}
      >
        {children}
      </NavLink>
      {subLinks && <div className={css.subLinks}>{subLinks}</div>}
    </div>
  )
}

export const GeneralLayoutNavItem = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  return (
    <div
      className={cn({
        [css.navItem]: true,
        [className || '']: !!className,
      })}
    >
      {children}
    </div>
  )
}

export const GeneralLayoutAuthOrDashboardLink = () => {
  const { t } = useT('generalLayout')
  const { pathname } = useLocation()
  const isItSignUpPage = pathname === getSignUpRoute()
  const isItRestorePasswordPage = pathname === getRestorePasswordRoute()
  const me = useMe()
  const { isDesktop } = useDesktopOrMobile()
  return (
    <>
      {!!me && (
        <>
          <GeneralLayoutNavLink to={getDashboardRoute()}>{t('dashboard')}</GeneralLayoutNavLink>
        </>
      )}
      {!me && (
        <>
          <GeneralLayoutNavLink
            onClick={(e) => {
              if (!isItSignUpPage && !isItRestorePasswordPage && isDesktop) {
                e.preventDefault()
                closeGeneralLayoutMenu()
                openSignInModal()
              }
            }}
            to={getSignInRoute()}
          >
            {t('signin')}
          </GeneralLayoutNavLink>
        </>
      )}
    </>
  )
}

const GeneralNavigation = ({ children }: { children: React.ReactNode }) => {
  return <div className={css.generalNavigation}>{children}</div>
}

const SidebarNavigation = ({ children }: { children: React.ReactNode }) => {
  return <div className={css.sidebarNavigation}>{children}</div>
}

const AccountMenuDropdown = () => {
  const me = useMe()
  const { t } = useT('generalLayout')
  const [isOpen, setIsOpen] = useState(false)
  const elRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const onClick = (e: any) => {
      if (!elRef.current?.contains(e.target)) {
        setIsOpen(false)
      }
    }
    document.body.addEventListener('click', onClick)
    return () => {
      document.body.removeEventListener('click', onClick)
    }
  }, [elRef])

  return (
    <div className={css.accountMenuDropdown} ref={elRef}>
      <Icon
        name="user"
        className={css.accountMenuIcon}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      />
      <div className={cn({ [css.accountMenuPanel]: true, [css.floatingPanel]: true, [css.opened]: isOpen })}>
        <NavLink
          className={(props) =>
            cn({
              [css.accountMenuLink]: true,
              [css.active]: props.isActive,
            })
          }
          onClick={() => {
            setIsOpen(false)
          }}
          to={getDashboardRoute()}
        >
          {t('dashboard')}
        </NavLink>
        <NavLink
          className={(props) =>
            cn({
              [css.accountMenuLink]: true,
              [css.active]: props.isActive,
            })
          }
          onClick={() => {
            setIsOpen(false)
          }}
          to={getEducationRoute()}
        >
          {t('education')}
        </NavLink>
        {/* <NavLink
          className={(props) =>
            cn({
              [css.accountMenuLink]: true,
              [css.active]: props.isActive,
            })
          }
          onClick={() => {
            setIsOpen(false)
          }}
          to={getCommuinityRoute()}
        >
          {t('community')}
        </NavLink> */}
        <NavLink
          className={(props) =>
            cn({
              [css.accountMenuLink]: true,
              [css.active]: props.isActive,
            })
          }
          onClick={() => {
            setIsOpen(false)
          }}
          to={getEditProfileRoute()}
        >
          {t('profile')}
        </NavLink>
        {!me ? (
          <NavLink
            className={(props) =>
              cn({
                [css.accountMenuLink]: true,
                [css.active]: props.isActive,
              })
            }
            onClick={() => {
              setIsOpen(false)
            }}
            to={getSignInRoute()}
          >
            {t('signin')}
          </NavLink>
        ) : (
          <NavLink
            className={(props) =>
              cn({
                [css.accountMenuLink]: true,
                [css.active]: props.isActive,
              })
            }
            onClick={() => {
              setIsOpen(false)
            }}
            to={getSignOutRoute()}
          >
            {t('signout')}
          </NavLink>
        )}
      </div>
    </div>
  )
}

const GlobeDropdown = () => {
  const { t } = useT('generalLayout')
  const [isOpen, setIsOpen] = useState(false)
  const elRef = useRef<HTMLDivElement>(null)

  const { lang, setLang } = useLang()
  const { currency, setCurrency } = useCurrency()

  useEffect(() => {
    const onClick = (e: any) => {
      if (!elRef.current?.contains(e.target)) {
        setIsOpen(false)
      }
    }
    document.body.addEventListener('click', onClick)
    return () => {
      document.body.removeEventListener('click', onClick)
    }
  }, [elRef])

  return (
    <div className={css.globeDropdown} ref={elRef}>
      <Icon
        name="globe"
        className={css.globeIcon}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      />
      <div className={cn({ [css.globePanel]: true, [css.floatingPanel]: true, [css.opened]: isOpen })}>
        <div className={css.globeCols}>
          <div className={css.globeCol}>
            <div className={css.globeHeader}>{t('lang')}</div>
            <div className={css.globeOptions}>
              {langs.map((l) => (
                <button
                  key={l}
                  onClick={() => {
                    setLang(l)
                  }}
                  className={cn({ [css.globeOption]: true, [css.active]: l === lang })}
                >
                  <Icon name="tick" className={css.globeOptionTick} />
                  <span className={css.globeOptionLabel}>{toHumanLang(l)}</span>
                </button>
              ))}
            </div>
          </div>
          <div className={css.globeCol}>
            <div className={css.globeHeader}>{t('currency')}</div>
            <div className={css.globeOptions}>
              {currencies.map((c) => (
                <button
                  key={c}
                  onClick={() => {
                    setCurrency(c)
                  }}
                  className={cn({ [css.globeOption]: true, [css.active]: c === currency })}
                >
                  <Icon name="tick" className={css.globeOptionTick} />
                  <span className={css.globeOptionLabel}>{toHumanCurrency(c)}</span>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const HeaderDesktop = ({
  generalNavigationChildren,
  logoLinkPolicy,
  logoImagePolicy,
}: {
  generalNavigationChildren?: React.ReactNode
  logoLinkPolicy: LogoLinkPolicy
  logoImagePolicy: LogoImagePolicy
}) => {
  const { lang } = useLang()
  const me = useMe()
  const panelHolderElRef = useRef<any>(null)
  const { elementTopReached: panelHolderTopReached } = useElementTopReached({ elementEl: panelHolderElRef.current })
  return (
    <div className={css.headerDesktop} id="header-desktop-parent">
      <div className={css.panelHolder} ref={panelHolderElRef}>
        <div className={cn({ [css.panel]: true, [css.fixed]: panelHolderTopReached })} id="header-desktop-panel">
          <div className={css.wrapper}>
            <NavLink
              to={me ? getDashboardRoute() : getHomeRoute()}
              className={({ isActive }) =>
                cn({
                  [css.logo]: true,
                  [css.active]: logoLinkPolicy === 'navigateToHome' ? isActive : !panelHolderTopReached,
                })
              }
              onClick={(e) => {
                if (logoLinkPolicy === 'scrollToTop') {
                  e.preventDefault()
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  })
                }
              }}
            >
              {logoImagePolicy === 'education' ? (
                <LogoRu width={111} height={49} />
              ) : lang === 'ru' ? (
                <LogoRu width={111} height={49} />
              ) : (
                <LogoEn width={111} height={49} />
              )}
            </NavLink>
            <div className={css.navigation}>
              <GeneralNavigation children={generalNavigationChildren} />
              <GlobeDropdown />
              <AccountMenuDropdown />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const MobileNavigationFooter = () => {
  const me = useMe()
  const { t } = useT('generalLayout')
  const { lang, setLang, humanLang } = useLang()
  const { currency, setCurrency, humanCurrency, currencyIconName } = useCurrency()
  return (
    <div className={css.mobileNavigationFooter}>
      <GeneralLayoutNavItem>
        <label className={css.mobileNavigationFooterButton}>
          <Icon name="globe" className={css.mobileNavigationFooterButtonIcon} />
          <span className={css.mobileNavigationFooterButtonHint}>{t('lang')}:</span>
          <span className={css.mobileNavigationFooterButtonLabel}>{humanLang}</span>
          <select
            className={css.select}
            value={lang}
            onChange={(e) => {
              if (langs.includes(e.target.value as Lang)) {
                setLang(e.target.value as Lang)
              }
            }}
          >
            {langs.map((lang) => (
              <option key={lang} value={lang}>
                {toHumanLang(lang)}
              </option>
            ))}
          </select>
        </label>
      </GeneralLayoutNavItem>
      <GeneralLayoutNavItem>
        <label className={css.mobileNavigationFooterButton}>
          <Icon name={currencyIconName} className={css.mobileNavigationFooterButtonIcon} />
          <span className={css.mobileNavigationFooterButtonHint}>{t('currency')}:</span>
          <span className={css.mobileNavigationFooterButtonLabel}>{humanCurrency}</span>
          <select
            className={css.select}
            value={currency}
            onChange={(e) => {
              if (currencies.includes(e.target.value as Currency)) {
                setCurrency(e.target.value as Currency)
              }
            }}
          >
            {currencies.map((currency) => (
              <option key={currency} value={currency}>
                {toHumanCurrency(currency)}
              </option>
            ))}
          </select>
        </label>
      </GeneralLayoutNavItem>
      {!me ? (
        <GeneralLayoutNavItem>
          <NavLink
            onClick={() => {
              closeGeneralLayoutMenu()
            }}
            className={(props) => {
              return cn({
                [css.mobileNavigationFooterButton]: true,
                [css.active]: props.isActive,
              })
            }}
            to={getSignInRoute()}
          >
            <Icon name="user" className={css.mobileNavigationFooterButtonIcon} />
            <span className={css.mobileNavigationFooterButtonLabel}>{t('signin')}</span>
          </NavLink>
        </GeneralLayoutNavItem>
      ) : (
        <>
          <GeneralLayoutNavItem>
            <NavLink
              onClick={() => {
                closeGeneralLayoutMenu()
              }}
              className={(props) => {
                return cn({
                  [css.mobileNavigationFooterButton]: true,
                  [css.active]: props.isActive,
                })
              }}
              to={getDashboardRoute()}
            >
              <Icon name="user" className={css.mobileNavigationFooterButtonIcon} />
              <span className={css.mobileNavigationFooterButtonLabel}>{t('account')}</span>
            </NavLink>
          </GeneralLayoutNavItem>
          <GeneralLayoutNavItem>
            <Link
              onClick={() => {
                closeGeneralLayoutMenu()
              }}
              className={css.mobileNavigationFooterButton}
              to={getSignOutRoute()}
            >
              <Icon name="logout" className={css.mobileNavigationFooterButtonIcon} />
              <span className={css.mobileNavigationFooterButtonLabel}>{t('signout')}</span>
            </Link>
          </GeneralLayoutNavItem>
        </>
      )}
    </div>
  )
}

const HeaderMobile = ({
  generalNavigationChildren,
  sidebarNavigationChildren,
  logoLinkPolicy,
  logoImagePolicy,
}: {
  generalNavigationChildren?: React.ReactNode
  sidebarNavigationChildren?: React.ReactNode
  logoLinkPolicy: LogoLinkPolicy
  logoImagePolicy: LogoImagePolicy
}) => {
  const { lang } = useLang()
  const { isOpen: isMenuOpen } = useStore(generalLayoutMenuStore)
  const { pathname } = useLocation()
  const prevPathnameRef = useRef(pathname)
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isMenuOpen])
  useEffect(() => {
    const prevPathnameWithoutLang = removeLangFromRoutePathname(prevPathnameRef.current)
    const pathnameWithoutLang = removeLangFromRoutePathname(pathname)
    if (prevPathnameWithoutLang !== pathnameWithoutLang) {
      closeGeneralLayoutMenu()
    }
    prevPathnameRef.current = pathname
  }, [pathname])
  const { scrollTop } = useScrollTop()
  const me = useMe()
  return (
    <div className={cn({ [css.headerMobile]: true, [css.menuOpened]: isMenuOpen })} id="header-mobile-parent">
      <div className={css.place}></div>
      <div className={css.fixed}>
        <div className={css.content}>
          <div className={css.topLine} id="header-mobile-panel">
            <NavLink
              to={me ? getDashboardRoute() : getHomeRoute()}
              className={({ isActive }) =>
                cn({
                  [css.logo]: true,
                  [css.active]: logoLinkPolicy === 'navigateToHome' ? isActive : !isMenuOpen && scrollTop === 0,
                })
              }
              onClick={(e) => {
                if (logoLinkPolicy === 'scrollToTop') {
                  e.preventDefault()
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  })
                  closeGeneralLayoutMenu()
                }
              }}
            >
              {logoImagePolicy === 'education' ? (
                <LogoEdRu width={91} height={40} />
              ) : lang === 'ru' ? (
                <LogoRu width={91} height={40} />
              ) : (
                <LogoEn width={91} height={40} />
              )}
            </NavLink>
            <div className={css.right}>
              <button
                className={css.menuIcon}
                onClick={() => {
                  toggleGeneralLayoutMenu()
                }}
              >
                {!isMenuOpen && <IconBurger className={css.icon} />}
                {isMenuOpen && <IconCrossMenu className={css.icon} />}
              </button>
            </div>
          </div>
          <div className={css.navigation}>
            {!!generalNavigationChildren && <GeneralNavigation children={generalNavigationChildren} />}
            {!!sidebarNavigationChildren && <SidebarNavigation children={sidebarNavigationChildren} />}
            <MobileNavigationFooter />
          </div>
        </div>
      </div>
    </div>
  )
}

type LogoLinkPolicy = 'scrollToTop' | 'navigateToHome'
type LogoImagePolicy = 'general' | 'education'

export const GeneralLayout = ({
  showSidebar = true,
  fullWidthContent = false,
  children,
  parseWindowSize = (windowSize) => ({
    isMobile: windowSize.width <= 1000,
    isDesktop: windowSize.width > 1000,
  }),
  generalNavigationChildren,
  sidebarNavigationChildren,
  logoLinkPolicy,
  logoImagePolicy = 'general',
  disableFooterTopPadding,
  footerBackgroundColor = 'systemPureWhite',
}: {
  showSidebar?: boolean
  fullWidthContent?: boolean
  children?: React.ReactNode
  parseWindowSize?: (windowSize: WindowSize) => { isMobile: boolean; isDesktop: boolean }
  generalNavigationChildren?: React.ReactNode
  sidebarNavigationChildren?: React.ReactNode
  logoLinkPolicy: LogoLinkPolicy
  logoImagePolicy?: LogoImagePolicy
  disableFooterTopPadding?: boolean
  footerBackgroundColor?: 'systemPureWhite' | 'brandAccent'
}) => {
  const me = useMe()
  const windowSize = useWindowSize()
  const { isMobile, isDesktop } = parseWindowSize(windowSize)

  return (
    <div className={css.generalLayout}>
      {isDesktop && (
        <HeaderDesktop
          logoImagePolicy={logoImagePolicy}
          logoLinkPolicy={logoLinkPolicy}
          generalNavigationChildren={generalNavigationChildren}
        />
      )}
      {isMobile && (
        <HeaderMobile
          logoImagePolicy={logoImagePolicy}
          logoLinkPolicy={logoLinkPolicy}
          generalNavigationChildren={generalNavigationChildren}
          sidebarNavigationChildren={sidebarNavigationChildren}
        />
      )}
      <div className={css.columns}>
        <div className={cn({ [css.wrapper]: true, [css.fullWidth]: !!fullWidthContent })}>
          {showSidebar && isDesktop && sidebarNavigationChildren && !!me && (
            <div className={css.leftColumn}>
              <SidebarNavigation children={sidebarNavigationChildren} />
            </div>
          )}
          <div className={css.rightColumn}>{children || <Outlet />}</div>
        </div>
      </div>
      <Footer
        disableFooterTopPadding={disableFooterTopPadding}
        footerBackgroundColor={footerBackgroundColor}
        className={css.footer}
      />
    </div>
  )
}
