import type { ContactItem } from '@svag/shared/src/contacts'
import React from 'react'

export const Contacts = ({ contacts }: { contacts: ContactItem[] }) => {
  return (
    <>
      {contacts.map((contact, index) => (
        <React.Fragment key={index}>
          {index > 0 && <br />}
          {contact.label && contact.href && contact.value ? (
            <>
              {contact.label}:{' '}
              <a key={index} href={contact.href} target="_blank" rel="noreferrer">
                {contact.value}
              </a>
            </>
          ) : contact.href ? (
            <>
              <a key={index} href={contact.href} target="_blank" rel="noreferrer">
                {contact.value || contact.label}
              </a>
            </>
          ) : contact.label && contact.value ? (
            <>
              {contact.label}: {contact.value}
            </>
          ) : (
            <>{contact.value || contact.label}</>
          )}
        </React.Fragment>
      ))}
    </>
  )
}
