import cn from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import { trackClick } from '../../../lib/mixpanel'
import { Icon, type IconName } from '../Icon'
import css from './index.module.scss'

export type ButtonProps = {
  to?: string
  color?: 'brandAccent' | 'brandAccentLight' | 'brandAccentLightStroked' | 'systemRed' | 'systemPureWhite' | 'dark'
  size?: 's' | 'm' | 'l' | 'xl'
  href?: string
  icon?: IconName
  loading?: boolean
  disabled?: boolean
  round?: boolean
  iconSize?: number
  children?: React.ReactNode
  fullWidth?: boolean
  asSpan?: boolean
  className?: string
  nowrap?: boolean
  type?: 'button' | 'submit'
  target?: React.HTMLAttributeAnchorTarget
  rel?: string
  onClick?: (e: any) => void
  eventElementId?: string | undefined
  eventElementGroup?: string | undefined
  eventExtra?: Record<string, any> | undefined
}
export const Button = React.forwardRef<any, ButtonProps>((props, ref) => {
  const {
    to,
    color = 'brandAccent',
    size = 'm',
    href,
    icon,
    iconSize,
    loading = false,
    disabled,
    round,
    children,
    fullWidth,
    asSpan,
    className,
    target,
    rel,
    nowrap,
    onClick,
    eventElementId,
    eventElementGroup,
    eventExtra,
    ...restProps
  } = props
  const classNameHere = cn({
    [css.button]: true,
    [css.loading]: loading,
    [css.disabled]: disabled,
    [css[`color-${color}`]]: !!color,
    [css[`size-${size}`]]: !!size,
    [css.iconOnly]: !children && !!icon,
    [css.round]: !!round,
    [css.fullWidth]: !!fullWidth,
    [css.nowrap]: !!nowrap,
    [className || '']: !!className,
  })
  const onClickHere = (...args: [any]) => {
    if (eventElementId || eventElementGroup || to || href) {
      trackClick({
        elementId: eventElementId,
        elementGroup: eventElementGroup,
        extra: eventExtra,
        to: to || href,
      })
    }
    if (onClick) {
      onClick(...args)
    }
  }
  const childrenHere = (
    <span className={css.content}>
      {icon && (
        <Icon
          className={css.icon}
          size={iconSize}
          style={
            !iconSize
              ? {}
              : {
                  width: `${iconSize}px`,
                  height: `${iconSize}px`,
                  flexBasis: `${iconSize}px`,
                }
          }
          name={icon}
        />
      )}
      {children}
    </span>
  )
  const withParent = (children: React.ReactNode) => {
    if (target !== '_blank') {
      return children
    }
    return (
      <button
        className={cn({
          [css.parentButton]: true,
          [css.fullWidth]: !!fullWidth,
        })}
        onClick={onClickHere}
      >
        {children}
      </button>
    )
  }
  return to ? (
    withParent(
      <Link ref={ref} to={to} className={classNameHere} children={childrenHere} onClick={onClickHere} {...restProps} />
    )
  ) : href ? (
    withParent(
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a
        target={target}
        rel={rel}
        ref={ref}
        href={href}
        className={classNameHere}
        children={childrenHere}
        {...restProps}
      />
    )
  ) : asSpan ? (
    <span ref={ref} className={classNameHere} children={childrenHere} onClick={onClickHere} {...restProps} />
  ) : (
    <button
      type="button"
      ref={ref}
      disabled={disabled}
      className={classNameHere}
      children={childrenHere}
      onClick={onClickHere}
      {...restProps}
    />
  )
})

export const Buttons: React.FC<{
  children: React.ReactNode
  withLinks?: boolean
  stretch?: boolean
  compressed?: boolean
  className?: string
}> = ({ children, withLinks, compressed, stretch, className, ...restProps }) => (
  <div
    className={cn({
      [css.buttonsPlace]: true,
      [css.withLinks]: !!withLinks,
      [css.stretch]: !!stretch,
      [css.compressed]: !!compressed,
      [className || '']: !!className,
    })}
    {...restProps}
  >
    <div className={css.buttons}>{children}</div>
  </div>
)

export const ButtonWrapper: React.FC<{ children: React.ReactNode; className?: string }> = ({
  children,
  className,
  ...restProps
}) => (
  <div className={cn(css.button, className)} {...restProps}>
    {children}
  </div>
)
