import { useStore } from '@nanostores/react'
import { AccessDeniedError, NotFoundError, UnauthorizedError } from '@svag/shared/src/error'
import { type Lang } from '@svag/shared/src/lang'
import { type UseTRPCQueryResult, type UseTRPCQuerySuccessResult } from '@trpc/react-query/dist/shared'
import isBoolean from 'lodash/isBoolean'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation, useNavigate } from 'react-router-dom'
import { ConfirmEmailBlocker } from '../components/auth/ConfirmEmailBlocker'
import { BreadCrumbs, type BreadCrumbsType } from '../components/other/BreadCrumbs'
import { lastVisistedNotAuthRouteStore } from '../components/other/NotAuthRouteTracker'
import { AccessDeniedPageComponent } from '../components/pages/AccessDeniedPageComponent'
import { AuthorizedOnlyPageComponent } from '../components/pages/AuthorizedOnlyPageComponent'
import { ErrorBasedErrorPageComponent } from '../components/pages/ErrorBasedErrorPageComponent'
import { NotFoundPageComponent } from '../components/pages/NotFoundPageComponent'
import { Loader } from '../components/ui/Loader'
import { useAppContext, type AppContext } from './ctx'
import { useAvailableLangsData, useLang, useT } from './i18n'
import { type EventsContext, eventsContextStore } from './mixpanel'

const checkExistsFn = <T,>(value: T, message?: string): NonNullable<T> => {
  if (!value) {
    throw new NotFoundError(message)
  }
  return value
}

const checkAccessFn = <T,>(value: T, message?: string): void => {
  if (!value) {
    throw new AccessDeniedError(message)
  }
}

type Props = Record<string, any>
type QueryResult = UseTRPCQueryResult<any, any>
type QueriesResults = QueryResult[]
type QuerySuccessResult<TQueryResult extends QueryResult = QueryResult> = UseTRPCQuerySuccessResult<
  NonNullable<TQueryResult['data']>,
  null
>
type QueriesSuccessResults<
  TQueriesResults extends QueryResult[],
  TQueryResult1 extends QueryResult | undefined = TQueriesResults[0],
  TQueryResult2 extends QueryResult | undefined = TQueriesResults[1],
  TQueryResult3 extends QueryResult | undefined = TQueriesResults[2]
> = TQueryResult1 extends QueryResult
  ? TQueryResult2 extends QueryResult
    ? TQueryResult3 extends QueryResult
      ? [QuerySuccessResult<TQueryResult1>, QuerySuccessResult<TQueryResult2>, QuerySuccessResult<TQueryResult3>]
      : [QuerySuccessResult<TQueryResult1>, QuerySuccessResult<TQueryResult2>]
    : [QuerySuccessResult<TQueryResult1>]
  : []
type HelperProps<TQueryResult extends QueryResult | undefined, TQueriesResults extends QueriesResults | undefined> = {
  ctx: AppContext
  t: ReturnType<typeof useT<'all'>>['t']
  lang: Lang
  queryResult: TQueryResult extends QueryResult ? QuerySuccessResult<TQueryResult> : undefined
  queriesResults: TQueriesResults extends QueriesResults ? QueriesSuccessResults<TQueriesResults> : undefined
}
type SetPropsProps<
  TQueryResult extends QueryResult | undefined,
  TQueriesResults extends QueriesResults | undefined
> = HelperProps<TQueryResult, TQueriesResults> & {
  checkExists: typeof checkExistsFn
  checkAccess: typeof checkAccessFn
  getAuthorizedMe: (message?: string) => NonNullable<AppContext['me']>
}
type PageWrapperProps<
  TProps extends Props,
  TQueryResult extends QueryResult | undefined,
  TQueriesResults extends QueriesResults | undefined
> = {
  redirectAuthorized?: boolean

  authorizedOnly?: boolean
  authorizedOnlyTitle?: string
  authorizedOnlyMessage?: string

  emailConfirmedOnly?: boolean

  checkAccess?: (helperProps: HelperProps<TQueryResult, TQueriesResults>) => boolean
  checkAccessTitle?: string
  checkAccessMessage?: string

  checkExists?: (helperProps: HelperProps<TQueryResult, TQueriesResults>) => boolean
  checkExistsTitle?: string
  checkExistsMessage?: string

  showLoaderOnFetching?: boolean

  eventsContext?:
    | EventsContext
    | ((helperProps: HelperProps<TQueryResult, TQueriesResults>) => undefined | EventsContext)

  title?: string | ((helperProps: HelperProps<TQueryResult, TQueriesResults>) => undefined | string)
  isTitleExact?: boolean
  getBreadCrumbs?: (helperProps: HelperProps<TQueryResult, TQueriesResults>) => BreadCrumbsType

  // langs: Lang[] | ((helperProps: HelperProps<TQueryResult, TQueriesResults>) => Lang[])
  langs: Lang[]

  useQuery?: () => TQueryResult
  useQueries?: () => TQueriesResults
  setProps?: (setPropsProps: SetPropsProps<TQueryResult, TQueriesResults>) => TProps
  Page: React.FC<TProps>
  ErrorLayout?: React.FC<{ children: React.ReactNode }>
}

const PageWrapper = <
  TProps extends Props = {},
  TQueryResult extends QueryResult | undefined = undefined,
  TQueriesResults extends QueriesResults | undefined = undefined
>({
  emailConfirmedOnly,
  authorizedOnly,
  authorizedOnlyTitle,
  authorizedOnlyMessage,
  redirectAuthorized,
  checkAccess,
  checkAccessTitle,
  checkAccessMessage,
  checkExists,
  checkExistsTitle,
  checkExistsMessage,
  eventsContext,
  title,
  isTitleExact = false,
  getBreadCrumbs,
  langs,
  useQuery,
  useQueries,
  setProps,
  Page,
  showLoaderOnFetching = true,
  ErrorLayout = ({ children }) => <>{children}</>,
}: PageWrapperProps<TProps, TQueryResult, TQueriesResults>) => {
  const { pathname } = useLocation()
  useAvailableLangsData({ langs, pathname })
  const { t } = useT('all')
  const { lang } = useLang()
  const lastVisistedNotAuthRoute = useStore(lastVisistedNotAuthRouteStore)
  const navigate = useNavigate()
  const ctx = useAppContext()
  const redirectNeeded = !!redirectAuthorized && !!ctx.me
  const emailConfirmedOnlyHere = emailConfirmedOnly // isBoolean(emailConfirmedOnly) ? emailConfirmedOnly : !!authorizedOnly
  const authorizedOnlyHere = isBoolean(authorizedOnly) ? authorizedOnly : !!emailConfirmedOnly
  const shouldShowConfirmEmailBlocker = emailConfirmedOnlyHere && !!ctx.me && !ctx.me.emailConfirmed
  const shouldShowAuthorizedOnlyBlocker = authorizedOnlyHere && !ctx.me
  // const queryShouldBeSkipped = shouldShowConfirmEmailBlocker || redirectNeeded

  const queryResult = useQuery?.()
  const queriesResults = useQueries?.()
  const isQueryLoading = queryResult?.isLoading || (showLoaderOnFetching && queryResult?.isFetching)
  const isQueriesLoading = queriesResults?.some((qr) => qr?.isLoading || (showLoaderOnFetching && qr?.isFetching))
  const isLoading = isQueryLoading || isQueriesLoading
  const error = queryResult?.error || queriesResults?.find((qr) => qr?.error)?.error

  useEffect(() => {
    if (redirectNeeded) {
      navigate(lastVisistedNotAuthRoute, { replace: true })
    }
  }, [redirectNeeded, navigate, lastVisistedNotAuthRoute])

  if (isLoading || redirectNeeded) {
    return <Loader type="page" />
  }

  if (shouldShowConfirmEmailBlocker) {
    return (
      <ErrorLayout>
        <ConfirmEmailBlocker />
      </ErrorLayout>
    )
  }

  if (shouldShowAuthorizedOnlyBlocker) {
    return (
      <ErrorLayout>
        <AuthorizedOnlyPageComponent title={authorizedOnlyTitle} message={authorizedOnlyMessage} />
      </ErrorLayout>
    )
  }

  if (error) {
    return (
      <ErrorLayout>
        <ErrorBasedErrorPageComponent error={error} />
      </ErrorLayout>
    )
  }

  const helperProps = { ctx, t, lang, queryResult: queryResult as never, queriesResults: queriesResults as never }

  if (checkAccess) {
    const accessDenied = !checkAccess(helperProps)
    if (accessDenied) {
      return (
        <ErrorLayout>
          <AccessDeniedPageComponent title={checkAccessTitle} message={checkAccessMessage} />
        </ErrorLayout>
      )
    }
  }

  if (checkExists) {
    const notExists = !checkExists(helperProps)
    if (notExists) {
      return <NotFoundPageComponent title={checkExistsTitle} message={checkExistsMessage} />
    }
  }

  const getAuthorizedMe = (message?: string) => {
    if (!ctx.me) {
      throw new UnauthorizedError(message)
    }
    return ctx.me
  }

  const calculatedTitle = typeof title === 'function' ? title(helperProps) : title
  const exactTitle = !calculatedTitle
    ? t('general.svag')
    : isTitleExact
    ? calculatedTitle
    : `${calculatedTitle} — ${t('general.svag')}`

  const calculatedEventsContext =
    (typeof eventsContext === 'function' ? eventsContext(helperProps) : eventsContext) || {}
  eventsContextStore.set(calculatedEventsContext)

  // TODO: useBeforeRender

  // TODO: Dangerous. Create a hook for this.
  // setAvailableLangsData(typeof langs === 'function' ? langs(helperProps) : langs)

  try {
    const props = setProps?.({
      ...helperProps,
      checkExists: checkExistsFn,
      checkAccess: checkAccessFn,
      getAuthorizedMe,
    }) as TProps
    return (
      <>
        {exactTitle && (
          <Helmet>
            <title>{exactTitle}</title>
          </Helmet>
        )}
        {getBreadCrumbs && <BreadCrumbs breadCrumbs={getBreadCrumbs(helperProps)} />}
        <Page {...props} />
      </>
    )
  } catch (error) {
    if (error instanceof NotFoundError) {
      return (
        <ErrorLayout>
          <NotFoundPageComponent title={checkExistsTitle} message={error.message || checkExistsMessage} />
        </ErrorLayout>
      )
    }
    if (error instanceof AccessDeniedError) {
      return (
        <ErrorLayout>
          <AccessDeniedPageComponent title={checkAccessTitle} message={error.message || checkAccessMessage} />
        </ErrorLayout>
      )
    }
    if (error instanceof UnauthorizedError) {
      return (
        <ErrorLayout>
          <AuthorizedOnlyPageComponent title={authorizedOnlyTitle} message={error.message || authorizedOnlyMessage} />
        </ErrorLayout>
      )
    }
    throw error
  }
}

export const withPageWrapper = <
  TProps extends Props = {},
  TQueryResult extends QueryResult | undefined = undefined,
  TQueriesResults extends QueriesResults | undefined = undefined
>(
  pageWrapperProps: Omit<PageWrapperProps<TProps, TQueryResult, TQueriesResults>, 'Page'>
) => {
  return (Page: PageWrapperProps<TProps, TQueryResult, TQueriesResults>['Page']) => {
    return () => <PageWrapper {...pageWrapperProps} Page={Page} />
  }
}
