import KinescopePlayer from '@kinescope/react-kinescope-player'
import cn from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useLang } from '../../../lib/i18n'
import { trackFinishWatchVideo, trackStartWatchVideo, trackWatchVideoOneMinute } from '../../../lib/mixpanel'
import { Loader } from '../../ui/Loader'
import css from './index.module.scss'

export const VideoKinescope = ({
  videoId,
  width,
  height,
  className,
  eventElementId,
  eventElementGroup,
}: {
  videoId: string
  width: number
  height: number
  className?: string
  eventElementId?: string
  eventElementGroup?: string
}) => {
  const ratioPercentString = ((height / width) * 100).toFixed(2) + '%'
  const [started, setStarted] = useState(false)
  const [ended, setEnded] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [watchedSeconds, setWatchedSeconds] = useState(0)
  const [watchedForOneMinute, setWatchedForOneMinute] = useState(false)
  const playerRef = useRef<KinescopePlayer>(null)
  const { lang } = useLang()

  useEffect(() => {
    if (watchedSeconds >= 60) {
      setWatchedForOneMinute(true)
    }
    if (playing && !watchedForOneMinute) {
      const timeout = setTimeout(() => {
        setWatchedSeconds(watchedSeconds + 1)
      }, 1000)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [playing, watchedSeconds, watchedForOneMinute, videoId])

  useEffect(() => {
    if (started) {
      void playerRef.current?.enableTextTrack(lang)
      trackStartWatchVideo({
        elementId: eventElementId,
        elementGroup: eventElementGroup,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventElementGroup, eventElementId, started, videoId])
  useEffect(() => {
    if (watchedForOneMinute) {
      trackWatchVideoOneMinute({
        elementId: eventElementId,
        elementGroup: eventElementGroup,
      })
    }
  }, [watchedForOneMinute, videoId, eventElementId, eventElementGroup])
  useEffect(() => {
    if (ended) {
      trackFinishWatchVideo({
        elementId: eventElementId,
        elementGroup: eventElementGroup,
      })
    }
  }, [ended, eventElementGroup, eventElementId, videoId])

  return (
    <div
      className={cn(css.videoOuter, className)}
      style={{
        paddingBottom: ratioPercentString,
      }}
    >
      <Loader className={css.loader} type="page" />
      <KinescopePlayer
        ref={playerRef}
        className={css.video}
        width="100%"
        height="100%"
        videoId={videoId}
        onPlay={() => {
          if (!started) {
            setStarted(true)
          }
          if (!playing) {
            setPlaying(true)
          }
        }}
        onPause={() => {
          if (playing) {
            setPlaying(false)
          }
        }}
        onEnded={() => {
          if (!ended) {
            setEnded(true)
          }
        }}
      />
    </div>
  )
}
