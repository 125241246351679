import React from 'react'
import { Link } from 'react-router-dom'
import css from './index.module.scss'

type BreadCrumbsItemType = {
  label: React.ReactNode
  to?: string
}
export type BreadCrumbsType = BreadCrumbsItemType[]
export const BreadCrumbs = ({ breadCrumbs }: { breadCrumbs: BreadCrumbsType }) => {
  return (
    <div className={css.breadCrumbs}>
      {breadCrumbs.map(({ label, to }, i) => {
        const delimiter = i === 0 ? null : <div className={css.delimiter}>←</div>
        if (to) {
          return (
            <React.Fragment key={i}>
              {delimiter}
              <Link className={css.link} to={to} key={i}>
                {label}
              </Link>
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment key={i}>
              {delimiter}
              <span className={css.label} key={i}>
                {label}
              </span>
            </React.Fragment>
          )
        }
      })}
    </div>
  )
}
