import { zCreateGroupStudentInput } from '@svag/backend/src/router/group/createGroupStudent/input'
import { canConsulting } from '@svag/backend/src/services/user/can'
import { useParams } from 'react-router-dom'
import { FormItems } from '../../../components/form/FormItems'
import { Input } from '../../../components/form/Input'
import { Alert } from '../../../components/ui/Alert'
import { Button } from '../../../components/ui/Button'
import { Segment } from '../../../components/ui/Segment'
import { useForm } from '../../../lib/form'
import { useT } from '../../../lib/i18n'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { type getGroupRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import { type RouteParams } from '../../../utils/pumpGetRoute'

export const NewGroupStudentPage = withPageWrapper({
  title: ({ t, queryResult }) => t('newGroupStudentPage.title', { groupName: queryResult.data.groupInstance.title }),
  showLoaderOnFetching: false,
  useQuery: () => {
    const { groupInstanceSlug } = useParams() as RouteParams<typeof getGroupRoute>
    return trpc.getGroupInstance.useQuery({
      groupInstanceSlug,
    })
  },
  checkAccess: ({ ctx, queryResult }) => canConsulting(ctx.me) && queryResult.data.groupInstance.isMeTeacher,
  setProps: ({ queryResult }) => {
    return {
      groupInstance: queryResult.data.groupInstance,
    }
  },
  langs: ['ru', 'en'],
})(({ groupInstance }) => {
  const { t } = useT('newGroupStudentPage')
  const createGroupStudent = trpc.createGroupStudent.useMutation()
  const form = useForm({
    validationSchema: zCreateGroupStudentInput,
    initialValues: {
      groupInstanceId: groupInstance.id,
      studentEmail: '',
    },
    onSubmit: async (values) => {
      await createGroupStudent.mutateAsync(values)
    },
    showValidationAlert: true,
    resetOnSuccess: true,
    successMessage: t('form.successMessage'),
    successMessagePolicy: 'toast',
  })

  return (
    <Segment title={t('title', { groupName: groupInstance.title })}>
      <form onSubmit={form.formik.handleSubmit}>
        <FormItems>
          <Input formik={form.formik} name="studentEmail" label={t('form.studentEmailLabel')} />
          <Alert {...form.alertProps} />
          <Button type="submit" {...form.buttonProps}>
            {t('form.submitButton')}
          </Button>
        </FormItems>
      </form>
    </Segment>
  )
})
